import { useEffect, useState } from "react";
import {
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { useSelector } from "react-redux";
import UserAddForm from "./UserAddForm";


const UserAdd = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [error, setError] = useState("");
  const [companyList, setCompanyList] = useState([]);

  const token = useSelector((state) => state.token);

  const getCompanyList = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+"/company/all", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if(!res.ok) setError(res.message); 
        else return res.json();
      }).then((data) => {
        setCompanyList(data)
      }).catch((err) => {
        console.log('Error :' + err);
        setError("Internal Server Error");  
      });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  useEffect(() => {
    getCompanyList()
  }, []);

  return (
    <>
      {companyList.length !== 0 ? <UserAddForm companyList={companyList}/> : (
        <Typography
          variant="h4"
          textAlign={"center"}
          sx={{
            color: colors.purple[200],
          }}
        >
          No Company List
        </Typography>
      )}
    </>
  );
};

export default UserAdd;
