import GroupsIcon from "@mui/icons-material/Groups";
import {
  Box,
  Divider,
  IconButton,
  Typography,
  useTheme,
  Paper,
  Button,
  Grid,
} from "@mui/material";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import FlexBetween from "components/FlexBetween";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPost } from "state";
import ProductMembers from "./ProductMembers";
import { useNavigate } from "react-router-dom";

const PurchasedItem = ({ pid, name, desc, price, pdfURL, img }) => {
  const token = useSelector((state) => state.token);
  const companyid = useSelector((state) => state.companyid);
  const theme = useTheme();
  const navigate = useNavigate();


  return (
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardMedia
      component="div"
      sx={{ pt: '56.25%',}}
      image="https://source.unsplash.com/random?wallpapers"
      />
    <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                    {name}
                    <IconButton onClick={() => {navigate(`/products/${name}/${pid}/${companyid}/members`)}}>
                      <GroupsIcon/>
                    </IconButton>
                    </Typography>
                    <Typography>
                      {desc}
                    </Typography>
    </CardContent>
    <CardActions style={{justifyContent: 'center'}}>
                    <Button variant="contained" size="large">View</Button>
                    <Button variant="contained" size="large">Download</Button>
    </CardActions>
    </Card>
  );
};

export default PurchasedItem;
