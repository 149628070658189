import { Box, Typography, useTheme, Container } from "@mui/material";
import { Grid, Card } from "@mui/material";
import { useState } from "react";

import { tokens } from "../../theme";
import LoginForm from "./LoginForm";
import { useNavigate } from "react-router-dom";
import { UserAuth } from "context/AuthContext";

import { auth } from "../../config/firebase";

const LoginPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { user } = UserAuth();

  console.log("In login index");

  return (
    <main>
      <div style={{ backgroundColor: colors.purple[200] }}>
        <Container
          maxWidth="ls"
        >
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            style={{ minHeight: '100vh' }}
          >
            <Grid>
            <Card
                sx={{
                  height: "28rem",
                  width: "30rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              > 
              <LoginForm />
              </Card>
              <Box padding={"2rem 0 0 0"} justifyContent={"center"}>
              <Typography
                onClick={() => {
                  navigate('/signup');
                }}
                variant="body1"
                fontWeight={"bold"}
              textAlign={"center"}
                sx={{
                  textDecoration: "underline",
                  color: colors.white[100],
                }}
              >
                {"don't have an account? sign-up here."}
              </Typography>
            </Box>
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default LoginPage;
