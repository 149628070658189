import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

const SectionA3 = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState();
  const data = props.data;
  const [updateData, setUpdateData] = useState(data["items"]);
  const reportName = useSelector((state) => state.reportName);
  const token = useSelector((state) => state.token);


  const updateFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index) => {
          if (ele.id === id) {
            obj["values"][index].value = val;
          }
        });
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const updateArrayFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index1) => {
          if(ele["values"]) {
            ele["values"].map((e, index2) => {
              if (e.id === id) {
                obj["values"][index1]["values"][index2].value = val;
              }
            });
          }
          
        });
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const saveData = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/studio/brsr/save/sectionA`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reportName: reportName,
          data: updateData,
          section: "A-3"
        }),
      }).then((res) => {
        if (!res.ok) setError("Unable to save data");
      });
    } catch (e) {
      console.log(e.message);
      setError("Unable to create report");
    }
  };

  return (
    <main>
      <div>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "9rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    component="h5"
                    variant="h5"
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginLeft={"1rem"}
                  >
                    {data.name}
                  </Typography>
                  <Stack direction="row">
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                      marginRight={"1rem"}
                    >
                      Edit
                    </Typography>
                  </Stack>
                </CardContent>
                <Divider />
                <CardContent
                  sx={{
                    width: "6rem",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <div style={{ paddingLeft: "4rem" }}>
                    <Stack
                      direction={"row"}
                      sx={{ justifyContent: "space-around" }}
                    >
                      <div>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Approved
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <DataSaverOffIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Filled
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CalendarMonthIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Due Date
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              31st Jan 2024
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CommentOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Comments
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              0 Comments
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <ListAltOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Open Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <PersonOutlineOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;My Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[0].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[0].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[0].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="A-3-1-1-1"
                                  variant="outlined"
                                  value={
                                    updateData[0].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Stack direction={"column"}>
                              <Typography
                                component="subtitle2"
                                variant="subtitle2"
                                fontWeight="medium"
                                color={colors.grey[300]}
                                sx={{ marginLeft: "1rem" }}
                              >
                                {data.items[0].values[0].values[1].name}
                              </Typography>
                              <TextField
                                id="A-3-1-1-2"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[0].values[1].value
                                }
                                size="small"
                                sx={{ width: "14rem", marginLeft: "1rem" }}
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[0].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Stack>
                            <Stack direction={"column"}>
                              <Typography
                                component="subtitle2"
                                variant="subtitle2"
                                fontWeight="medium"
                                color={colors.grey[300]}
                                sx={{ marginLeft: "1rem" }}
                              >
                                {data.items[0].values[0].values[2].name}
                              </Typography>
                              <TextField
                                id="A-3-1-1-3"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[0].values[2].value
                                }
                                size="small"
                                sx={{ width: "14rem", marginLeft: "1rem" }}
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[0].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Stack>
                            <Stack direction={"column"}>
                              <Typography
                                component="subtitle2"
                                variant="subtitle2"
                                fontWeight="medium"
                                color={colors.grey[300]}
                                sx={{ marginLeft: "1rem" }}
                              >
                                {data.items[0].values[0].values[3].name}
                              </Typography>
                              <TextField
                                id="A-3-1-1-4"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[0].values[3].value
                                }
                                size="small"
                                sx={{ width: "14rem", marginLeft: "1rem" }}
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[0].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Stack>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="A-3-1-2-1"
                                variant="outlined"
                                value={updateData[0].values[1].values[0].value}
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-3-1-2-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[1].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "14rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[1].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-3-1-2-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[1].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "14rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[1].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-3-1-2-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[1].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "14rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[1].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[1].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[1].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[1].values[0].name}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "30rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[1].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="A-3-2-1-1"
                                  variant="outlined"
                                  value={data.items[1].values[0].values[0].value}
                                  size="small"
                                  sx={{ width: "30rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Stack direction={"column"}>
                              <Typography
                                component="subtitle2"
                                variant="subtitle2"
                                fontWeight="medium"
                                color={colors.grey[300]}
                                sx={{ marginLeft: "1rem" }}
                              >
                                {data.items[1].values[0].values[1].name}
                              </Typography>
                              <TextField
                                id="A-3-2-1-2"
                                variant="outlined"
                                defaultValue={updateData[1].values[0].values[1].value}
                                size="small"
                                sx={{ width: "29rem", marginLeft: "1rem" }}
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[0].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Stack>

                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "30rem" }}>
                              <TextField
                                id="A-3-2-2-1"
                                variant="outlined"
                                value={data.items[1].values[1].values[0].value}
                                size="small"
                                sx={{ width: "30rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-3-2-2-2"
                              variant="outlined"
                              size="small"
                              defaultValue={updateData[1].values[1].values[1].value}
                              sx={{
                                width: "29rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[1].values[1].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography
                      component="body1"
                      variant="body1"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginLeft={"1rem"}
                    >
                      {data.items[1].values[2].name}
                    </Typography>
                    <div style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}>
                      <Stack direction={"row"}>
                        <TextField
                          id="A-3-2-3"
                          variant="outlined"
                          defaultValue={updateData[1].values[2].value}
                          size="small"
                          multiline
                          maxRows={10}
                          sx={{ width: "60rem" }}
                          onChange={(e) => {
                            updateFields(data.items[1].values[2].id, e.target.value);
                          }}
                        />
                        <Box sx={{ padding: "10px 0 0 20px" }}>
                          <CommentOutlinedIcon
                            sx={{
                              color: colors.purple[100],
                              fontSize: "bold",
                            }}
                          />
                        </Box>
                      </Stack>
                    </div>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography
                      component="body1"
                      variant="body1"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginLeft={"1rem"}
                    >
                      {data.items[1].values[3].name}
                    </Typography>
                    <div style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}>
                      <Stack direction={"row"}>
                        <TextField
                          id="A-3-2-4"
                          variant="outlined"
                          defaultValue={updateData[1].values[3].value}
                          size="small"
                          multiline
                          maxRows={10}
                          sx={{ width: "60rem" }}
                          onChange={(e) => {
                            updateFields(data.items[1].values[3].id, e.target.value);
                          }}
                        />
                        <Box sx={{ padding: "10px 0 0 20px" }}>
                          <CommentOutlinedIcon
                            sx={{
                              color: colors.purple[100],
                              fontSize: "bold",
                            }}
                          />
                        </Box>
                      </Stack>
                    </div>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 5rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default SectionA3;
