import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  fid: null,
  userid: null,
  token: null,
  firstname: null,
  lastname: null,
  designation: null,
  license: null,
  usertype: null,
  companyname: null,
  products: [],
  purchased: [],
  reportid: "",
  reportName: "",
  pageName: "",
  breadcrumbs: []
};



export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setLogin: (state, action) => {
      state.fid = action.payload.fid;
      state.userid = action.payload.userid;
      state.token = action.payload.token;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.designation = action.payload.designation;
      state.usertype = action.payload.usertype;
      state.companyid = action.payload.companyid;
      state.companyname = action.payload.companyname;
    },
    setLogout: (state) => {
      state.userid = null;
      state.token = null;
    },
    setTopbar: (state, action) => {
      state.pageName = action.payload.pageName;
      state.breadcrumbs = action.payload.breadcrumbs;
    },
    setProducts: (state, action) => {
      state.products = action.payload.products;
    },
    setProduct: (state, action) => {
      const updatedProducts = state.products.map((product) => {
        if (product._id === action.payload.product._id) return action.payload.product;
        return product;
      });
      state.products = updatedProducts;
    },
    setPurchasedProducts: (state, action) => {
      state.purchased = action.payload.purchased;
    },
    setPurchased: (state, action) => {
      const purchasedProducts = state.purchased.map((product) => {
        if (product._id === action.payload.product._id) return action.payload.product;
        return product;
      });
      state.purchased = purchasedProducts;
    },
    setReport: (state, action) => {
      state.reportid = action.payload.reportid;
      state.reportName = action.payload.reportName;
    },
    
  },
});

export const { setMode, setLogin, setLogout, setTopbar, setProducts, setProduct, setPurchasedProducts, setPurchased,setReport } =
  authSlice.actions;
export default authSlice.reducer;