import { useRef, useEffect, useState } from "react";
import WebViewer from "@pdftron/webviewer";
import { Box } from "@mui/material";
import PdfViewerComponent from "components/PDFViewer.js";

const ProductViewer = ({productName}) => {
  const viewer = useRef(null);

  const [document, setDocument] = useState("document.pdf");


  useEffect(() => {
    WebViewer(
      {
        path: "lib",
        initialDoc:
          "https://pdftron.s3.amazonaws.com/downloads/pl/webviewer-demo.pdf",
      },
      viewer.current
    ).then((instance) => {
      instance.UI.disableElements([
        "toolbarGroup-Edit",
        "toolbarGroup-Insert",
        "toolbarGroup-Forms",
        "toolbarGroup-nenuOverlay",
      ]);
      var FitMode = instance.UI.FitMode;
      instance.UI.setFitMode(FitMode.FitWidth);
      const { documentViewer, annotationManager, Annotations } = instance.Core;

      documentViewer.addEventListener("documentLoaded", () => {
        const rectangleAnnot = new Annotations.RectangleAnnotation({
          PageNumber: 1,
          // values are in page coordinates with (0, 0) in the top left
          X: 100,
          Y: 150,
          Width: 200,
          Height: 50,
          Author: annotationManager.getCurrentUser(),
        });

        annotationManager.addAnnotation(rectangleAnnot);
        // need to draw the annotation otherwise it won't show up until the page is refreshed
        annotationManager.redrawAnnotation(rectangleAnnot);
      });
    });
  }, []);

  return (
    // <Box
    //   padding="5rem 5rem 0 5rem"
    //   width="100%"
    //   height="100vh"
    //   display="flex"
    //   flex-direction="column"
    // >
    //   <Box flex="1" margin="8px" ref={viewer}></Box>
    // </Box>
    <Box>
      <PdfViewerComponent document={document} />
    </Box>
  );
};

export default ProductViewer;
