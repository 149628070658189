import {
    useTheme,
    Box,
    Grid,
    Container,
    Card,
    CardContent,
    Typography,
    Stack,
  } from "@mui/material";
  import { tokens } from "../../theme";
  import { useState,useEffect } from "react";
  import { useNavigate, useLocation } from "react-router-dom";
  import LinearProgress, {
    linearProgressClasses,
  } from "@mui/material/LinearProgress";
  import { styled } from "@mui/material/styles";
  import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
  import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
  import Topbar from "scenes/global/Topbar";
  import { setTopbar } from "state";
  import { useDispatch, useSelector } from "react-redux";
  import Section1 from "./Section1";
  
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 3,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
    },
  }));
  
  const StrategyPage = () => {
    console.log("Inside Strategy page");
  
    const navigate = useNavigate();
    const theme = useTheme();
    const dispatch = useDispatch();
    const colors = tokens(theme.palette.mode);
    const companyid = useSelector((state) => state.companyid);
    const token = useSelector((state) => state.token);
    const [strategyData, setStrategyData] = useState([]);
    const [blocks, setBlocks] = useState([]);
    const [error, setError] = useState("");


    dispatch(setTopbar({ pageName: "Strategy", breadcrumbs: ["Strategy"]  }));
  
    const [currentIndex, setCurrentIndex] = useState(0);
  
    const handlePrevClick = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };
  
    const handleNextClick = () => {
      if (currentIndex < blocks.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    };

    const getStrategyData = async () => {
        try {
          await fetch(process.env.REACT_APP_SERVER_URL+`/strategy/${companyid}`, {
            method: "GET",
            headers: { Authorization: `Bearer ${token}` },
          })
            .then((res) => {
              if (!res.ok) setError(res.message);
              else return res.json();
            })
            .then((data) => {
                
                
                setStrategyData(data);
            })
            .catch((err) => {
              console.log("Error :" + err);
              setError("Internal Server Error");
            });
        } catch (e) {
          console.log(e.message);
          setError("Internal Server Error");
        }
      };

      useEffect(() => {
        getStrategyData();
        if(strategyData.length !== 0){
   
            setBlocks([<Section1 data={strategyData[0]["section"][0]}/> ])
        }
      }, [strategyData.length]);
  
    return (
      <main>
        <div>
        <Topbar/>
          <Container
            maxWidth="ls"
            style={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Grid>
                <Card
                  sx={{
                    height: "8rem",
                    width: "7rem",
                    display: "flex",
                    flexDirection: "column",
                    mr: "1rem",
                    borderRadius: "10px",
                    border:
                      currentIndex === 0
                        ? `1px solid ${colors.purple[200]}`
                        : "0px",
                  }}
                >
                  <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                    <Stack direction="column" >
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="100"
                        color={
                          currentIndex === 0
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                        paddingBottom={"3.2rem"}
                      >
                        1 Stakeholder Prioritization
                      </Typography>
                      <Box sx={{width:"5rem"}}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 0
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                      </Box>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
        <div style={{ marginTop: "1rem", marginLeft: "2rem" }}>
          <Box sx={{ display: "flex", justifyContent: "left" }}>
            <ArrowBackIosIcon
              style={{ color: colors.purple[200], fontSize: "15px" }}
              onClick={handlePrevClick}
            />
            <ArrowForwardIosIcon
              style={{ color: colors.purple[200], fontSize: "15px" }}
              onClick={handleNextClick}
            />
          </Box>
        </div>
        {blocks[currentIndex]}
      </main>
    );
  };
  
  export default StrategyPage;
  