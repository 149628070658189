
import ProductList from "./ProductList";


const ProductPage = () => {

  console.log("inside product page");
  return (
    
    <ProductList/>
  );

};

export default ProductPage;
