import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import Topbar from "scenes/global/Topbar";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTopbar } from "state";
import { useDispatch } from "react-redux";

const fy = [
  {
    value: "FY 2023-24",
    label: "FY 2023-24",
  },
  {
    value: "FY 2022-23",
    label: "FY 2022-23",
  },
];

const StudioPage = () => {
  console.log("Inside Studio page");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const companyid = useSelector((state) => state.companyid);
  const userid = useSelector((state) => state.userid);
  const token = useSelector((state) => state.token);
  const [name, setName] = useState("");
  const [financialyear, setFinancialYear] = useState("");
  const [savedReports, setSavedReports] = useState([]);
  const [error, setError] = useState("");

  const [open, setOpen] = useState(false);

  dispatch(setTopbar({ pageName: "Reporting Studio", breadcrumbs: ["Reporting Studio"] }));
  

  const handleClickOpen = () => {
    setOpen(true);
    
  };
  const handleClose = () => {
    setOpen(false);
  };

  const createReport = async (reportType) => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/studio/create`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reportType: reportType,
          name: name,
          fy: financialyear,
          createdBy: userid,
          companyid: companyid,
        }),
      }).then((res) => {
        if (!res.ok) setError("Unable to create report");
        else {
          setOpen(false);
          getSavedReports();
        }
      });
    } catch (e) {
      console.log(e.message);
      setError("Unable to create report");
    }
  };

  const getSavedReports = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/studio/report/saved/${companyid}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res.ok) setError(res.message);
          else return res.json();
        })
        .then((data) => {
          setSavedReports(data);
        })
        .catch((err) => {
          console.log("Error :" + err);
          setError("Internal Server Error");
        });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  useEffect(() => {
    getSavedReports();
  }, [savedReports.length]);

  return (
    <main>
      <div>
        <Topbar/>
        <Box>
          <Container
            maxWidth="ls"
            style={{ marginTop: "3rem", marginLeft: "1rem" }}
          >
            <Typography
              component="h4"
              variant="h4"
              fontWeight="bold"
              color={colors.grey[300]}
              gutterBottom
            >
              Select New Template
            </Typography>
          </Container>
        </Box>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <div onClick={handleClickOpen}>
                <Card
                  sx={{
                    height: "100%",
                    width: "10rem",
                    display: "flex",
                    flexDirection: "column",
                    mr: "2rem",
                    borderRadius: "10px",
                  }}
                >
                  <div
                    style={{
                      marginTop: "1rem",
                      marginLeft: "4rem",
                      marginRight: "4rem",
                    }}
                  >
                    <CardMedia
                      component="img"
                      image={require("../../assets/icons/edit_purple.png")}
                    />
                  </div>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                    >
                      BRSR
                    </Typography>
                  </CardContent>
                </Card>
              </div>
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Create BRSR</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Enter the name and timeperiod of the report
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Name"
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    variant="standard"
                    sx={{ paddingBottom: "1rem" }}
                  />
                  <TextField
                    id="fy"
                    label="Financial Year"
                    size="small"
                    variant="standard"
                    fullWidth
                    select
                    onChange={(e) => setFinancialYear(e.target.value)}
                  >
                    {fy.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    onClick={() => {
                      createReport("BRSR");
                    }}
                  >
                    Create
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>

            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "10rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "2rem",
                  borderRadius: "10px",
                }}
              >
                <div
                  style={{
                    marginTop: "1rem",
                    marginLeft: "4rem",
                    marginRight: "4rem",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={require("../../assets/icons/edit_purple.png")}
                  />
                </div>
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h5"
                    variant="h5"
                    fontWeight="bold"
                    color={colors.grey[100]}
                  >
                    Custom
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Divider
        style={{ marginTop: "3rem", marginLeft: "2rem", marginRight: "2rem" }}
      ></Divider>
      <div>
        <Box>
          <Container
            maxWidth="ls"
            style={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Typography
              component="h4"
              variant="h4"
              fontWeight="bold"
              color={colors.grey[300]}
              gutterBottom
            >
              Saved Reports
            </Typography>
          </Container>
        </Box>
        {savedReports.length !== 0 && (
          <Container
            maxWidth="ls"
            style={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              {savedReports.map(({ _id, reportType, name, fy,nodes }) => ( 
                <div key={_id} onClick={() => {
                  navigate('/studio/brsr',{state: {_id,name,reportType,fy,nodes}});}}>
                <Grid>
                  <Card
                    sx={{
                      height: "100%",
                      width: "10rem",
                      display: "flex",
                      flexDirection: "column",
                      mr: "2rem",
                      borderRadius: "10px",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "1rem",
                        marginLeft: "4rem",
                        marginRight: "4rem",
                      }}
                    >
                      <CardMedia
                        component="img"
                        image={require("../../assets/icons/file_purple.png")}
                      />
                    </div>
                    <CardContent
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        component="h5"
                        variant="h5"
                        fontWeight="bold"
                        color={colors.grey[100]}
                      >
                        {name + " " + reportType + " " + fy}
                      </Typography>
                      
                    </CardContent>
                  </Card>
                </Grid>
                </div>
              ))}
            </Grid>
          </Container>
        )}
      </div>
    </main>
  );
};

export default StudioPage;
