import { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  Grid,
  FormControlLabel,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { tokens } from "../../theme";
import { UserAuth } from "../../context/AuthContext";
import logo from "../../assets/icons/aiou_logo.png";

// Only for invites
// import { collection, getDocs } from "firebase/firestore";
// import { db } from "../../firebase";

const SignUpForm = ({ invitedIds }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [error, setError] = useState();
  const [invited, setInvited] = useState(false);
  // const [invitedIds, setInvitedIds] = useState([]);

  const fid = useSelector((state) => state.fid);
  const token = useSelector((state) => state.token);
  const { createUser } = UserAuth();

  const verifyInvite = async (email) => {
    if (invitedIds.includes(email)) {
      setInvited(true);
    } else {
      setInvited(false);
    }
  };

  const handleSignupFormSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (password === confirmPassword) {
      try {
        await createUser(email, password);
      } catch (e) {
        console.log(e.message);
        setError(e.message);
      }
    } else {
      setError("Password does not match");
      console.log(error);
    }
  };

  const activateUser = async () => {
    setError("");
    try {
      const response = await fetch(
        process.env.REACT_APP_SERVER_URL + "/auth/activate",
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fid: fid,
            email: email,
          }),
        }
      ).then((res) => {
        if (!res.ok) setError(res.message);
        else navigate("/");
      });
    } catch (e) {
      console.log(e.message);
      setError(e.message);
    }
  };

  useEffect(() => {
    if (invitedIds.length !== 0) verifyInvite(email);
    if (fid !== null && token !== null) activateUser();
  }, [email, fid, token]);

  return (
    <Grid align="center" paddingTop={"2rem"}>
      <Formik>
        {() => (
          <form onSubmit={handleSignupFormSubmit}>
            <Box component="img" sx={{ height: 54 }} alt="Logo" src={logo} />
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{
                color: colors.grey[300],
              }}
            >
              Empowering a Sustainable Universe
            </Typography>
            <Box
              display="grid"
              gap="30px"
              padding={"3rem 3rem 0 3rem"}
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                sx={{ gridColumn: "span 4" }}
                InputProps={{ sx: { borderRadius: "2rem" } }}
              />
              {invited && (
                <TextField
                  fullWidth
                  label="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  name="password"
                  sx={{ gridColumn: "span 4" }}
                  InputProps={{ sx: { borderRadius: "2rem" } }}
                />
              )}
              {invited && (
                <TextField
                  fullWidth
                  label="Confirm Password"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  name="confirm-password"
                  sx={{ gridColumn: "span 4" }}
                  InputProps={{ sx: { borderRadius: "2rem" } }}
                />
              )}
            </Box>
            {invited ? (
              <Box padding={"1rem 0 0 3rem"} align="left">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={true}
                      // onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        color: colors.purple[100],
                      }}
                    />
                  }
                  label="I agree to the Terms of Service and Privacy Policy."
                />
              </Box>
            ) : (
              <Box padding={"2rem 0 0 0"} align="center">
                <Typography
              variant="body1"
              fontWeight={"bold"}
              textAlign={"center"}
              sx={{
                color: colors.grey[300],
              }}
            >
              are you invited? enter your email address to verify & sign-up
            </Typography>
              </Box>
            )}

            {invited && (
              <Box padding={"1rem 4rem 0 4rem"}>
                <Button
                  type="submit"
                  variant="contained"
                  size="large"
                  sx={{
                    color: colors.purple[100],
                    width: "10rem",
                    borderRadius: "4rem",
                  }}
                >
                  <Typography
                    variant="h4"
                    textAlign={"center"}
                    sx={{
                      color: colors.white[200],
                    }}
                  >
                    Sign Up
                  </Typography>
                </Button>
              </Box>
            )}
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default SignUpForm;
