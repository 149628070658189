import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { tokens } from "../../theme";
import { setTopbar } from "state";
import ProductItem from "./ProductItem";
import PurchasedItem from "./PurchasedItem";
import Topbar from "scenes/global/Topbar";
import {
  Box,
  Typography,
  useTheme,
  Grid,
  Divider,
  Container,
} from "@mui/material";

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [purchased, setPurchasedProducts] = useState([]);
  const [error, setError] = useState("");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const token = useSelector((state) => state.token);
  const userid = useSelector((state) => state.userid);

  dispatch(setTopbar({ pageName: "Solutions", breadcrumbs: ["Solutions"] }));

  const getProducts = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+"/product/all/items", {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res.ok) setError(res.message);
          else return res.json();
        })
        .then((data) => {
          
          setProducts(data);
        })
        .catch((err) => {
          console.log("Error :" + err);
          setError("Internal Server Error");
        });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  const getPurchasedProducts = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/product/${userid}/all/purchaseditems`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res.ok) setError(res.message);
          else return res.json();
        })
        .then((data) => {
          setPurchasedProducts(data);
        })
        .catch((err) => {
          console.log("Error :" + err);
          setError("Internal Server Error");
        });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  useEffect(() => {
    getProducts();
    getPurchasedProducts();
  }, []);

  return (
    <main>
      <Topbar />
      {/* Purchased Products */}
      {purchased.length !== 0 && (
        <div>
            <Container maxWidth="ls" style={{ marginTop: "50px" }}>
              <Typography
                component="h3"
                variant="h3"
                color="text.primary"
                gutterBottom
              >
                My Products
              </Typography>
            </Container>

          <Container sx={{ py: 8 }} maxWidth="ls">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {purchased.map(({ _id, name, desc, price, pdfURL, img }) => (
                <Grid item key={purchased} xs={12} sm={6} md={4}>
                  <PurchasedItem
                    key={_id}
                    pid={_id}
                    name={name}
                    desc={desc}
                    price={price}
                    pdfURL={pdfURL}
                    img={img}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        </div>
      )}

      {/* All Products */}

      <div style={{paddingLeft: "1rem"}}>
          <Container maxWidth="ls" style={{ marginTop: "1rem" }}>
          <Typography
              component="h4"
              variant="h4"
              fontWeight="bold"
              color={colors.grey[300]}
              gutterBottom
            >
              All Solutions
            </Typography>
          </Container>

        {Object.keys(products).length !== 0 && (
          <Container sx={{ py: 4 }} maxWidth="ls">
            <Grid container >
              {products.map(({ _id, name, desc, price, pdfURL, img }, index) => (
                <Grid item key={products} xs={12} sm={6} md={4}>
                  <ProductItem
                    key={_id}
                    pid={_id}
                    name={name}
                    desc={desc}
                    price={price}
                    pdfURL={pdfURL}
                    img={img}
                    index={index}
                  />
                </Grid>
              ))}
            </Grid>
          </Container>
        )}
      </div>
    </main>
  );
};

export default ProductList;
