import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
  Divider,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

function TableRows1({ rows1, tableRowRemove1, onValUpdate1 }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return rows1.map((rowsData1, index1) => {
    const objIndex = index1 + 1;
    return (
      <Stack direction={"row"}>
        <TextField
          id="1-1"
          variant="outlined"
          size="small"
          sx={{ width: "8.5rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData1.value1}
          onChange={(e) => onValUpdate1(objIndex, "var1", e.target.value)}
        />
        <TextField
          id="1-2"
          variant="outlined"
          size="small"
          sx={{ width: "8rem", marginLeft: "0.5rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData1.value2}
          onChange={(e) => onValUpdate1(objIndex, "var2", e.target.value)}
        />
        <TextField
          id="1-3"
          variant="outlined"
          size="small"
          sx={{ width: "8rem", marginLeft: "0.5rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData1.value3}
          onChange={(e) => onValUpdate1(objIndex, "var3", e.target.value)}
        />
        <TextField
          id="1-4"
          variant="outlined"
          size="small"
          sx={{ width: "8rem", marginLeft: "0.5rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData1.value4}
          onChange={(e) => onValUpdate1(objIndex, "var4", e.target.value)}
        />
        <TextField
          id="1-5"
          variant="outlined"
          size="small"
          sx={{ width: "8rem", marginLeft: "0.5rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData1.value5}
          onChange={(e) => onValUpdate1(objIndex, "var5", e.target.value)}
          select
        >
          {principleValue.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="1-6"
          variant="outlined"
          size="small"
          sx={{ width: "8rem", marginLeft: "0.5rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData1.value6}
          onChange={(e) => onValUpdate1(objIndex, "var6", e.target.value)}
          select
        >
          {principleValue.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          id="1-7"
          variant="outlined"
          size="small"
          sx={{ width: "8rem", marginLeft: "0.5rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData1.value7}
          onChange={(e) => onValUpdate1(objIndex, "var7", e.target.value)}
        />
        <Stack direction={"row"}>
          <Box sx={{ padding: "1.2rem 0 0 20px" }}>
            <CommentOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
            />
          </Box>
          <Box sx={{ padding: "1.1rem 0 0 10px" }}>
            <DeleteOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
              onClick={() => tableRowRemove1(objIndex)}
            />
          </Box>
        </Stack>
      </Stack>
    );
  });
}

function TableRows2({ rows2, tableRowRemove2, onValUpdate2 }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return rows2.map((rowsData2, index2) => {
    const objIndex = index2 + 1;
    return (
      <Stack direction={"row"}>
        <TextField
          id="2-1"
          variant="outlined"
          size="small"
          sx={{ width: "20rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData2.value1}
          onChange={(e) => onValUpdate2(objIndex, "var1", e.target.value)}
        />
        <TextField
          id="2-2"
          variant="outlined"
          size="small"
          sx={{ width: "19rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData2.value2}
          onChange={(e) => onValUpdate2(objIndex, "var2", e.target.value)}
        />
        <TextField
          id="2-3"
          variant="outlined"
          size="small"
          sx={{ width: "19rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData2.value3}
          onChange={(e) => onValUpdate2(objIndex, "var3", e.target.value)}
        />
        <Stack direction={"row"}>
          <Box sx={{ padding: "1.2rem 0 0 20px" }}>
            <CommentOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
            />
          </Box>
          <Box sx={{ padding: "1.1rem 0 0 10px" }}>
            <DeleteOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
              onClick={() => tableRowRemove2(objIndex)}
            />
          </Box>
        </Stack>
      </Stack>
    );
  });
}

function TableRows3({ rows3, tableRowRemove3, onValUpdate3 }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return rows3.map((rowsData3, index3) => {
    const objIndex = index3 + 1;
    return (
      <Stack direction={"row"}>
        <TextField
          id="3-1"
          variant="outlined"
          size="small"
          sx={{ width: "30rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData3.value1}
          onChange={(e) => onValUpdate3(objIndex, "var1", e.target.value)}
        />
        <TextField
          id="3-2"
          variant="outlined"
          size="small"
          sx={{ width: "29rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData3.value2}
          onChange={(e) => onValUpdate3(objIndex, "var2", e.target.value)}
        />
        <Stack direction={"row"}>
          <Box sx={{ padding: "1.2rem 0 0 20px" }}>
            <CommentOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
            />
          </Box>
          <Box sx={{ padding: "1.1rem 0 0 10px" }}>
            <DeleteOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
              onClick={() => tableRowRemove3(objIndex)}
            />
          </Box>
        </Stack>
      </Stack>
    );
  });
}

function TableRows4({ rows4, tableRowRemove4, onValUpdate4 }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return rows4.map((rowsData4, index4) => {
    const objIndex = index4 + 1;
    return (
      <Stack direction={"row"}>
        <TextField
          id="4-1"
          variant="outlined"
          size="small"
          sx={{ width: "30rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData4.value1}
          onChange={(e) => onValUpdate4(objIndex, "var1", e.target.value)}
        />
        <TextField
          id="4-2"
          variant="outlined"
          size="small"
          sx={{ width: "29rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData4.value2}
          onChange={(e) => onValUpdate4(objIndex, "var2", e.target.value)}
        />
        <Stack direction={"row"}>
          <Box sx={{ padding: "1.2rem 0 0 20px" }}>
            <CommentOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
            />
          </Box>
          <Box sx={{ padding: "1.1rem 0 0 10px" }}>
            <DeleteOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
              onClick={() => tableRowRemove4(objIndex)}
            />
          </Box>
        </Stack>
      </Stack>
    );
  });
}

function TableRows5({ rows5, tableRowRemove5, onValUpdate5 }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return rows5.map((rowsData5, index5) => {
    const objIndex = index5 + 1;
    return (
      <Stack direction={"row"}>
        <TextField
          id="5-1"
          variant="outlined"
          size="small"
          sx={{ width: "30rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData5.value1}
          onChange={(e) => onValUpdate5(objIndex, "var1", e.target.value)}
        />
        <TextField
          id="5-2"
          variant="outlined"
          size="small"
          sx={{ width: "29rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          defaultValue={rowsData5.value2}
          onChange={(e) => onValUpdate5(objIndex, "var2", e.target.value)}
        />
        <Stack direction={"row"}>
          <Box sx={{ padding: "1.2rem 0 0 20px" }}>
            <CommentOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
            />
          </Box>
          <Box sx={{ padding: "1.1rem 0 0 10px" }}>
            <DeleteOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
              onClick={() => tableRowRemove5(objIndex)}
            />
          </Box>
        </Stack>
      </Stack>
    );
  });
}

const principleValue = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const SectionC2 = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState();
  const data = props.data;
  const [updateData, setUpdateData] = useState(data["items"]);
  const reportName = useSelector((state) => state.reportName);
  const token = useSelector((state) => state.token);

  const [rows1, initRow1] = useState(updateData[4].values[1].rows.slice(1));
  const [rows2, initRow2] = useState(updateData[5].rows.slice(1));
  const [rows3, initRow3] = useState(updateData[6].values[0].rows.slice(1));
  const [rows4, initRow4] = useState(updateData[6].values[1].rows.slice(1));
  const [rows5, initRow5] = useState(updateData[8].rows.slice(1));

  const addRowTable1 = () => {
    updateData[4].values[1].rows.push({
      id1: "",
      value1: "",
      id2: "",
      value2: "",
      id3: "",
      value3: "",
      id4: "",
      value4: "",
      id5: "",
      value5: "",
      id6: "",
      value6: "",
      id7: "",
      value7: "",
    });
    setUpdateData(updateData);
    initRow1(updateData[4].values[1].rows.slice(1));
  };

  const addRowTable2 = () => {
    updateData[5].rows.push({
      id1: "",
      value1: "",
      id2: "",
      value2: "",
      id3: "",
      value3: "",
    });
    setUpdateData(updateData);
    initRow2(updateData[5].rows.slice(1));
  };

  const addRowTable3 = () => {
    updateData[6].values[0].rows.push({
      id1: "",
      value1: "",
      id2: "",
      value2: "",
    });
    setUpdateData(updateData);
    initRow3(updateData[6].values[0].rows.slice(1));
  };

  const addRowTable4 = () => {
    updateData[6].values[1].rows.push({
      id1: "",
      value1: "",
      id2: "",
      value2: "",
    });
    setUpdateData(updateData);
    initRow4(updateData[6].values[1].rows.slice(1));
  };

  const addRowTable5 = () => {
    updateData[8].rows.push({
      id1: "",
      value1: "",
      id2: "",
      value2: "",
    });
    setUpdateData(updateData);
    initRow5(updateData[8].rows.slice(1));
  };

  const tableRowRemove1 = (index) => {
    updateData[4].values[1].rows.splice(index, 1);
    setUpdateData(updateData);
    initRow1(updateData[4].values[1].rows.slice(1));
  };
  const tableRowRemove2 = (index) => {
    updateData[5].rows.splice(index, 1);
    setUpdateData(updateData);
    initRow2(updateData[5].rows.slice(1));
  };
  const tableRowRemove3 = (index) => {
    updateData[6].values[0].rows.splice(index, 1);
    setUpdateData(updateData);
    initRow3(updateData[6].values[0].rows.slice(1));
  };
  const tableRowRemove4 = (index) => {
    updateData[6].values[1].rows.splice(index, 1);
    setUpdateData(updateData);
    initRow4(updateData[6].values[1].rows.slice(1));
  };
  const tableRowRemove5 = (index) => {
    updateData[8].rows.splice(index, 1);
    setUpdateData(updateData);
    initRow5(updateData[8].rows.slice(1));
  };

  const onValUpdate1 = (i, fieldName, fieldValue) => {
    const objId = i + 1;

    switch (fieldName) {
      case "var1":
        updateData[4].values[1].rows[i].id1 = "P2-L-C-1-2-1-" + objId;
        updateData[4].values[1].rows[i].value1 = fieldValue;
        break;

      case "var2":
        updateData[4].values[1].rows[i].id2 = "P2-L-C-1-2-2-" + objId;
        updateData[4].values[1].rows[i].value2 = fieldValue;
        break;

      case "var3":
        updateData[4].values[1].rows[i].id3 = "P2-L-C-1-2-3-" + objId;
        updateData[4].values[1].rows[i].value3 = fieldValue;
        break;

      case "var4":
        updateData[4].values[1].rows[i].id4 = "P2-L-C-1-2-4-" + objId;
        updateData[4].values[1].rows[i].value4 = fieldValue;
        break;

      case "var5":
        updateData[4].values[1].rows[i].id5 = "P2-L-C-1-2-5-" + objId;
        updateData[4].values[1].rows[i].value5 = fieldValue;
        break;

      case "var6":
        updateData[4].values[1].rows[i].id6 = "P2-L-C-1-2-6-" + objId;
        updateData[4].values[1].rows[i].value6 = fieldValue;
        break;

      case "var7":
        updateData[4].values[1].rows[i].id7 = "P2-L-C-1-2-7-" + objId;
        updateData[4].values[1].rows[i].value7 = fieldValue;
        break;

      default:
        console.log("Default");
    }

    setUpdateData(updateData);
  };

  const onValUpdate2 = (i, fieldName, fieldValue) => {
    const objId = i + 1;

    switch (fieldName) {
      case "var1":
        updateData[5].rows[i].id1 = "P2-L-C-2-1-" + objId;
        updateData[5].rows[i].value1 = fieldValue;
        break;

      case "var2":
        updateData[5].rows[i].id2 = "P2-L-C-2-2-" + objId;
        updateData[5].rows[i].value2 = fieldValue;
        break;

      case "var3":
        updateData[5].rows[i].id3 = "P2-L-C-2-3-" + objId;
        updateData[5].rows[i].value3 = fieldValue;
        break;

      default:
        console.log("Default");
    }

    setUpdateData(updateData);
  };
  const onValUpdate3 = (i, fieldName, fieldValue) => {
    const objId = i + 1;

    switch (fieldName) {
      case "var1":
        updateData[6].values[0].rows[i].id1 = "P2-L-C-3-1-1-" + objId;
        updateData[6].values[0].rows[i].value1 = fieldValue;
        break;

      case "var2":
        updateData[6].values[0].rows[i].id2 = "P2-L-C-3-1-2-" + objId;
        updateData[6].values[0].rows[i].value2 = fieldValue;
        break;

      default:
        console.log("Default");
    }

    setUpdateData(updateData);
  };

  const onValUpdate4 = (i, fieldName, fieldValue) => {
    const objId = i + 1;

    switch (fieldName) {
      case "var1":
        updateData[6].values[1].rows[i].id1 = "P2-L-C-3-2-1-" + objId;
        updateData[6].values[1].rows[i].value1 = fieldValue;
        break;

      case "var2":
        updateData[6].values[1].rows[i].id2 = "P2-L-C-3-2-2-" + objId;
        updateData[6].values[1].rows[i].value2 = fieldValue;
        break;

      default:
        console.log("Default");
    }

    setUpdateData(updateData);
  };

  const onValUpdate5 = (i, fieldName, fieldValue) => {
    const objId = i + 1;

    switch (fieldName) {
      case "var1":
        updateData[8].rows[i].id1 = "P2-L-C-5-1-" + objId;
        updateData[8].rows[i].value1 = fieldValue;
        break;

      case "var2":
        updateData[8].rows[i].id2 = "P2-L-C-5-2-" + objId;
        updateData[8].rows[i].value2 = fieldValue;
        break;

      default:
        console.log("Default");
    }

    setUpdateData(updateData);
  };

  const updateArrayFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index1) => {
          if (ele["values"]) {
            ele["values"].map((e, index2) => {
              if (e.id === id) {
                obj["values"][index1]["values"][index2].value = val;
              }
            });
          } else {
            if (ele.id === id) {
              obj["values"][index1].value = val;
            }
          }
        });
      }

      return obj;
    });
    setUpdateData(newData);
  };

  const updateTableFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index1) => {
          if (ele["rows"]) {
            ele["rows"].map((e, index2) => {
              if (e.id1 === id) {
                obj["values"][index1]["rows"][index2].value1 = val;
              } else if (e.id2 === id) {
                obj["values"][index1]["rows"][index2].value2 = val;
              } else if (e.id3 === id) {
                obj["values"][index1]["rows"][index2].value3 = val;
              } else if (e.id4 === id) {
                obj["values"][index1]["rows"][index2].value4 = val;
              } else if (e.id5 === id) {
                obj["values"][index1]["rows"][index2].value5 = val;
              } else if (e.id6 === id) {
                obj["values"][index1]["rows"][index2].value6 = val;
              }  else if (e.id7 === id) {
                obj["values"][index1]["rows"][index2].value7 = val;
              }
            });
          }
        });
      } else if (obj["rows"]) {
        obj["rows"].map((ele, index1) => {
          if (ele.id1 === id) {
            obj["rows"][index1].value1 = val;
          } else if (ele.id2 === id) {
            obj["rows"][index1].value2 = val;
          } else if (ele.id3 === id) {
            obj["rows"][index1].value3 = val;
          }
        });
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const saveData = async () => {
    try {
      await fetch(
        process.env.REACT_APP_SERVER_URL + `/studio/brsr/save/sectionC`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            reportName: reportName,
            data: updateData,
            section: "C-2",
          }),
        }
      ).then((res) => {
        if (!res.ok) setError("Unable to save data");
      });
    } catch (e) {
      console.log(e.message);
      setError("Unable to create report");
    }
  };

  return (
    <main>
      <div>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "9rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginLeft={"1rem"}
                  >
                    {data.name}
                  </Typography>
                  <Stack direction="row">
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                      marginRight={"1rem"}
                    >
                      Edit
                    </Typography>
                  </Stack>
                </CardContent>
                <Divider />
                <CardContent
                  sx={{
                    width: "6rem",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <div style={{ paddingLeft: "4rem" }}>
                    <Stack
                      direction={"row"}
                      sx={{ justifyContent: "space-around" }}
                    >
                      <div>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Approved
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <DataSaverOffIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Filled
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CalendarMonthIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Due Date
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              31st Jan 2024
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CommentOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Comments
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              0 Comments
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <ListAltOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Open Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <PersonOutlineOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;My Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Typography
              component="h5"
              variant="h5"
              fontWeight="bold"
              color={colors.grey[300]}
              marginLeft={"2rem"}
              paddingTop={"2rem"}
            >
              Essential Indicators
            </Typography>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[0].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-E-C-1-A-1"
                                variant="outlined"
                                size="small"
                                sx={{ width: "15rem", paddingTop: "2.4rem" }}
                                defaultValue={
                                  updateData[0].values[0].values[0].value
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-1-A-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1.2rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-1-A-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1.2rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-1-A-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[0].values[0].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>

                            <Box sx={{ padding: "3rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-E-C-1-B-1"
                                variant="outlined"
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                defaultValue={
                                  updateData[0].values[1].values[0].value
                                }
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-E-C-1-B-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-E-C-1-B-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-E-C-1-B-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[1].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[1].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[1].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[1].values[0].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-2-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "20rem" }}
                                  defaultValue={updateData[1].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[0].id,
                                      e.target.value
                                    );
                                  }}
                                  select
                                >
                                  {principleValue.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[1].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-2-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "39rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[1].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[2].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[2].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[2].values[0].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-3-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "15rem" }}
                                  defaultValue={updateData[2].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[0].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[1].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-3-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[2].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[2].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-3-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[2].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[3].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-3-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[2].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[3].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[3].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[3].values[0].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-4-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "20rem", paddingTop: "1.2rem" }}
                                  defaultValue={updateData[3].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[0].id,
                                      e.target.value
                                    );
                                  }}
                                  select
                                >
                                  {principleValue.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="P2-E-C-4-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "39rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[3].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Divider sx={{ paddingTop: "3rem", color: colors.grey[100] }} />
            </Grid>
            <Typography
              component="h5"
              variant="h5"
              fontWeight="bold"
              color={colors.grey[300]}
              marginLeft={"2rem"}
              paddingTop={"2rem"}
            >
              Leadership Indicators
            </Typography>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[4].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[4].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[4].values[0].name}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "20rem" }}
                                  defaultValue={updateData[4].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[0].id,
                                      e.target.value
                                    );
                                  }}
                                  select
                                >
                                  {principleValue.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[4].values[1].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "8.5rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[4].values[1].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-2-1-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "8.5rem", paddingTop: "3.6rem" }}
                                  defaultValue={
                                    updateData[4].values[1].rows[0].value1
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[4].values[1].rows[0].id1,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "8.5rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[1].rows[0].name2}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-2-2-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "8rem",
                                    marginLeft: "0.5rem",
                                    paddingTop: "2.4rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[1].rows[0].value2
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[4].values[1].rows[0].id2,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "8.5rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[1].rows[0].name3}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-2-3-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "8rem",
                                    marginLeft: "0.5rem",
                                    paddingTop: "2.4rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[1].rows[0].value3
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[4].values[1].rows[0].id3,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "8.5rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[1].rows[0].name4}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-2-4-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "8rem", marginLeft: "0.5rem" }}
                                  defaultValue={
                                    updateData[4].values[1].rows[0].value4
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[4].values[1].rows[0].id4,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "8.5rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[1].rows[0].name5}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-2-5-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "8rem",
                                    marginLeft: "0.5rem",
                                    paddingTop: "1.2rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[1].rows[0].value5
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[4].values[1].rows[0].id5,
                                      e.target.value
                                    );
                                  }}
                                  select
                                >
                                  {principleValue.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "8.5rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "0.5rem" }}
                                >
                                  {data.items[4].values[1].rows[0].name6}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-2-6-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "8rem",
                                    marginLeft: "0.5rem",
                                    paddingTop: "2.4rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[1].rows[0].value6
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[4].values[1].rows[0].id6,
                                      e.target.value
                                    );
                                  }}
                                  select
                                >
                                  {principleValue.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "8.5rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "0.5rem" }}
                                >
                                  {data.items[4].values[1].rows[0].name7}
                                </Typography>
                                <TextField
                                  id="P2-L-C-1-2-7-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "8rem",
                                    marginLeft: "0.5rem",
                                    paddingTop: "1.2rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[1].rows[0].value7
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[4].values[1].rows[0].id7,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "5.4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <TableRows1
                            rows1={rows1}
                            tableRowRemove1={tableRowRemove1}
                            onValUpdate1={onValUpdate1}
                          />
                          <div style={{ padding: "0.5rem 0 0 0" }}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={addRowTable1}
                            >
                              Add New Row
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[5].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[5].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[5].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="P2-L-C-2-1-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "20rem" }}
                                  defaultValue={
                                    updateData[5].rows[0].value1
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[5].rows[0].id1,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].rows[0].name2}
                                </Typography>
                                <TextField
                                  id="P2-L-C-2-2-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "19rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[5].rows[0].value2
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[5].rows[0].id2,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].rows[0].name3}
                                </Typography>
                                <TextField
                                  id="P2-L-C-2-3-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "19rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[5].rows[0].value3
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[5].rows[0].id3,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <TableRows2
                            rows2={rows2}
                            tableRowRemove2={tableRowRemove2}
                            onValUpdate2={onValUpdate2}
                          />
                          <div style={{ padding: "0.5rem 0 0 0" }}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={addRowTable2}
                            >
                              Add New Row
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[6].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[6].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[6].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "30rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[6].values[0].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="P2-L-C-3-1-1-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "30rem" }}
                                  defaultValue={
                                    updateData[6].values[0].rows[0].value1
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[6].values[0].rows[0].id1,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "30rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[0].rows[0].name2}
                                </Typography>
                                <TextField
                                  id="P2-L-C-3-1-2-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "29rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[6].values[0].rows[0].value2
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[6].values[0].rows[0].id2,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <TableRows3
                            rows3={rows3}
                            tableRowRemove3={tableRowRemove3}
                            onValUpdate3={onValUpdate3}
                          />
                          <div style={{ padding: "0.5rem 0 0 0" }}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={addRowTable3}
                            >
                              Add New Row
                            </Button>
                          </div>
                        </div>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[6].values[1].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "30rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[6].values[1].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="P2-L-C-3-2-1-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "30rem" }}
                                  defaultValue={
                                    updateData[6].values[1].rows[0].value1
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[6].values[1].rows[0].id1,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "30rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[1].rows[0].name2}
                                </Typography>
                                <TextField
                                  id="P2-L-C-3-2-2-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "29rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[6].values[1].rows[0].value2
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[6].values[1].rows[0].id2,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <TableRows4
                            rows4={rows4}
                            tableRowRemove4={tableRowRemove4}
                            onValUpdate4={onValUpdate4}
                          />
                          <div style={{ padding: "0.5rem 0 0 0" }}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={addRowTable4}
                            >
                              Add New Row
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[7].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[7].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[7].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-1-1"
                              variant="outlined"
                              size="small"
                              defaultValue={updateData[7].values[0].values[0].value}
                              sx={{ width: "15rem", paddingTop: "1.2rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P2-L-C-4-A-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[7].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P2-L-C-4-A-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[7].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="P2-L-C-4-A-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[7].values[0].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-2-1"
                              variant="outlined"
                              size="small"
                              defaultValue={updateData[7].values[1].values[0].value}
                              sx={{ width: "15rem", paddingTop: "0.5rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[1].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[1].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-3-1"
                              variant="outlined"
                              size="small"
                              defaultValue={
                                updateData[7].values[2].values[0].value
                              }
                              sx={{ width: "15rem", paddingTop: "0.5rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[2].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[2].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[2].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[2].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-4-1"
                              variant="outlined"
                              size="small"
                              defaultValue={
                                updateData[7].values[3].values[0].value
                              }
                              sx={{ width: "15rem", paddingTop: "0.5rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-4-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[3].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[3].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-4-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[3].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[3].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-4-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[3].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[3].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[7].values[4].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-5-1"
                              variant="outlined"
                              size="small"
                              defaultValue={
                                updateData[7].values[4].values[0].value
                              }
                              sx={{ width: "15rem", paddingTop: "1.2rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[4].values[1].name}
                                </Typography>
                                <TextField
                                  id="P2-L-C-4-A-5-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[7].values[4].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[4].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[4].values[2].name}
                                </Typography>
                                <TextField
                                  id="P2-L-C-4-A-5-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[7].values[4].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[4].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[4].values[3].name}
                                </Typography>
                                <TextField
                                  id="P2-L-C-4-A-5-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[7].values[4].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[4].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-6-1"
                              variant="outlined"
                              size="small"
                              defaultValue={
                                updateData[7].values[5].values[0].value
                              }
                              sx={{ width: "15rem", paddingTop: "0.5rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-6-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-6-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[5].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[5].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-6-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[5].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[5].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-7-1"
                              variant="outlined"
                              size="small"
                              defaultValue={
                                updateData[7].values[6].values[0].value
                              }
                              sx={{ width: "15rem", paddingTop: "0.5rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-7-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[6].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[6].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-7-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[6].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[6].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-7-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[6].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[6].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <TextField
                              id="P2-L-C-4-A-8-1"
                              variant="outlined"
                              size="small"
                              defaultValue={
                                updateData[7].values[7].values[0].value
                              }
                              sx={{ width: "15rem", paddingTop: "0.5rem" }}
                              InputProps={{
                                readOnly: true,
                              }}
                            />
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-8-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[7].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[7].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-8-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[7].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[7].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P2-L-C-4-A-8-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "0.5rem",
                                }}
                                defaultValue={
                                  updateData[7].values[7].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[7].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {data.items[8].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[8].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "30rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[8].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="P2-L-C-5-1-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "30rem", paddingTop: "1.2rem" }}
                                  defaultValue={
                                    updateData[8].rows[0].value1
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[8].rows[0].id1,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "30rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                   {data.items[8].rows[0].name2}
                                </Typography>
                                <TextField
                                  id="P2-L-C-5-1-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "29rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[8].rows[0].value2
                                  }
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[8].rows[0].id2,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <TableRows5
                            rows5={rows5}
                            tableRowRemove5={tableRowRemove5}
                            onValUpdate5={onValUpdate5}
                          />
                          <div style={{ padding: "0.5rem 0 0 0" }}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={addRowTable5}
                            >
                              Add New Row
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default SectionC2;
