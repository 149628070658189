import { useEffect, useRef } from "react";

export default function PdfViewerComponent(props) {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    let PSPDFKit;

    (async function () {
      PSPDFKit = await import("pspdfkit");
      await PSPDFKit.load({
        licenseKey: 'KxYLc71NLIYCT8M5mKSCygwAP_zqHYM2qesBecrUNTEMUCr4vGzelg35kjXfCXJ6RRck6zLTH_A1JWCN3ptN2R8sPZ63-gH1oLSE1Z9sqsfc5icFdIV2kDai2nZUDEkG9JTmKGljyyuBe_5SvUHVZaeCynpF9h2DYR-ktD72gOea7ecQ4V9Hn5NIJ4n42GhEZKvfUaw7rYMvRHVI_SdRwdclpszRDR7edwRvt1KrD577GL-sQFArofwu3Kjvb7G8d6aVtbxmmG2QPS6gMkcTCK0XiWVJlh1Z1NYLB2M72HZGv3JqAci0JuaDHaNHbD9LJBpMb7UtRnBxDh8G2iMpX1g7VizMcdNpU67tUIMJ3t5ZOwkhq-kpbC77Pn28M6onYfwwS5XQ0l3L1jKGElkZwjB7nDK5FhQK303myxA1lRUGGGucEKAVX48654k-YzDzpkqNlfyyppdd8p8bGvTaTt0fIOwg_feI6gfeOMDmnuREZsPzxI1Rlc1Sap8IN1U2KuWjSsWnIQTm8dcp5yh2hes-yGQwQZy9s0lp9HoHmsEemCqbqCipMTOa1IbwxURuO7ts2146JJq44kTK86InwTRVKNsE3vnST9KgZ2Ndv8M=',
        // Container where PSPDFKit should be mounted.
        container,
        // The document to open.
        document: props.document,
        // Use the public directory URL as a base URL. PSPDFKit will download its library assets from here.
        baseUrl: `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`,
        
      });
    })();

    return () => PSPDFKit && PSPDFKit.unload(container);
  }, [props.document]);

  return <div ref={containerRef} style={{ width: "100%", height: "100vh" }} />;
};