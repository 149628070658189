import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useNavigate } from "react-router-dom";
import { Box, Button, Paper, Typography, useTheme, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
const ProductMembers = () => {
  const [productMembers, setProductMembers] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { name, pid, companyid } = useParams();
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const getProductMembers = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/product/${pid}/${companyid}/members`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res.ok) setError(res.message);
          else return res.json();
        })
        .then((data) => {
          setProductMembers(data);
        })
        .catch((err) => {
          console.log("Error :" + err);
          setError("Internal Server Error");
        });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  useEffect(() => {
    getProductMembers();
  }, []);

  return (
    <>
      <Grid direction="row" alignItems={"right"} alignContent={"space-evenly"}>
        <Box padding={"2rem 3rem 0 3rem"}>
          <Button
            variant="contained"
            size="large"
            sx={{
              color: colors.purple[100],
            }}
            onClick={() => {
              navigate(`/products/${name}/${pid}/${companyid}/addmembers`);
            }}
          >
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{
                color: colors.white[200],
              }}
            >
              Add Members
            </Typography>
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{
              color: colors.purple[100],
            }}
            onClick={() => {
              navigate(`/products/${name}/${pid}/${companyid}/removemembers`);
            }}
          >
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{
                color: colors.white[200],
              }}
            >
              Remove Members
            </Typography>
          </Button>
        </Box>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Designation</TableCell>
              <TableCell align="right">Views</TableCell>
              <TableCell align="right">Downloads</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productMembers.length !== 0 &&
              productMembers.map((row) => (
                <TableRow key={row.uid}>
                  <TableCell component="th" scope="row">
                    {row.firstName + " " + row.lastName}
                  </TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.designation}</TableCell>
                  <TableCell align="right">{row.views}</TableCell>
                  <TableCell align="right">{row.downloads}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductMembers;
