import { Box, Typography, useTheme, Container } from "@mui/material";
import { Grid, Card, Stack, CardContent } from "@mui/material";
import { useState } from "react";

import { tokens } from "../../theme";
import { UserAuth } from "context/AuthContext";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import SignalCellularAltOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined';
import { useNavigate } from "react-router-dom";

const HomePage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const { user } = UserAuth();

  console.log("In homepage");

  return (
    <main>
      <div>
        <Container maxWidth="ls">
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"10rem"}
            marginBottom={"7rem"}
          >
            <Stack direction={"column"} justifyContent="center">
              <Grid>
                <Stack direction={"row"}>
                  <Typography
                    variant="h2"
                    textAlign={"center"}
                    sx={{
                      color: colors.grey[300],
                    }}
                  >
                    Welcome! Let's begin your Sustainability journey with
                  </Typography>
                  <Typography
                    variant="h2"
                    textAlign={"center"}
                    sx={{
                      color: colors.purple[200],
                    }}
                  >
                    &nbsp;Aquarius
                  </Typography>
                  <Typography
                    variant="h2"
                    textAlign={"center"}
                    sx={{
                      color: colors.grey[300],
                    }}
                  >
                    .
                  </Typography>
                </Stack>
              </Grid>
              <Grid padding={"2rem 0 0 22rem"}>
                <Stack direction={"row"}>
                  <Typography
                    variant="h5"
                    textAlign={"center"}
                    sx={{
                      color: colors.grey[300],
                    }}
                  >
                    Powered by
                  </Typography>
                  <Typography
                    variant="h5"
                    textAlign={"center"}
                    sx={{
                      color: colors.purple[200],
                    }}
                  >
                    &nbsp;AI
                  </Typography>
                  <Typography
                    variant="h5"
                    textAlign={"center"}
                    sx={{
                      color: colors.grey[300],
                    }}
                  >
                    OU
                  </Typography>
                </Stack>
              </Grid>
            </Stack>
          </Grid>
          <Stack direction={"row"} marginLeft={"18rem"}>
            <div
              onClick={() => {
                navigate("/strategy");
              }}
            >
              <Card
                sx={{
                  height: "10rem",
                  width: "15rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  alignItems: "center",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Stack direction={"column"}>
                    <AutoGraphIcon
                      sx={{
                        color: colors.purple[100],
                        fontSize: "4rem",
                        marginLeft: "3rem",
                      }}
                    />
                    <Typography
                      component="h6"
                      variant="h6"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginTop="1rem"
                    >
                      Strategise ESG Roadmap
                    </Typography>
                  </Stack>
                </CardContent>
              </Card>
            </div>
            <div
              onClick={() => {
                navigate("/studio")
              }}
            >
            <Card
              sx={{
                height: "10rem",
                width: "15rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                border: colors.purple[200],
                marginLeft: "2rem",
                alignItems: "center",
              }}
            >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Stack direction={"column"}>
                    <AccountTreeOutlinedIcon
                      sx={{
                        color: colors.purple[100],
                        fontSize: "3.5rem",
                        marginLeft: "4.5rem",
                        marginTop: "0.5rem"
                    
                      }}
                    />
                    <Typography
                      component="h6"
                      variant="h6"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginTop="1rem"
                      textAlign= "center"
                    >
                      Standardized Sustainability Reporting
                    </Typography>
                  </Stack>
                </CardContent>
                </Card>
            </div>
            <div
              onClick={() => {
                navigate("/dashboard");
              }}
            >
            <Card
              sx={{
                height: "10rem",
                width: "15rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                border: colors.purple[200],
                marginLeft: "2rem",
                alignItems: "center",
              }}
            >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Stack direction={"column"}>
                    <SignalCellularAltOutlinedIcon
                      sx={{
                        color: colors.purple[100],
                        fontSize: "3.5rem",
                        marginLeft: "4.5rem",
                        marginTop: "0.5rem"
                    
                      }}
                    />
                    <Typography
                      component="h6"
                      variant="h6"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginTop="1rem"
                      textAlign= "center"
                    >
                      ESG Business Intelligence & Analytics
                    </Typography>
                  </Stack>
                </CardContent>
            </Card>
            </div>
          </Stack>
        </Container>
      </div>
    </main>
  );
};

export default HomePage;
