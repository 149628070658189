import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// color design token export

export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        purple: {
          100: "#4b0081",
          200: "#9147d8",
        },
        blue: {
          100: "#2d45cd",
          200: "#576ad7",
        },
        green: {
          100: "#10d792",
        },
        white: {
          100: "#ebf0f4",
          200: "#ffffff",
        },
        grey: {
          100: "#333333",
          200: "#e0e0e0",
        },
      }
    : {
        purple: {
          100: "#5f17cb",
          200: "#9147d8",
        },

        blue: {
          100: "#2d45cd",
          200: "#bac4ff",
        },
        green: {
          100: "#10d792",
          200: "#cdffee"
        },
        white: {
          100: "#ebf0f4",
          200: "#eff3fe",
          300: "#ffffff",
        },
        grey: {
          100: "#333333",
          200: "#e0e0e0",
          300: "#818cbb",
          400: "#f3f5fe"
        },
        yellow: {
          100: "#ffd900",
          200: "#fff5bd"
        }
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);

  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            primary: {
              main: colors.purple[100],
            },
            secondary: {
              main: colors.green[100],
            },
            neutral: {
              dark: colors.grey[100],
              light: colors.grey[200],
            },
            background: {
              default: colors.white[200],
              alt: colors.white[100],
            },
          }
        : {
            primary: {
              main: colors.purple[100],
            },
            secondary: {
              main: colors.green[100],
            },
            neutral: {
              dark: colors.grey[100],
              light: colors.grey[200],
            },
            background: {
              default: colors.white[200],
              alt: colors.white[100],
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 24,
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 14,
      },
    },
  };
};

//context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});

export const useMode = () => {
  const [mode, setMode] = useState("light");

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );

    const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

    return [theme, colorMode];
}
