import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Topbar from "scenes/global/Topbar";
import { setTopbar } from "state";
import { useDispatch, useSelector } from "react-redux";
import SectionA1 from "./SectionA_1.jsx";
import SectionA2 from "./SectionA_2.jsx";
import SectionA3 from "./SectionA_3.jsx";
import SectionA4 from "./SectionA_4.jsx";
import SectionA5 from "./SectionA_5.jsx";
import SectionA6 from "./SectionA_6.jsx";
import SectionA7 from "./SectionA_7.jsx";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

const SectionAPage = () => {
  console.log("Inside Section A page");

  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();
  const colors = tokens(theme.palette.mode);
  const { state } = useLocation();
  const { data } = state;
  const reportName = useSelector((state) => state.reportName);
  dispatch(setTopbar({ pageName: "Section A", breadcrumbs: ["Reporting Studio", reportName, "Section A"]  }));


  const blocks = [
  <SectionA1 data={data['section'][0]}/>, 
  <SectionA2 data={data['section'][1]}/>, 
  <SectionA3 data={data['section'][2]}/>, 
  <SectionA4 data={data['section'][3]}/>, 
  <SectionA5 data={data['section'][4]}/>, 
  <SectionA6 data={data['section'][5]}/>,
  <SectionA7 data={data['section'][6]}/>];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < blocks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <main>
      <div>
      <Topbar/>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 0
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column" >
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 0
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"3.2rem"}
                    >
                      1 Details of the listed entity
                    </Typography>
                    <Box sx={{width:"5rem"}}>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="medium"
                      color={
                        currentIndex === 0
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                    >
                      50 %
                    </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 1
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 1
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"3.2rem"}
                    >
                      2 Products/ Services
                    </Typography>
                    <Box sx={{width:"5rem"}}>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="medium"
                      color={colors.grey[100]}
                    >
                      50 %
                    </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 2
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 2
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.3rem"}
                    >
                      3 Operations
                    </Typography>
                    <Box sx={{width:"5rem"}}>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="medium"
                      color={colors.grey[100]}
                    >
                      50 %
                    </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 3
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 3
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.3rem"}
                    >
                      4 Employees
                    </Typography>
                    <Box sx={{width:"5rem"}}>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="medium"
                      color={colors.grey[100]}
                    >
                      50 %
                    </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 4
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 4
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"1rem"}
                    >
                      5 Holding Subsidiary & Associate Companies
                    </Typography>
                    <Box sx={{width:"5rem"}}>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="medium"
                      color={colors.grey[100]}
                    >
                      50 %
                    </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 5
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 5
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.2rem"}
                    >
                      6 CSR Details
                    </Typography>
                    <Box sx={{width:"5rem"}}>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="medium"
                      color={colors.grey[100]}
                    >
                      50 %
                    </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 6
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 6
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"2rem"}
                    >
                      7 Transparency {"\n"} & Disclosure Compliances
                    </Typography>
                    <Box sx={{width:"5rem"}}>
                    <BorderLinearProgress variant="determinate" value={50} />
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="medium"
                      color={colors.grey[100]}
                    >
                      50 %
                    </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ marginTop: "1rem", marginLeft: "2rem" }}>
        <Box sx={{ display: "flex", justifyContent: "left" }}>
          <ArrowBackIosIcon
            style={{ color: colors.purple[200], fontSize: "15px" }}
            onClick={handlePrevClick}
          />
          <ArrowForwardIosIcon
            style={{ color: colors.purple[200], fontSize: "15px" }}
            onClick={handleNextClick}
          />
        </Box>
      </div>
      {blocks[currentIndex]}
    </main>
  );
};

export default SectionAPage;
