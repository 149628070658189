import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { tokens } from "../../../theme";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

const SectionA1 = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState();
  const data = props.data;
  const [updateData, setUpdateData] = useState(data["items"]);
  const reportName = useSelector((state) => state.reportName);
  const token = useSelector((state) => state.token);

  const updateFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj.id === id) {
        obj.value = val;
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const updateArrayFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index) => {
          if (ele.id === id) {
            obj["values"][index].value = val;
          }
        });
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const saveData = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/studio/brsr/save/sectionA`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reportName: reportName,
          data: updateData,
          section: "A-1"
        }),
      }).then((res) => {
        if (!res.ok) setError("Unable to save data");
      });
    } catch (e) {
      console.log(e.message);
      setError("Unable to create report");
    }
  };

  return (
    <main>
      <div>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "9rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    component="h5"
                    variant="h5"
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginLeft={"1rem"}
                  >
                    {data.name}
                  </Typography>
                  <Stack direction="row">
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                      marginRight={"1rem"}
                    >
                      Edit
                    </Typography>
                  </Stack>
                </CardContent>
                <Divider />
                <CardContent
                  sx={{
                    width: "6rem",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <div style={{ paddingLeft: "4rem" }}>
                    <Stack
                      direction={"row"}
                      sx={{ justifyContent: "space-around" }}
                    >
                      <div>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Approved
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <DataSaverOffIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Filled
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CalendarMonthIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Due Date
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              31st Jan 2024
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CommentOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Comments
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              0 Comments
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <ListAltOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Open Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <PersonOutlineOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;My Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[0].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[0].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-1"
                              variant="outlined"
                              defaultValue={updateData[0].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[0].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[1].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[1].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-2"
                              variant="outlined"
                              value={updateData[1].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[1].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[2].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[2].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-3"
                              variant="outlined"
                              value={updateData[2].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[2].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[3].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[3].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-4"
                              variant="outlined"
                              value={updateData[3].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[3].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[4].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[4].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-5"
                              variant="outlined"
                              value={updateData[4].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[4].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[5].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[5].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-6"
                              variant="outlined"
                              value={updateData[5].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[5].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[6].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[6].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-7"
                              variant="outlined"
                              value={updateData[6].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[6].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[7].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[7].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-8"
                              variant="outlined"
                              value={updateData[7].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[7].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[8].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[8].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-9"
                              variant="outlined"
                              value={updateData[8].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[8].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[9].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[9].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-10"
                              variant="outlined"
                              value={updateData[9].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[9].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[10].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[10].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-11"
                              variant="outlined"
                              value={updateData[10].value}
                              size="small"
                              sx={{ width: "60rem" }}
                              onChange={(e) => {
                                updateFields(data.items[10].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[11].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[11].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="A-1-12-1"
                              variant="outlined"
                              label="Name"
                              value={updateData[11].values[0].value}
                              size="small"
                              sx={{ width: "15rem" }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[11].values[0].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-1-12-2"
                              variant="outlined"
                              label="Designation"
                              value={updateData[11].values[1].value}
                              size="small"
                              sx={{ width: "14rem", marginLeft: "1rem" }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[11].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-1-12-3"
                              variant="outlined"
                              label="Telephone"
                              value={updateData[11].values[2].value}
                              size="small"
                              sx={{ width: "14rem", marginLeft: "1rem" }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[11].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-1-12-4"
                              variant="outlined"
                              label="Email"
                              value={updateData[11].values[3].value}
                              size="small"
                              sx={{ width: "14rem", marginLeft: "1rem" }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[11].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                      <div style={{ paddingTop: "1rem" }}>
                        <Stack direction={"row"}>
                          <Box sx={{ width: "4rem" }}>
                          <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[12].id}
                        </Typography>
                        
                          </Box>
                          <Box sx={{ width: "55rem" }}>
                          <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[12].name}
                        </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <TextField
                              id="1-1"
                              variant="outlined"
                              size="small"
                              sx={{ width: "60rem" }}
                              value={updateData[12].value}
                              onChange={(e) => {
                                updateFields(data.items[12].id, e.target.value);
                              }}
                            />
                            <Box sx={{ padding: "10px 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.purple[100],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 5rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default SectionA1;
