import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { Box, Button, Paper, Typography, useTheme, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { tokens } from "../../theme";
import PropTypes from "prop-types";

function EnhancedTableHead(props) {
  const { onSelectAllClick, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        <TableCell>Name</TableCell>
        <TableCell align="right">Email</TableCell>
        <TableCell align="right">Designation</TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const ProductRemoveMembers = () => {
  const [productMembers, setProductMembers] = useState([]);
  const [selected, setSelected] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { name, pid, companyid } = useParams();
  const token = useSelector((state) => state.token);
  const userid = useSelector((state) => state.userid);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = productMembers;
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, member) => {
    const selectedIndex = selected.indexOf(member);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, member);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (member) => selected.indexOf(member) !== -1;

  const getProductMembers = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/product/${pid}/${companyid}/members`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res.ok) setError(res.message);
          else return res.json();
        })
        .then((data) => {
          if (data.length !== 0) {
            const filtereddata = data.filter((item) => item.uid !== userid);
            setProductMembers(filtereddata);
          }
        })
        .catch((err) => {
          console.log("Error :" + err);
          setError("Internal Server Error");
        });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  const withdrawMembers = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/product/${companyid}/withdraw/${pid}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          members: selected,
        }),
      }).then((res) => {
        if (res.status === 201) {
          selected.map(async (member) => {
            await fetch(process.env.REACT_APP_SERVER_URL+`/email/product/withdraw`, {
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },

              body: JSON.stringify({
                to: member.email,
                from: "letter@aiou.co.in",
                subject: `${name} Withdrawn-${
                  member.firstName + " " + member.lastName
                }`,
                text: `${
                  member.firstName + " " + member.lastName
                } has been withdrawn from Product:${name} `,
              }),
            });
          });

          navigate(`/products/${name}/${pid}/${companyid}/members`);
        }
      });
    } catch (e) {
      console.log(e.message);
      setError("Users not removed from product");
    }
  };

  useEffect(() => {
    getProductMembers();
  }, []);

  return (
    <>
      <Box padding={"2rem 3rem 0 3rem"}>
        <Button
          variant="contained"
          size="large"
          sx={{
            color: colors.purple[100],
          }}
          onClick={() => {
            withdrawMembers();
          }}
        >
          <Typography
            variant="h4"
            textAlign={"center"}
            sx={{
              color: colors.white[200],
            }}
          >
            Submit
          </Typography>
        </Button>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <EnhancedTableHead
                numSelected={selected.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={productMembers.length}
              />
              <TableBody>
                {productMembers.length !== 0 &&
                  productMembers.map((row, index) => {
                    const isItemSelected = isSelected(row);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.uid}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.firstName + " " + row.lastName}
                        </TableCell>
                        <TableCell align="right">{row.email}</TableCell>
                        <TableCell align="right">{row.designation}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
};

export default ProductRemoveMembers;
