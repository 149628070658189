import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const userid = useSelector((state) => state.userid);

  if (!userid) {
    console.log("Inside protected router");
    console.log(userid);
    return <Navigate to='/' />;
  }
  return children;
};

export default ProtectedRoute;