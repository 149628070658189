import { tokens } from "../theme";

// export const mockDataTeam = [
//   {
//     id: 1,
//     name: "Jon Snow",
//     email: "jonsnow@gmail.com",
//     age: 35,
//     phone: "(665)121-5454",
//     access: "admin",
//   },
//   {
//     id: 2,
//     name: "Cersei Lannister",
//     email: "cerseilannister@gmail.com",
//     age: 42,
//     phone: "(421)314-2288",
//     access: "manager",
//   },
//   {
//     id: 3,
//     name: "Jaime Lannister",
//     email: "jaimelannister@gmail.com",
//     age: 45,
//     phone: "(422)982-6739",
//     access: "user",
//   },
//   {
//     id: 4,
//     name: "Anya Stark",
//     email: "anyastark@gmail.com",
//     age: 16,
//     phone: "(921)425-6742",
//     access: "admin",
//   },
//   {
//     id: 5,
//     name: "Daenerys Targaryen",
//     email: "daenerystargaryen@gmail.com",
//     age: 31,
//     phone: "(421)445-1189",
//     access: "user",
//   },
//   {
//     id: 6,
//     name: "Ever Melisandre",
//     email: "evermelisandre@gmail.com",
//     age: 150,
//     phone: "(232)545-6483",
//     access: "manager",
//   },
//   {
//     id: 7,
//     name: "Ferrara Clifford",
//     email: "ferraraclifford@gmail.com",
//     age: 44,
//     phone: "(543)124-0123",
//     access: "user",
//   },
//   {
//     id: 8,
//     name: "Rossini Frances",
//     email: "rossinifrances@gmail.com",
//     age: 36,
//     phone: "(222)444-5555",
//     access: "user",
//   },
//   {
//     id: 9,
//     name: "Harvey Roxie",
//     email: "harveyroxie@gmail.com",
//     age: 65,
//     phone: "(444)555-6239",
//     access: "admin",
//   },
// ];

// export const mockDataContacts = [
//   {
//     id: 1,
//     name: "Jon Snow",
//     email: "jonsnow@gmail.com",
//     age: 35,
//     phone: "(665)121-5454",
//     address: "0912 Won Street, Alabama, SY 10001",
//     city: "New York",
//     zipCode: "10001",
//     registrarId: 123512,
//   },
//   {
//     id: 2,
//     name: "Cersei Lannister",
//     email: "cerseilannister@gmail.com",
//     age: 42,
//     phone: "(421)314-2288",
//     address: "1234 Main Street, New York, NY 10001",
//     city: "New York",
//     zipCode: "13151",
//     registrarId: 123512,
//   },
//   {
//     id: 3,
//     name: "Jaime Lannister",
//     email: "jaimelannister@gmail.com",
//     age: 45,
//     phone: "(422)982-6739",
//     address: "3333 Want Blvd, Estanza, NAY 42125",
//     city: "New York",
//     zipCode: "87281",
//     registrarId: 4132513,
//   },
//   {
//     id: 4,
//     name: "Anya Stark",
//     email: "anyastark@gmail.com",
//     age: 16,
//     phone: "(921)425-6742",
//     address: "1514 Main Street, New York, NY 22298",
//     city: "New York",
//     zipCode: "15551",
//     registrarId: 123512,
//   },
//   {
//     id: 5,
//     name: "Daenerys Targaryen",
//     email: "daenerystargaryen@gmail.com",
//     age: 31,
//     phone: "(421)445-1189",
//     address: "11122 Welping Ave, Tenting, CD 21321",
//     city: "Tenting",
//     zipCode: "14215",
//     registrarId: 123512,
//   },
//   {
//     id: 6,
//     name: "Ever Melisandre",
//     email: "evermelisandre@gmail.com",
//     age: 150,
//     phone: "(232)545-6483",
//     address: "1234 Canvile Street, Esvazark, NY 10001",
//     city: "Esvazark",
//     zipCode: "10001",
//     registrarId: 123512,
//   },
//   {
//     id: 7,
//     name: "Ferrara Clifford",
//     email: "ferraraclifford@gmail.com",
//     age: 44,
//     phone: "(543)124-0123",
//     address: "22215 Super Street, Everting, ZO 515234",
//     city: "Evertin",
//     zipCode: "51523",
//     registrarId: 123512,
//   },
//   {
//     id: 8,
//     name: "Rossini Frances",
//     email: "rossinifrances@gmail.com",
//     age: 36,
//     phone: "(222)444-5555",
//     address: "4123 Ever Blvd, Wentington, AD 142213",
//     city: "Esteras",
//     zipCode: "44215",
//     registrarId: 512315,
//   },
//   {
//     id: 9,
//     name: "Harvey Roxie",
//     email: "harveyroxie@gmail.com",
//     age: 65,
//     phone: "(444)555-6239",
//     address: "51234 Avery Street, Cantory, ND 212412",
//     city: "Colunza",
//     zipCode: "111234",
//     registrarId: 928397,
//   },
//   {
//     id: 10,
//     name: "Enteri Redack",
//     email: "enteriredack@gmail.com",
//     age: 42,
//     phone: "(222)444-5555",
//     address: "4123 Easer Blvd, Wentington, AD 142213",
//     city: "Esteras",
//     zipCode: "44215",
//     registrarId: 533215,
//   },
//   {
//     id: 11,
//     name: "Steve Goodman",
//     email: "stevegoodmane@gmail.com",
//     age: 11,
//     phone: "(444)555-6239",
//     address: "51234 Fiveton Street, CunFory, ND 212412",
//     city: "Colunza",
//     zipCode: "1234",
//     registrarId: 92197,
//   },
// ];

// export const mockDataInvoices = [
//   {
//     id: 1,
//     name: "Jon Snow",
//     email: "jonsnow@gmail.com",
//     cost: "21.24",
//     phone: "(665)121-5454",
//     date: "03/12/2022",
//   },
//   {
//     id: 2,
//     name: "Cersei Lannister",
//     email: "cerseilannister@gmail.com",
//     cost: "1.24",
//     phone: "(421)314-2288",
//     date: "06/15/2021",
//   },
//   {
//     id: 3,
//     name: "Jaime Lannister",
//     email: "jaimelannister@gmail.com",
//     cost: "11.24",
//     phone: "(422)982-6739",
//     date: "05/02/2022",
//   },
//   {
//     id: 4,
//     name: "Anya Stark",
//     email: "anyastark@gmail.com",
//     cost: "80.55",
//     phone: "(921)425-6742",
//     date: "03/21/2022",
//   },
//   {
//     id: 5,
//     name: "Daenerys Targaryen",
//     email: "daenerystargaryen@gmail.com",
//     cost: "1.24",
//     phone: "(421)445-1189",
//     date: "01/12/2021",
//   },
//   {
//     id: 6,
//     name: "Ever Melisandre",
//     email: "evermelisandre@gmail.com",
//     cost: "63.12",
//     phone: "(232)545-6483",
//     date: "11/02/2022",
//   },
//   {
//     id: 7,
//     name: "Ferrara Clifford",
//     email: "ferraraclifford@gmail.com",
//     cost: "52.42",
//     phone: "(543)124-0123",
//     date: "02/11/2022",
//   },
//   {
//     id: 8,
//     name: "Rossini Frances",
//     email: "rossinifrances@gmail.com",
//     cost: "21.24",
//     phone: "(222)444-5555",
//     date: "05/02/2021",
//   },
// ];

// export const mockTransactions = [
//   {
//     txId: "01e4dsa",
//     user: "johndoe",
//     date: "2021-09-01",
//     cost: "43.95",
//   },
//   {
//     txId: "0315dsaa",
//     user: "jackdower",
//     date: "2022-04-01",
//     cost: "133.45",
//   },
//   {
//     txId: "01e4dsa",
//     user: "aberdohnny",
//     date: "2021-09-01",
//     cost: "43.95",
//   },
//   {
//     txId: "51034szv",
//     user: "goodmanave",
//     date: "2022-11-05",
//     cost: "200.95",
//   },
//   {
//     txId: "0a123sb",
//     user: "stevebower",
//     date: "2022-11-02",
//     cost: "13.55",
//   },
//   {
//     txId: "01e4dsa",
//     user: "aberdohnny",
//     date: "2021-09-01",
//     cost: "43.95",
//   },
//   {
//     txId: "120s51a",
//     user: "wootzifer",
//     date: "2019-04-15",
//     cost: "24.20",
//   },
//   {
//     txId: "0315dsaa",
//     user: "jackdower",
//     date: "2022-04-01",
//     cost: "133.45",
//   },
// ];

export const mockBarData = [
  {
    period: "Apr-Jun 22",
    value: 20000,
  },
  {
    period: "Jul-Sep 22",
    value: 30000,
  },
  {
    period: "Oct-Dec 22",
    value: 25000,
  },
  {
    period: "Jan-Mar 23",
    value: 35000,
  },
];

export const mockBar2Data = [
  {
    period: "FY 2019-20",
    Male: 1000,
    Female: 200 
  },
  {
    period: "FY 2020-21",
    Male: 1100,
    Female: 300 
  },
  {
    period: "FY 2021-22",
    Male: 1000,
    Female: 280 
  },
  {
    period: "FY 2022-23",
    Male: 1150,
    Female: 280 
  },
];

export const mockBar3Data = [
  {
    period: "Male",
    value1: 288.68,
    value2: 0
  },
  {
    period: "Female",
    value1:0,
    value2: 223.73,
  },
];



export const mockPieData = [
  {
    id: "1",
    label: "Metal",
    value: 16.7,
  },
  {
    id: "2",
    label: "Paper",
    value: 55.6,
  },
  {
    id: "3",
    label: "Plastic",
    value: 27.7,
  },
];

export const mockPie2Data = [
  {
    id: "1",
    label: "Male",
    value: 55.6,
  },
  {
    id: "2",
    label: "Female",
    value: 16.7,
  },
];

export const mockLineData = [
  {
    id: "CO2",
    data: [
      {
        x: "Apr 22",
        y: 15000,
      },
      {
        x: "May 22",
        y: 17000,
      },
      {
        x: "Jun 22",
        y: 11000,
      },
      {
        x: "Jul 22",
        y: 10000,
      },
      {
        x: "Aug 22",
        y: 13000,
      },
      {
        x: "Sep 22",
        y: 14000,
      },
      {
        x: "Oct 22",
        y: 12000,
      },
      {
        x: "Nov 22",
        y: 14000,
      },
      {
        x: "Dec 22",
        y: 15000,
      },
      {
        x: "Jan 23",
        y: 12000,
      },
      {
        x: "Feb 23",
        y: 16000,
      },
      {
        x: "Mar 23",
        y: 13000,
      },
    ],
  }
];

export const mockLine2Data = [
  {
    id: "Male",
    data: [
      {
        x: "FY 2019-20",
        y: 3,
      },
      {
        x: "FY 2020-21",
        y: 8,
      },
      {
        x: "FY 2021-22",
        y: 11,
      },
      {
        x: "FY 2022-23",
        y: 14,
      }
    ],
  },
  {
    id: "Female",
    data: [
      {
        x: "FY 2019-20",
        y: 1,
      },
      {
        x: "FY 2020-21",
        y: 4,
      },
      {
        x: "FY 2021-22",
        y: 5,
      },
      {
        x: "FY 2022-23",
        y: 10,
      }
    ],
  }

];

export const mockBar4Data = [
  {
    period: "Sexual Harassment",
    FY2022_23: 3,
    FY2021_22: 1 
  },
  {
    period: "Discrimination",
    FY2022_23: 2,
    FY2021_22: 0 
  },
  {
    period: "Child Labour",
    FY2022_23: 1,
    FY2021_22: 1 
  },
  {
    period: "Forced Labour",
    FY2022_23: 2,
    FY2021_22: 1 
  },
  {
    period: "Wages",
    FY2022_23: 2,
    FY2021_22: 2 
  },
  {
    period: "Others",
    FY2022_23: 4,
    FY2021_22: 2 
  },
];

export const mockBar5Data = [
  {
    period: "Data privacy",
    FY2022_23: 5,
    FY2021_22: 2 
  },
  {
    period: "Advertising",
    FY2022_23: 1,
    FY2021_22: 3 
  },
  {
    period: "Cyber-Security",
    FY2022_23: 0,
    FY2021_22: 1 
  },
  {
    period: "Restrictive Trade Practices",
    FY2022_23: 3,
    FY2021_22: 2 
  },
  {
    period: "Unfair Trade Practices",
    FY2022_23: 1,
    FY2021_22: 2 
  },
  {
    period: "Others",
    FY2022_23: 1,
    FY2021_22: 2 
  },
];

