import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

const Value = [
  {
    value: "Yes",
    label: "Yes",
  },
  {
    value: "No",
    label: "No",
  },
];

const SectionB1 = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState();
  const data = props.data;
  const [updateData, setUpdateData] = useState(data["items"]);
  const reportName = useSelector((state) => state.reportName);
  const token = useSelector((state) => state.token);


  const updateArrayFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index) => {
          if (ele.id === id) {
            obj["values"][index].value = val;
          } 
        });
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const saveData = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/studio/brsr/save/sectionB`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reportName: reportName,
          data: updateData,
          section: "B-1"
        }),
      }).then((res) => {
        if (!res.ok) setError("Unable to save data");
      });
    } catch (e) {
      console.log(e.message);
      setError("Unable to create report");
    }
  };


  return (
    <main>
      <div>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "9rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    component="h5"
                    variant="h5"
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginLeft={"1rem"}
                  >
                    {data.name}
                  </Typography>
                  <Stack direction="row">
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                      marginRight={"1rem"}
                    >
                      Edit
                    </Typography>
                  </Stack>
                </CardContent>
                <Divider />
                <CardContent
                  sx={{
                    width: "6rem",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <div style={{ paddingLeft: "4rem" }}>
                    <Stack
                      direction={"row"}
                      sx={{ justifyContent: "space-around" }}
                    >
                      <div>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Approved
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <DataSaverOffIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Filled
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CalendarMonthIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Due Date
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              31st Jan 2024
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CommentOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Comments
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              0 Comments
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <ListAltOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Open Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <PersonOutlineOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;My Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[0].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[0].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-1"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[0].value}
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-2"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[1].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-3"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[2].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-4"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[3].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-5"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[4].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-6"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[5].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-7"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[6].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-8"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[7].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-A-9"
                                  variant="outlined"
                                  defaultValue={updateData[0].values[8].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[0].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[1].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[1].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[1].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-1"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[0].value}
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-2"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[1].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-3"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[2].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-4"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[3].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-5"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[4].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-6"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[5].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-7"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[6].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-8"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[7].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-B-9"
                                  variant="outlined"
                                  defaultValue={updateData[1].values[8].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[1].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[2].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[2].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[2].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-1"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[0].value}
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[0].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-2"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[1].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[1].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-3"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[2].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[2].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-4"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[3].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[3].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-5"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[4].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[4].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-6"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[5].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[5].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-7"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[6].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[6].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-8"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[7].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[7].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-1-C-9"
                                  variant="outlined"
                                  defaultValue={updateData[2].values[8].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[2].values[8].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[3].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[3].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[3].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-1"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[0].value}
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-2"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[1].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-3"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[2].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-4"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[3].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-5"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[4].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-6"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[5].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-7"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[6].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-8"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[7].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-2-9"
                                  variant="outlined"
                                  defaultValue={updateData[3].values[8].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[3].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[4].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[4].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[4].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-1"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[0].value}
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-2"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[1].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-3"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[2].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-4"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[3].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-5"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[4].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-6"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[5].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-7"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[6].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-8"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[7].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-3-9"
                                  variant="outlined"
                                  defaultValue={updateData[4].values[8].value}
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[4].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[5].id}&nbsp;
                        </Typography>
                        <Box sx={{ width: "60rem" }}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[5].name}
                        </Typography>
                        </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[5].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[5].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[0].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[1].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[2].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[3].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[4].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[5].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[6].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[7].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-4-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[5].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[5].values[8].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[6].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[6].name}
                        </Typography>
                        </Stack>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[6].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[6].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[0].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[1].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[2].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[3].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[4].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[5].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[6].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[7].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[6].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-5-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[6].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[6].values[8].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"row"}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[7].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[7].name}
                        </Typography>
                        </Stack>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[7].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[7].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[0].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[1].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[2].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[3].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[4].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[5].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[6].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[7].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-6-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[7].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[7].values[8].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Stack direction={"column"}>
                          <Stack direction={"row"}>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[8].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[8].name}
                        </Typography>
                        </Stack>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                            paddingTop={"1rem"}
                          >
                            {data.items[9].name}
                          </Typography>
                        </Stack>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[9].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[9].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[9].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-A-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[9].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[9].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                            paddingTop={"1rem"}
                          >
                            {data.items[10].name}
                          </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[10].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[10].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-B-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[10].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[10].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                         {data.items[11].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[11].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[11].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[11].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-C-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[11].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[11].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[12].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[12].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[12].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[0].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[1].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[2].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[3].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[4].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[5].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[6].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[7].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[12].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-D-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[12].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[12].values[8].id, e.target.value);
                                  }}
                                  select
                                >
                                  {Value.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[13].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "6rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[13].values[0].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem" }}
                                  defaultValue={updateData[13].values[0].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[0].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[1].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[1].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[1].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[2].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[2].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[2].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[3].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[3].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[3].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[4].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[4].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[4].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[5].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[5].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[5].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[6].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-7"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[6].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[6].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[7].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-8"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[7].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[7].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "7rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[13].values[8].name}
                                </Typography>
                                <TextField
                                  id="B-1-7-E-9"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  defaultValue={updateData[13].values[8].value}
                                  onChange={(e) => {
                                    updateArrayFields(data.items[13].values[8].id, e.target.value);
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 5rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default SectionB1;
