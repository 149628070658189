import React, { memo } from "react";
import { Handle, Position } from "reactflow";
import { useTheme, Card, CardContent, Typography, Box } from "@mui/material";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";

function CustomNode({ data }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();

  function renderSwitch(param) {
    switch (param) {
      case "Section A":
        return navigate("/studio/brsr/sectionA", { state: { data } });
      case "Section B":
        return navigate("/studio/brsr/sectionB", { state: { data } });
      case "Section C":
        return navigate("/studio/brsr/sectionC", { state: { data } });
      case "Generate Report":
        return navigate("/studio/brsr/reportGen", { state: { data } });
      case "HRMS File Upload":
        return navigate("/studio/brsr/fileupload", { type: "HRMS" });

      default:
        return "foo";
    }
  }

  return (
    <div
      onClick={() => {
        console.log("Inside Custom node");

        renderSwitch(data.name);
      }}
    >
      <Box sx={{ border: 1, borderRadius: 1, borderColor: colors.grey[200] }}>
        <Card
          sx={{
            height: "3rem",
            width: "8rem",
            display: "flex",
            flexDirection: "column",
            borderRadius: "5px",
          }}
        >
          <CardContent sx={{ display: "flex", justifyContent: "center" }}>
            <Typography
              component="subtitle1"
              variant="subtitle1"
              fontWeight="400"
              fontSize={10}
              color={colors.grey[100]}
            >
              &nbsp;{data.name}
            </Typography>
          </CardContent>
        </Card>
      </Box>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: colors.purple[200] }}
      />
      <Handle
        type="source"
        position={Position.Right}
        style={{ background: colors.purple[200] }}
      />
    </div>
  );
}

export default memo(CustomNode);
