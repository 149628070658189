import {
  useTheme,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
  Box,
  TextField,
  MenuItem,
  Button,
  Divider,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { SaveOutlined } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useSelector } from "react-redux";

const documentValue = [
  {
    value: "HRMS",
    label: "Human Resource Management System",
  },
  {
    value: "No",
    label: "No",
  },
];

const FileUpload = () => {
  console.log("Inside Fileupload page");

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [file, setFile] = useState("");
  const [error, setError] = useState("");

  const token = useSelector((state) => state.token);

  const handleFileUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("file", file);

        fetch(process.env.REACT_APP_SERVER_URL+"/brsr/fileupload", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            ContentType: 'multipart/form-data'
          },
          body: formData
        })
          .then((res) => {
            console.log(res);
            if (!res.ok) setError(res.message);
            else return res.json();
          })
          .catch((err) => {
            console.log("Error :" + err);
            setError("Internal Server Error");
          });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  return (
    <main>
      <div>
        <Stack direction="row">
          <Container
            maxWidth={false}
            style={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"right"}
            >
              <Grid item>
                <Card
                  sx={{
                    height: "3rem",
                    width: "6rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      Edit
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card
                  sx={{
                    height: "3rem",
                    width: "6rem",
                    display: "flex",
                    flexDirection: "column",

                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <SaveOutlined
                      style={{ color: colors.purple[200] }}
                    ></SaveOutlined>
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      &nbsp;Save
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card
                  sx={{
                    height: "3rem",
                    width: "6rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                    marginRight: "1rem",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <CancelOutlinedIcon
                      style={{ color: colors.purple[200] }}
                    ></CancelOutlinedIcon>
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      &nbsp;Cancel
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Stack>
      </div>
      <div style={{ padding: "2rem" }}>
        <Grid>
          <Card
            sx={{
              height: "30rem",
              width: "70rem",
              display: "flex",
              flexDirection: "column",
              mr: "0.5rem",
              borderRadius: "10px",
            }}
          >
            <CardContent sx={{ display: "flex", justifyContent: "left" }}>
              <Typography
                component="h5"
                variant="h5"
                fontWeight="bold"
                color={colors.grey[100]}
                marginLeft={"1rem"}
                paddingTop={"1rem"}
              >
                File Upload
              </Typography>
            </CardContent>
            <CardContent sx={{ display: "flex", justifyContent: "left" }}>
              <Box>
                <Stack direction={"row"}>
                  <Typography
                    component="h6"
                    variant="h6"
                    fontWeight="bold"
                    color={colors.grey[300]}
                    sx={{
                      marginLeft: "1rem",
                      paddingTop: "0.5rem",
                      paddingRight: "1rem",
                    }}
                  >
                    Document Type
                  </Typography>
                  <TextField
                    id="1-1"
                    variant="outlined"
                    size="small"
                    sx={{ width: "20rem" }}
                    select
                  >
                    {documentValue.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Stack>
              </Box>
              <Box sx={{ marginLeft: "8.5rem" }}>
                <Stack direction={"row"}>
                  <Box sx={{ paddingTop: "0.1rem" }}>
                    <input
                      id="upload-file"
                      type="file"
                      accept={".csv"}
                      onChange={(e) => setFile(e.target.files[0])}
                      style={{ display: "none" }}
                    />
                    <label htmlFor="upload-file">
                      <Button variant="outlined" size="medium" component="span">
                        Select Document
                      </Button>
                    </label>
                  </Box>

                  <TextField
                    id="1-1"
                    variant="outlined"
                    size="small"
                    value={file.name}
                    sx={{ width: "20rem", marginLeft: "1rem" }}
                  />
                </Stack>
              </Box>
            </CardContent>
            <CardContent sx={{ display: "flex", justifyContent: "right" }}>
              <Box sx={{ marginRight: "2.2rem" }}>
                <Stack direction={"row"}>
                  <Box sx={{ paddingRight: "0.5rem" }}>
                    <Button variant="outlined" size="medium">
                      Download Template
                    </Button>
                  </Box>
                  <Button
                    variant="outlined"
                    size="medium"
                    component="span"
                    onClick={handleFileUpload}
                  >
                    Upload
                  </Button>
                </Stack>
              </Box>
            </CardContent>
            <Divider sx={{ paddingTop: "1rem", color: colors.grey[100] }} />
            <CardContent sx={{ display: "flex", justifyContent: "left" }}>
              <Typography
                component="h5"
                variant="h5"
                fontWeight="bold"
                color={colors.grey[100]}
                marginLeft={"1rem"}
                paddingTop={"1rem"}
              >
                Data Preview
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </div>
    </main>
  );
};

export default FileUpload;
