import React, { useState, createContext, useContext } from "react";
import { ProSidebarProvider } from "react-pro-sidebar";
import MainSidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

const SidebarContext = createContext({});

export const MainSidebarProvider = (props) => {
  const [sidebarRTL, setSidebarRTL] = useState(false);
  const [sidebarBackgroundColor, setSidebarBackgroundColor] =
    useState(undefined);
  const [sidebarImage, setSidebarImage] = useState(undefined);
  
  return (
    <ProSidebarProvider>
      <SidebarContext.Provider
        value={{
          sidebarBackgroundColor,
          setSidebarBackgroundColor,

          sidebarImage,
          setSidebarImage,

          sidebarRTL,
          setSidebarRTL,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: sidebarRTL ? "row-reverse" : "row",
          }}
        >
          <MainSidebar data={props.data}/>
          {props.children}
        </div>
      </SidebarContext.Provider>
    </ProSidebarProvider>
    
  );
};

export const useSidebarContext = () => useContext(SidebarContext);
