import {
  useTheme,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useNavigate, useLocation } from "react-router-dom";
import { GroupsOutlined, SaveOutlined } from "@mui/icons-material";
import Topbar from "scenes/global/Topbar";
import ReactFlow, { Controls, Background, applyNodeChanges } from "reactflow";
import { useState, useCallback, useRef, useEffect } from "react";
import "reactflow/dist/style.css";
import CustomNode from "./CustomNode.js";
import { setReport, setTopbar } from "state";
import { useDispatch,useSelector } from "react-redux";

const nodeTypes = {
  custom: CustomNode,
};

const BRSRPage = () => {
  console.log("Inside BRSR page");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { state } = useLocation();
  const { _id, name, reportType, fy, nodes } = state;
  
  const [elements, setElements] = useState(nodes);
  const yPos = useRef(0);

  dispatch(setReport({ reportid: _id }));
  dispatch(setReport({ reportName: name }));
  dispatch(setTopbar({ pageName: name, breadcrumbs: ["Reporting Studio", name]  }));

  // const onNodesChange = useCallback(
  //   (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
  //   []
  // );

  const addElement = useCallback(() => {
    yPos.current += 100;
    setElements((elements) => {
      
      return [
        ...elements,
        {
          id: "1",
          sourcePosition: "right",
          targetPosition: "left",
          position: { x: 100, y: yPos.current },
          data: { label: "yo" },
          type: "custom",
        },
      ];
    });
  }, []);

  return (
    <main>
      <div>
        <Topbar/>
        <Stack direction="row">
          <Container
            maxWidth={false}
            style={{ width: "50%", marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <div onClick={addElement}>
                  <Card
                    sx={{
                      height: "3rem",
                      width: "6rem",
                      display: "flex",
                      flexDirection: "column",
                      mr: "2rem",
                      borderRadius: "10px",
                    }}
                  >
                    <CardContent
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        component="subtitle1"
                        variant="subtitle1"
                        fontWeight="600"
                        color={colors.grey[100]}
                      >
                        Add Node
                      </Typography>
                    </CardContent>
                  </Card>
                </div>
              </Grid>
            </Grid>
          </Container>
          <Container
            maxWidth={false}
            style={{ width: "50%", marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"right"}
            >
              <Grid item>
                <Card
                  sx={{
                    height: "3rem",
                    width: "6rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <GroupsOutlined
                      style={{ color: colors.purple[200] }}
                    ></GroupsOutlined>
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      &nbsp;Collab
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card
                  sx={{
                    height: "3rem",
                    width: "6rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      Edit
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item>
                <Card
                  sx={{
                    height: "3rem",
                    width: "6rem",
                    display: "flex",
                    flexDirection: "column",
                    mr: "1rem",
                    borderRadius: "10px",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <SaveOutlined
                      style={{ color: colors.purple[200] }}
                    ></SaveOutlined>
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                    >
                      &nbsp;Save
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Stack>
      </div>
      <div style={{ height: "28rem" }}>
        <ReactFlow nodes={elements} nodeTypes={nodeTypes}>
          <Background />
          <Controls />
        </ReactFlow>
      </div>
    </main>
  );
};

export default BRSRPage;
