import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));


const SectionC5 = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState();
  const data = props.data;
  const [updateData, setUpdateData] = useState(data["items"]);
  const reportName = useSelector((state) => state.reportName);
  const token = useSelector((state) => state.token);

  const updateFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj.id === id) {
        obj.value = val;
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const updateArrayFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index1) => {
          if (ele["values"]) {
            ele["values"].map((e, index2) => {
              if (e.id === id) {
                obj["values"][index1]["values"][index2].value = val;
              }
            });
          } else {
            if (ele.id === id) {
              obj["values"][index1].value = val;
            }
          }
        });
      }

      return obj;
    });
    setUpdateData(newData);
  };

  const saveData = async () => {
    try {
      await fetch(
        process.env.REACT_APP_SERVER_URL + `/studio/brsr/save/sectionC`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            reportName: reportName,
            data: updateData,
            section: "C-5",
          }),
        }
      ).then((res) => {
        if (!res.ok) setError("Unable to save data");
      });
    } catch (e) {
      console.log(e.message);
      setError("Unable to create report");
    }
  };

  return (
    <main>
      <div>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "9rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    component="h6"
                    variant="h6"
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginLeft={"1rem"}
                  >
                    {data.name}
                  </Typography>
                  <Stack direction="row">
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                      marginRight={"1rem"}
                    >
                      Edit
                    </Typography>
                  </Stack>
                </CardContent>
                <Divider />
                <CardContent
                  sx={{
                    width: "6rem",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <div style={{ paddingLeft: "4rem" }}>
                    <Stack
                      direction={"row"}
                      sx={{ justifyContent: "space-around" }}
                    >
                      <div>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Approved
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <DataSaverOffIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Filled
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CalendarMonthIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Due Date
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              31st Jan 2024
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CommentOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Comments
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              0 Comments
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <ListAltOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Open Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <PersonOutlineOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;My Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Typography
              component="h5"
              variant="h5"
              fontWeight="bold"
              color={colors.grey[300]}
              marginLeft={"2rem"}
              paddingTop={"2rem"}
            >
              Essential Indicators
            </Typography>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[0].id.split("-").slice(-2).reverse().pop()}-{data.items[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[0].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[0].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[0].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-1-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-1-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-1-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-1-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[0].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[1].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[1].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[2].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[2].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-1-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[2].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[2].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[0].values[3].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                   {data.items[0].values[3].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-2-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[3].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-2-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[3].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[3].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[3].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-2-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[3].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[3].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[3].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-A-2-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[0].values[3].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[3].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[4].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[4].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[4].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[4].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[5].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[5].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-A-2-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[0].values[5].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[0].values[5].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[1].id.split("-").slice(-2).reverse().pop()}-{data.items[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[1].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[1].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[1].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-1-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[1].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-1-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[1].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-1-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[1].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-1-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[1].values[0].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[1].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[1].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[1].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[1].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[2].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[2].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-1-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[2].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[2].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[1].values[3].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                   {data.items[1].values[3].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-2-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[1].values[3].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-2-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[1].values[3].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[3].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[3].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-2-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[1].values[3].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[3].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[1].values[3].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-1-B-2-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[1].values[3].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[1].values[3].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[1].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[4].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[4].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[4].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[4].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[1].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[5].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[5].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-1-B-2-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[1].values[5].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[1].values[5].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[2].id.split("-").slice(-2).reverse().pop()}-{data.items[2].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[2].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[2].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[2].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-1-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[2].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "2.15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-1-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-1-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-1-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[0].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[0].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-1-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[0].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[0].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[0].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-1-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[0].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[0].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[1].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[1].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[1].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[1].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[1].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[1].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[2].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[2].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[2].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[2].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[2].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[2].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-1-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[2].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[2].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "2rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[2].values[3].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-2-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[2].values[3].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-2-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[3].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[3].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[3].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-2-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[3].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[3].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[3].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-2-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[3].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[3].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[3].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-2-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[3].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[3].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[3].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-2-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[3].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[3].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[4].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[4].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[4].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[4].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[4].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[4].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[4].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[4].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[5].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[5].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[5].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[5].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[5].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[5].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-2-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[5].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[5].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "3rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[2].values[6].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[2].values[6].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-3-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[2].values[6].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "2.15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[6].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-3-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[6].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[6].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[6].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-3-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[6].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[6].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[6].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-3-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[6].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[6].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[6].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-3-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[6].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[6].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[6].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-3-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[6].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[6].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[7].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[7].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[7].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[7].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[7].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[7].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[7].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[7].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[7].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[7].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[7].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[8].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[8].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[8].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[8].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[8].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[8].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[8].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[8].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[8].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-3-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[8].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[8].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "2rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[2].values[9].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-4-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[2].values[9].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[9].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-4-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[9].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[9].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[9].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-4-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[9].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[9].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[9].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-4-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[9].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[9].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[9].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-4-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[9].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[9].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[2].values[9].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-A-4-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[2].values[9].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[2].values[9].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[10].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[10].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[10].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[10].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[10].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[10].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[10].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[10].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[10].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[10].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[10].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[2].values[11].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[11].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[11].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[11].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[11].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[11].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[11].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[11].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[11].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-A-4-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[2].values[11].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[2].values[11].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[3].id.split("-").slice(-2).reverse().pop()}-{data.items[3].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[3].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[3].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[3].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-1-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[3].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "2.15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-1-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-1-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-1-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[0].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[0].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-1-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[0].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[0].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[0].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-1-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[0].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[0].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[1].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[1].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[1].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[1].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[1].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[1].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[2].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[2].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[2].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[2].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[2].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[2].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-1-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[2].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[2].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "2rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[3].values[3].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-2-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[3].values[3].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "2.2rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-2-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[3].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[3].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[3].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-2-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[3].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[3].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[3].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-2-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[3].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[3].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[3].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-2-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[3].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[3].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[3].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-2-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[3].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[3].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[4].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[4].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[4].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[4].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[4].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[4].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[4].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[4].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[5].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[5].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[5].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[5].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[5].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[5].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-2-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[5].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[5].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "3rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[3].values[6].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[3].values[6].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-3-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[3].values[6].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "2.15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[6].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-3-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[6].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[6].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[6].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-3-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[6].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[6].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[6].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-3-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[6].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[6].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[6].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-3-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[6].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[6].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[6].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-3-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[6].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[6].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[7].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[7].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[7].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[7].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[7].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[7].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[7].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[7].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[7].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[7].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[7].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[8].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[8].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[8].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[8].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[8].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[8].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[8].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[8].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[8].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-3-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[8].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[8].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "2rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[3].values[9].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-4-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[3].values[9].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "10rem", paddingTop: "2.2rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[9].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-4-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.15rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[9].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[9].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[9].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-4-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[9].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[9].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[9].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-4-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[9].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[9].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[9].values[4].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-4-1-5"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[9].values[4].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[9].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[3].values[9].values[5].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-2-B-4-1-6"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "9rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[3].values[9].values[5].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[3].values[9].values[5].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[10].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[10].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[10].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[10].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[10].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[10].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[10].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-2-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[10].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[10].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-2-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[10].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[10].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[3].values[11].values[0].value
                                }
                                size="small"
                                sx={{ width: "10rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[11].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[11].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[11].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[11].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[11].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[11].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-3-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[11].values[4].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[11].values[4].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "10rem" }}>
                              <TextField
                                id="P5-E-C-2-B-4-3-6"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "9rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[3].values[11].values[5].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[3].values[11].values[5].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                          {data.items[4].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[4].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <Box sx={{ marginLeft: "1rem", paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[4].values[0].name}
                          </Typography>
                        </Box>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[4].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-1-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[4].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "20rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-1-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-1-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-1-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-1-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-1-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-1-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-1-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-1-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[2].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[2].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Box sx={{ marginLeft: "1rem", paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[4].values[3].name}
                          </Typography>
                        </Box>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                   {data.items[4].values[3].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-2-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[4].values[3].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "20rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                   {data.items[4].values[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-2-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[3].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[3].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                   {data.items[4].values[3].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-2-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[3].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[3].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-2-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-2-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-2-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[4].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[4].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-2-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-2-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-2-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[5].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[5].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Box sx={{ marginLeft: "1rem", paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[4].values[6].name}
                          </Typography>
                        </Box>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[4].values[6].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-3-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[4].values[6].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "20rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[6].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-3-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[6].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[6].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[6].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-3-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[6].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[6].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-3-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[7].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-3-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[7].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[7].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-3-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[7].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[7].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-3-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[8].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-3-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[8].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[8].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-3-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[8].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[8].values[2].id,
                                    e.target.value
                                  );
                                }}
                                
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Box sx={{ marginLeft: "1rem", paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[4].values[9].name}
                          </Typography>
                        </Box>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[4].values[9].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-4-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[4].values[9].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "20rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[9].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-4-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[9].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[9].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[4].values[9].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-3-4-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "19rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[4].values[9].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[4].values[9].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-4-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[10].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-4-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[10].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[10].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-4-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[10].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[10].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-4-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[4].values[11].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-4-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[11].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[11].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-3-4-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "19rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[4].values[11].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[4].values[11].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>

                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                          {data.items[5].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[5].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "10rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[5].values[0].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-4-1"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "10rem" }}
                                  defaultValue={
                                    updateData[5].values[0].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[5].values[0].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "50rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[5].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-4-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "49rem", marginLeft: "1rem" }}
                                  defaultValue={
                                    updateData[5].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[5].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "1.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                          {data.items[6].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[6].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-E-C-5"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                defaultValue={
                                  updateData[6].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[6].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "0.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                          {data.items[7].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[7].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[7].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-1-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[0].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "2.15rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-6-1-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[7].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-6-1-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[7].values[0].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-6-1-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[7].values[0].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[1].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[1].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[1].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[1].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[2].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[2].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[2].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[2].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-4-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[3].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-4-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[3].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[3].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-4-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[3].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[3].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-4-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[3].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[3].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-5-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-5-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-5-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[4].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[4].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-5-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[4].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[4].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-6-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-6-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-6-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[5].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[5].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-1-6-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[5].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[5].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                        <div
                          style={{ paddingTop: "2rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[7].values[6].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-1-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[6].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "2.15rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[6].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-6-2-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[7].values[6].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[6].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[6].values[2].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-6-2-1-3"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[7].values[6].values[2].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[6].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[7].values[6].values[3].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-6-2-1-4"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "14rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[7].values[6].values[3].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[7].values[6].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.8rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[7].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[7].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[7].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-2-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[7].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[7].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-2-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[7].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[7].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[8].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[8].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[8].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-3-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[8].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[8].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-3-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[8].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[8].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-4-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[9].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-4-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[9].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[9].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-4-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[9].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[9].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-4-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[9].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[9].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-5-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[10].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-5-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[10].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[10].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-5-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[10].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[10].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-5-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[10].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[10].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-6-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[7].values[11].values[0].value
                                }
                                size="small"
                                sx={{ width: "15rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-6-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[11].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[11].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-6-3"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[11].values[2].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[11].values[2].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "15rem" }}>
                              <TextField
                                id="P5-E-C-6-2-6-4"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[7].values[11].values[3].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[7].values[11].values[3].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.6rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[8].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[8].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-E-C-7"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                defaultValue={
                                  updateData[8].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[8].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "0.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[9].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[9].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-E-C-8"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                defaultValue={
                                  updateData[9].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[9].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "0.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[10].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[10].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-9-1-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[10].values[0].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "2.15rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <Stack>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[10].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-E-C-9-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "39rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[10].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[10].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-9-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[10].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-E-C-9-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[10].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[10].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-9-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[10].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-E-C-9-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[10].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[10].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-9-4-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[10].values[3].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-E-C-9-4-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[10].values[3].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[10].values[3].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-9-5-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[10].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-E-C-9-5-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[10].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[10].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-E-C-9-6-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[10].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-E-C-9-6-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[10].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[10].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[11].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[11].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-E-C-10"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                defaultValue={
                                  updateData[11].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[11].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "0.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>

              <Divider sx={{ paddingTop: "3rem", color: colors.grey[100] }} />
            </Grid>
            <Typography
              component="h5"
              variant="h5"
              fontWeight="bold"
              color={colors.grey[300]}
              marginLeft={"2rem"}
              paddingTop={"2rem"}
            >
              Leadership Indicators
            </Typography>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[12].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[12].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-L-C-1"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                defaultValue={
                                  updateData[12].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[12].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "0.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[13].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[13].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-L-C-2"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                defaultValue={
                                  updateData[13].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[13].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "0.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[14].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[14].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-L-C-3"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                defaultValue={
                                  updateData[14].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[14].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "0.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                           {data.items[15].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[15].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-L-C-4-1-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[15].values[0].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "2.1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[15].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="P5-L-C-4-1-2"
                                  variant="outlined"
                                  size="small"
                                  sx={{
                                    width: "39rem",
                                    marginLeft: "1rem",
                                    paddingTop: "1rem",
                                  }}
                                  defaultValue={
                                    updateData[15].values[0].values[1].value
                                  }
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[15].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "2.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-L-C-4-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[15].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-L-C-4-2-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[15].values[1].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[15].values[1].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-L-C-4-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[15].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-L-C-4-3-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[15].values[2].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[15].values[2].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-L-C-4-4-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[15].values[3].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-L-C-4-4-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[15].values[3].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[15].values[3].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-L-C-4-5-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[15].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-L-C-4-5-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[15].values[4].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[15].values[4].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "20rem" }}>
                              <TextField
                                id="P5-L-C-4-6-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[15].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "20rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <Box sx={{ width: "40rem" }}>
                              <TextField
                                id="P5-L-C-4-6-2"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "39rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[15].values[5].values[1].value
                                }
                                onChange={(e) => {
                                  updateArrayFields(
                                    data.items[15].values[5].values[1].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.5rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                  paddingTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>

                      <Stack direction={"row"}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                          {data.items[16].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Box sx={{ width: "60rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[16].name}
                            </Typography>
                          </Box>
                        </Stack>
                        <div
                          style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                        >
                          <Stack direction={"row"}>
                            <Box sx={{ width: "60rem" }}>
                              <TextField
                                id="P5-L-C-5"
                                variant="outlined"
                                size="small"
                                sx={{
                                  width: "60rem",
                                  paddingTop: "1rem",
                                }}
                                defaultValue={
                                  updateData[16].value
                                }
                                onChange={(e) => {
                                  updateFields(
                                    data.items[16].id,
                                    e.target.value
                                  );
                                }}
                              />
                            </Box>
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 4rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default SectionC5;
