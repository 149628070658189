import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  Switch,
  Divider,
  TextField,
  Button,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { tokens } from "../../../theme";
import { useState } from "react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useSelector } from "react-redux";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

function TableRows1({ rows1, tableRowRemove1, onValUpdate1 }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return rows1.map((rowsData1, index1) => {
    const objIndex = index1 + 1;
    return (
      <Stack direction={"row"}>
        <TextField
          id="1-1"
          variant="outlined"
          defaultValue = {rowsData1.value1}
          size="small"
          sx={{ width: "12rem", paddingTop: "0.5rem" }}
          onChange={(e) => onValUpdate1(objIndex, "var1", e.target.value)}
        />
        <TextField
          id="1-2"
          variant="outlined"
          defaultValue = {rowsData1.value2}
          size="small"
          sx={{ width: "11rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          onChange={(e) => onValUpdate1(objIndex, "var2", e.target.value)}
        />
        <TextField
          id="1-3"
          variant="outlined"
          defaultValue = {rowsData1.value3}
          size="small"
          sx={{ width: "11rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          onChange={(e) => onValUpdate1(objIndex, "var3", e.target.value)}
        />
        <TextField
          id="1-4"
          variant="outlined"
          defaultValue = {rowsData1.value4}
          size="small"
          sx={{ width: "11rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          onChange={(e) => onValUpdate1(objIndex, "var4", e.target.value)}
        />
        <TextField
          id="1-5"
          variant="outlined"
          defaultValue = {rowsData1.value5}
          size="small"
          sx={{ width: "11rem", marginLeft: "1rem", paddingTop: "0.5rem" }}
          onChange={(e) => onValUpdate1(objIndex, "var5", e.target.value)}
        />
        <Stack direction={"row"}>
          <Box sx={{ padding: "1.2rem 0 0 20px" }}>
            <CommentOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
            />
          </Box>
          <Box sx={{ padding: "1.1rem 0 0 10px" }}>
            <DeleteOutlinedIcon
              sx={{
                color: colors.grey[300],
                fontSize: "bold",
              }}
              onClick={() => tableRowRemove1(objIndex)}
            />
          </Box>
        </Stack>
      </Stack>
    );
  });
}

const SectionA7 = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState();
  const data = props.data;
  const [updateData, setUpdateData] = useState(data["items"]);
  const reportName = useSelector((state) => state.reportName);
  const token = useSelector((state) => state.token);

  const [rows1, initRow1] = useState(updateData[1].rows.slice(1));

  const addRowTable1 = () => {
    updateData[1].rows.push({
      id1: "",
      value1: "",
      id2: "",
      value2: "",
      id3: "",
      value3: "",
      id4: "",
      value4: "",
      id5: "",
      value5: "",
    });
    setUpdateData(updateData);
    initRow1(updateData[1].rows.slice(1));
  };

  const tableRowRemove1 = (index) => {
    updateData[1].rows.splice(index, 1);
    setUpdateData(updateData);
    initRow1(updateData[1].rows.slice(1));
  };

  const onValUpdate1 = (i, fieldName, fieldValue) => {
    const objId = i + 1;

    switch (fieldName) {
      case "var1":
        updateData[1].rows[i].id1 = "A-7-2-1-" + objId;
        updateData[1].rows[i].value1 = fieldValue;
        break;

      case "var2":
        updateData[1].rows[i].id2 = "A-7-2-2-" + objId;
        updateData[1].rows[i].value2 = fieldValue;
        break;

      case "var3":
        updateData[1].rows[i].id3 = "A-7-2-3-" + objId;
        updateData[1].rows[i].value3 = fieldValue;
        break;

      case "var4":
        updateData[1].rows[i].id4 = "A-7-2-4-" + objId;
        updateData[1].rows[i].value4 = fieldValue;
        break;

      case "var5":
        updateData[1].rows[i].id5 = "A-7-2-5-" + objId;
        updateData[1].rows[i].value5 = fieldValue;
        break;

      default:
        console.log("Default");
    }

    setUpdateData(updateData);
  };

  const updateArrayFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["values"]) {
        obj["values"].map((ele, index1) => {
          if (ele["values"]) {
            ele["values"].map((e, index2) => {
              if (e.id === id) {
                obj["values"][index1]["values"][index2].value = val;
              }
            });
          }
        });
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const updateTableFields = (id, val) => {
    const newData = updateData.map((obj) => {
      if (obj["rows"]) {
        obj["rows"].map((ele, index) => {
          if (ele.id1 === id) {
            obj["rows"][index].value1 = val;
          }
          else if (ele.id2 === id){
            obj["rows"][index].value2 = val;
          }
          else if (ele.id3 === id){
            obj["rows"][index].value3 = val;
          }
          else if (ele.id4 === id){
            obj["rows"][index].value4 = val;
          }
          else if (ele.id5 === id){
            obj["rows"][index].value5 = val;
          }
        });
      }
      return obj;
    });
    setUpdateData(newData);
  };

  const saveData = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/studio/brsr/save/sectionA`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reportName: reportName,
          data: updateData,
          section: "A-7"
        }),
      }).then((res) => {
        if (!res.ok) setError("Unable to save data");
      });
    } catch (e) {
      console.log(e.message);
      setError("Unable to create report");
    }
  };

  return (
    <main>
      <div>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "9rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography
                    component="h5"
                    variant="h5"
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginLeft={"1rem"}
                  >
                    {data.name}
                  </Typography>
                  <Stack direction="row">
                    <Switch defaultChecked size="small" />
                    <Typography
                      component="subtitle1"
                      variant="subtitle1"
                      fontWeight="600"
                      color={colors.grey[100]}
                      marginRight={"1rem"}
                    >
                      Edit
                    </Typography>
                  </Stack>
                </CardContent>
                <Divider />
                <CardContent
                  sx={{
                    width: "6rem",
                    display: "flex",
                    justifyContent: "left",
                  }}
                >
                  <div style={{ paddingLeft: "4rem" }}>
                    <Stack
                      direction={"row"}
                      sx={{ justifyContent: "space-around" }}
                    >
                      <div>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CheckCircleOutlineIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Approved
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <DataSaverOffIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Filled
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <BorderLinearProgress
                              variant="determinate"
                              value={50}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="medium"
                              color={colors.grey[100]}
                            >
                              50 %
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CalendarMonthIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Due Date
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              31st Jan 2024
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <CommentOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Comments
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              0 Comments
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <ListAltOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;Open Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                      <div style={{ paddingLeft: "6rem" }}>
                        <Stack direction={"column"}>
                          <Stack direction={"row"} marginBottom={"1rem"}>
                            <PersonOutlineOutlinedIcon
                              sx={{
                                color: colors.purple[100],
                                fontSize: "medium",
                              }}
                            />
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.grey[300]}
                            >
                              &nbsp;My Tasks
                            </Typography>
                          </Stack>
                          <Box sx={{ width: "5rem" }}>
                            <Typography
                              component="body2"
                              variant="body2"
                              fontWeight="bold"
                              color={colors.purple[200]}
                            >
                              0 Open Tasks
                            </Typography>
                          </Box>
                        </Stack>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginTop: "1rem",
                }}
              >
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[0].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[0].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[0].values[0].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[0].values[0].values[0].name}
                                </Typography>
                                <TextField
                                  id="A-7-A-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "2.3rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", paddingRight: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-7-A-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[0].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-7-A-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[0].values[2].value
                                  }
                                  size="small"
                                  sx={{
                                    width: "11rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.3rem",
                                  }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-7-A-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[0].values[3].value
                                  }
                                  size="small"
                                  sx={{
                                    width: "11rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.3rem",
                                  }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[0].values[4].name}
                                </Typography>
                                <TextField
                                  id="A-7-A-1-5"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[0].values[4].value
                                  }
                                  size="small"
                                  sx={{
                                    width: "11rem",
                                    marginLeft: "1rem",
                                    paddingTop: "3.5rem",
                                  }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[0].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "5.3rem 0 0 2.3rem" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-A-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[1].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-A-2-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[1].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[1].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-2-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[1].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[1].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-2-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[1].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[1].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-2-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[1].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[1].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-A-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[2].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-A-3-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[2].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[2].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-3-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[2].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[2].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-3-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[2].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[2].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-3-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[2].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[2].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-A-4-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[3].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-A-4-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[3].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[3].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-4-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[3].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[3].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-4-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[3].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[3].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-4-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[3].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[3].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-A-5-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[4].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-A-5-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[4].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[4].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-5-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[4].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[4].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-5-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[4].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[4].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-5-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[4].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[4].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-A-6-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[5].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-A-6-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[5].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[5].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-6-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[5].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[5].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-6-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[5].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[5].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-6-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[5].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[5].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-A-7-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[6].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-A-7-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[6].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[6].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-7-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[6].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[6].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-7-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[6].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[6].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-A-7-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[6].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[6].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {data.items[0].values[7].value}
                          </Typography>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[0].values[7].values[0].name}
                                </Typography>
                                <TextField
                                  id="A-7-B-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[7].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "2.3rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", paddingRight: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[7].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-7-B-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[7].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", marginLeft: "1rem" }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[7].values[1].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[7].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-7-B-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[7].values[2].value
                                  }
                                  size="small"
                                  sx={{
                                    width: "11rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.3rem",
                                  }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[7].values[2].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[7].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-7-B-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[7].values[3].value
                                  }
                                  size="small"
                                  sx={{
                                    width: "11rem",
                                    marginLeft: "1rem",
                                    paddingTop: "2.3rem",
                                  }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[7].values[3].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {data.items[0].values[7].values[4].name}
                                </Typography>
                                <TextField
                                  id="A-7-B-1-5"
                                  variant="outlined"
                                  defaultValue={
                                    updateData[0].values[7].values[4].value
                                  }
                                  size="small"
                                  sx={{
                                    width: "11rem",
                                    marginLeft: "1rem",
                                    paddingTop: "3.5rem",
                                  }}
                                  onChange={(e) => {
                                    updateArrayFields(
                                      data.items[0].values[7].values[4].id,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Box sx={{ padding: "5.3rem 0 0 2.3rem" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-B-2-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[8].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-B-2-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[8].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[8].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-2-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[8].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[8].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-2-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[8].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[8].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-2-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[8].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[8].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-B-3-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[9].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-B-3-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[9].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[9].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-3-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[9].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[9].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-3-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[9].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[9].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-3-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[9].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[9].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-B-4-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[10].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-B-4-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[10].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[10].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-4-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[10].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[10].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-4-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[10].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[10].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-4-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[10].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[10].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-B-5-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[11].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-B-5-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[11].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[11].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-5-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[11].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[11].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-5-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[11].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[11].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-5-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[11].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[11].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-B-6-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[12].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-B-6-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[12].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[12].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-6-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[12].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[12].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-6-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[12].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[12].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-6-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[12].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[12].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <TextField
                                id="A-7-B-7-1"
                                variant="outlined"
                                defaultValue={
                                  updateData[0].values[13].values[0].value
                                }
                                size="small"
                                sx={{ width: "12rem", paddingTop: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Box>
                            <TextField
                              id="A-7-B-7-2"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[13].values[1].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[13].values[1].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-7-3"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[13].values[2].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[13].values[2].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-7-4"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[13].values[3].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[13].values[3].id,
                                  e.target.value
                                );
                              }}
                            />
                            <TextField
                              id="A-7-B-7-5"
                              variant="outlined"
                              defaultValue={
                                updateData[0].values[13].values[4].value
                              }
                              size="small"
                              sx={{
                                width: "11rem",
                                marginLeft: "1rem",
                                paddingTop: "1rem",
                              }}
                              onChange={(e) => {
                                updateArrayFields(
                                  data.items[0].values[13].values[4].id,
                                  e.target.value
                                );
                              }}
                            />
                            <Box sx={{ padding: "1.7rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Stack direction={"column"}>
                      <div>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                          marginLeft={"1rem"}
                        >
                          {data.items[1].id}&nbsp;
                        </Typography>
                        <Typography
                          component="body1"
                          variant="body1"
                          fontWeight="bold"
                          color={colors.grey[100]}
                        >
                          {data.items[1].name}
                        </Typography>
                        <div
                          style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                        >
                          <Box sx={{ width: "60rem", paddingBottom: "1rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {data.items[1].value}
                            </Typography>
                          </Box>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "12rem" }}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {data.items[1].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="A-7-2-1-1"
                                  defaultValue={updateData[1].rows[0].value1}
                                  variant="outlined"
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "2.9rem" }}
                                  onChange={(e) => {
                                    updateTableFields(
                                      data.items[1].rows[0].id1,
                                      e.target.value
                                    );
                                  }}
                                />
                              </Stack>
                            </Box>
                            <Stack direction={"column"}>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {data.items[1].rows[0].name2}
                                  </Typography>
                                  <TextField
                                    id="A-7-2-2-1"
                                    defaultValue={updateData[1].rows[0].value2}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      paddingTop: "1.8rem",
                                    }}
                                    onChange={(e) => {
                                      updateTableFields(
                                        data.items[1].rows[0].id2,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                            <Stack direction={"column"}>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {data.items[1].rows[0].name3}
                                  </Typography>
                                  <TextField
                                    id="A-7-2-3-1"
                                    defaultValue={updateData[1].rows[0].value3}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      paddingTop: "1.8rem",
                                    }}
                                    onChange={(e) => {
                                      updateTableFields(
                                        data.items[1].rows[0].id3,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                            <Stack direction={"column"}>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {data.items[1].rows[0].name4}
                                  </Typography>
                                  <TextField
                                    id="A-7-2-4-1"
                                    defaultValue={updateData[1].rows[0].value4}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      paddingTop: "1.7rem",
                                    }}
                                    onChange={(e) => {
                                      updateTableFields(
                                        data.items[1].rows[0].id4,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                            <Stack direction={"column"}>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {data.items[1].rows[0].name5}
                                  </Typography>
                                  <TextField
                                    id="A-7-2-5-1"
                                    defaultValue={updateData[1].rows[0].value5}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      paddingTop: "0.5rem",
                                    }}
                                    onChange={(e) => {
                                      updateTableFields(
                                        data.items[1].rows[0].id5,
                                        e.target.value
                                      );
                                    }}
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                            <Box sx={{ padding: "4rem 0 0 20px" }}>
                              <CommentOutlinedIcon
                                sx={{
                                  color: colors.grey[300],
                                  fontSize: "bold",
                                }}
                              />
                            </Box>
                          </Stack>
                          <TableRows1
                            rows1={rows1}
                            tableRowRemove1={tableRowRemove1}
                            onValUpdate1={onValUpdate1}
                          />
                          <div style={{ padding: "0.5rem 0 0 0" }}>
                            <Button
                              variant="outlined"
                              size="medium"
                              onClick={addRowTable1}
                            >
                              Add New Row
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Stack>
                  </div>
                </CardContent>
                <CardContent
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ padding: "0 0 0 1rem" }}>
                    <Button variant="contained" size="large" onClick={saveData}>
                      Save
                    </Button>
                  </div>
                  <Box sx={{ padding: "1rem 5rem 1rem 0" }}>
                    <Stack direction="row">
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                      >
                        Assignee
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                      <Typography
                        component="subtitle2"
                        variant="subtitle2"
                        fontWeight="200"
                        color={colors.grey[100]}
                        padding={"0 0 0 1rem"}
                      >
                        Approver
                      </Typography>
                      <AccountCircleIcon
                        sx={{
                          color: colors.purple[100],
                          fontSize: "bold",
                        }}
                      />
                    </Stack>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default SectionA7;
