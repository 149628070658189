import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import { useTheme, Box, Typography, IconButton, Stack } from "@mui/material";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AutoGraphIcon from "@mui/icons-material/AutoGraph";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import SignalCellularAltOutlinedIcon from "@mui/icons-material/SignalCellularAltOutlined";
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import logo from "../../assets/icons/aiou_logo.png";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

const MainSidebar = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState(props.data);
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();

  console.log(selected);

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          color: `${colors.grey[300]} !important`,
          backgroundColor: "transparent !important",
        },
        // "& .menu-icon:hover": {
        //   color: `${colors.purple[100]} !important`,
        //   backgroundColor: "transparent !important",
        // },
        "& .menu-item": {
          color: `${colors.grey[300]} !important`,
          backgroundColor: "transpare`ˀnt !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "transparent !important",
        },
        // "& .menu-item:hover": {
        //   color: `${colors.purple[100]} !important`,
        //   backgroundColor: "transparent !important",
        // },
        "& .menu-item.active": {
          color: `${colors.purple[100]} !important`,
          backgroundColor: "transparent !important",
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.white[300]}
        image={sidebarImage}
        width="13rem"
      >
        <Box>
          <Menu iconshape="square">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop={"3rem"}
              marginBottom={"2rem"}
            >
              <Typography
                variant="h3"
                color={colors.grey[300]}
                paddingLeft={"3rem"}
              >
                AQUARIUS
              </Typography>
            </Box>

            <Box sx={{ marginLeft: "1rem" }}>
              <Item
                title="Dashboard"
                to="/dashboard"
                icon={<SignalCellularAltOutlinedIcon sx={{color:selected==="Dashboard" ? colors.purple[100]:colors.grey[300]}}/>}
                selected={selected}
                setSelected={setSelected}
                ß
              />
              <Item
                title="Strategy"
                to="/strategy"
                icon={<AutoGraphIcon sx={{color:selected==="Strategy" ? colors.purple[100]:colors.grey[300]}}/>}
                ß
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Reporting Studio"
                to="/studio"
                icon={<AccountTreeOutlinedIcon sx={{color:selected==="Reporting Studio" ? colors.purple[100]:colors.grey[300]}}/>}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Solutions"
                to="/products"
                icon={<CategoryOutlinedIcon sx={{color:selected==="Solutions" ? colors.purple[100]:colors.grey[300]}}/>}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="FAQ Page"
                to="/faq"
                icon={<HelpOutlineOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              /> */}
            </Box>
          </Menu>
        </Box>
        {!collapsed && (
          <Stack direction={"column"}>
            <Typography
              variant="body2"
              color={colors.grey[300]}
              padding={"18rem 0 0 5rem"}
            >
              Powered by
            </Typography>
            <Box
              component="img"
              sx={{ height: "3rem", width: "11rem" }}
              alt="Logo"
              src={logo}
              paddingLeft={"2rem"}
              paddingTop={"1rem"}
            />
          </Stack>
        )}
      </Sidebar>
    </Box>
  );
};

export default MainSidebar;
