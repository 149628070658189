import {
  Box,
  Typography,
  useTheme,
  Container,
  Card
} from "@mui/material";
import { Grid, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { tokens } from "../../theme";
import SignUpForm from "./SignUpForm";

// Only for invites
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../config/firebase";

const SignupPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [invitedIds, setInvitedIds] = useState([]);


  const getInvitedEmail = async () => {
    const invitedArray = [];
    try {
    await getDocs(collection(db, "invites")).then((querySnapshot) => {

      querySnapshot.docs.map((doc) => {
        invitedArray.push(doc.id);
      });
      setInvitedIds(invitedArray);

    });
  } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getInvitedEmail();
  }, []);

  return (
    <main>
    <div style={{ backgroundColor: colors.purple[200] }}>
      <Container
        maxWidth="ls"
      >
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ minHeight: '100vh' }}
        >
          <Grid>
          <Card
              sx={{
                height: "100%",
                width: "30rem",
                display: "flex",
                flexDirection: "column",
                borderRadius: "10px",
                border: colors.purple[200],
                paddingBottom: "2rem"
              }}
            > 
           {<SignUpForm invitedIds={invitedIds}/>}
            </Card>
            <Box padding={"2rem 0 0 0"} justifyContent={"center"}>
            <Typography
              onClick={() => {
                navigate('/');
              }}
              variant="body1"
              fontWeight={"bold"}
            textAlign={"center"}
              sx={{
                textDecoration: "underline",
                color: colors.white[100],
              }}
            >
              {"already have an account? login here."}
            </Typography>
          </Box>
          </Grid>
        </Grid>
      </Container>
    </div>
  </main>
    // <div style={{backgroundColor:colors.purple[100]}}>
    // <Grid container spacing={2}>
    //   <Grid container item xs={7} direction="row" alignItems={"center"}>
    //     <Box>
    //       <Typography
    //         fontWeight="500"
    //         variant="h1"
    //         sx={{ mb: "1.5rem", color: colors.grey[200] }}
    //       >
    //         Welcome to AIOU!
    //       </Typography>
    //     </Box>
    //   </Grid>
    //   <Grid container item xs={5} direction="column" align="center">
    //     <Paper
    //       elevation={5}
    //       style={{ minHeight: "100vh", borderRadius: 0, flex: 1 }}
    //     >
    //       <Box p="3rem" borderRadius="1.5rem" alignContent={"space-between"}>
    //         {<SignUpForm invitedIds={invitedIds}/>}
    //         <Box padding={"2rem 0 0 0"}>
    //           <Typography
    //             onClick={() => {
    //               navigate('/');
    //             }}
    //             sx={{
    //               textDecoration: "underline",
    //               color: colors.grey[100],
    //               "&:hover": {
    //                 cursor: "pointer",
    //                 color: colors.purple[100],
    //               },
    //             }}
    //           >
    //             {"Already have an account? Login here."}
    //           </Typography>
    //         </Box>
    //       </Box>
    //     </Paper>
    //   </Grid>
    // </Grid>
    // </div>
  );
};

export default SignupPage;
