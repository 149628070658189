import { useNavigate } from "react-router-dom";
import { tokens } from "../../theme";
import { Box, Typography, useTheme, Button, Grid, Paper } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const UserManagePage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [allMembers, setAllMembers] = useState([]);
  const token = useSelector((state) => state.token);

  const getAllMembers = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/users/allmembers`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      }).then((res) => {
        if(!res.ok) setError(res.message); 
        else return res.json();
      }).then((data) => {
        setAllMembers(data)
      }).catch((err) => {
        console.log('Error :' + err);
        setError("Internal Server Error");  
      });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  useEffect(() => {
    getAllMembers();
  },[]);

  return (
    <>
      <Grid direction="row" alignItems={"right"} alignContent={"space-evenly"}>
        <Box padding={"2rem 3rem 0 3rem"}>
          <Button
            variant="contained"
            size="large"
            sx={{
              color: colors.purple[100],
            }}
            onClick={() => {
              navigate("/admin/usermanage/adduser");
            }}
          >
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{
                color: colors.white[200],
              }}
            >
              Add User
            </Typography>
          </Button>
          <Button
            variant="contained"
            size="large"
            sx={{
              color: colors.purple[100],
            }}
            onClick={() => {
              navigate("/admin/usermanage/addcompany");
            }}
          >
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{
                color: colors.white[200],
              }}
            >
              Add Company
            </Typography>
          </Button>
        </Box>
      </Grid>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell align="right">Email</TableCell>
              <TableCell align="right">Designation</TableCell>
              <TableCell align="right">Company</TableCell>
              <TableCell align="right">License</TableCell>
              <TableCell align="right">Type</TableCell>
              <TableCell align="right">Active</TableCell>
              <TableCell align="right">Created Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allMembers.length !== 0 &&
              allMembers.map((row) => (
                <TableRow key={row._id}>
                  <TableCell component="th" scope="row">
                    {row.firstName + " " + row.lastName}
                  </TableCell>
                  <TableCell align="right">{row.email}</TableCell>
                  <TableCell align="right">{row.designation}</TableCell>
                  <TableCell align="right">{row.companyName}</TableCell>
                  <TableCell align="right">{row.license}</TableCell>
                  <TableCell align="right">{row.usertype}</TableCell>
                  <TableCell align="right">{row.active}</TableCell>
                  <TableCell align="right">{row.createdAt}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UserManagePage;
