import { createContext, useContext, useState, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { useDispatch } from "react-redux";
import { auth } from "../config/firebase";
import { setLogin, setLogout } from "state";

const token = "";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  const createUser = async (email, password) => {
    await createUserWithEmailAndPassword(auth, email, password).then(() =>
      auth.currentUser
        .getIdToken()
        .then((token) =>
          dispatch(setLogin({ fid: auth.currentUser.uid, token: token }))
        )
    );
  };

  const signIn = async (email, password) => {
    try {
      await signInWithEmailAndPassword(auth, email, password).then(async () => {
        await auth.currentUser.getIdToken().then(async (token) => {
          await fetch(process.env.REACT_APP_SERVER_URL+`/auth/login/${auth.currentUser.uid}`, {
            method: "GET",
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          })
            .then((res) => {
              console.log(res);
              if (!res.ok) setError(res.message);
              else return res.json();
            })
            .then((data) => {
              dispatch(
                setLogin({
                  fid: data.fid,
                  userid: data.userid,
                  firstname: data.firstname,
                  lastname: data.lastname,
                  designation: data.designation,
                  usertype: data.usertype,
                  companyid: data.companyid,
                  companyname: data.companyname,
                  token: token,
                })
              );
            });
        });
      });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  const logout = async () => {
    return signOut(auth).then(() => dispatch(setLogout()));
  };

  useEffect(() => {}, []);

  return (
    <UserContext.Provider value={{ createUser, token, signIn, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
