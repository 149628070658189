import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Topbar from "scenes/global/Topbar";
import { setTopbar } from "state";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SectionB1 from "./SectionB_1.jsx";
import SectionB2 from "./SectionB_2.jsx";
import SectionB3 from "./SectionB_3.jsx";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

const SectionBPage = () => {
  console.log("Inside Section B page");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { state } = useLocation();
  const { data } = state;
  const reportName = useSelector((state) => state.reportName);
  dispatch(
    setTopbar({
      pageName: "Section B",
      breadcrumbs: ["Reporting Studio", reportName, "Section B"],
    })
  );

  const blocks = [
  <SectionB1 data={data['section'][0]}/>, 
  <SectionB2 data={data['section'][1]}/>, 
  <SectionB3 data={data['section'][2]}/>];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < blocks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <main>
      <div>
        <Topbar />
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 0
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 0
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"2.1rem"}
                    >
                      1 Policy and management processes
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 0
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 1
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 1
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"2.1rem"}
                    >
                      2 Governance, leadership and oversight
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={colors.grey[100]}
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 2
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 2
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"1rem"}
                    >
                      3 Details of review of NGBRCs by the company
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={colors.grey[100]}
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ marginTop: "1rem", marginLeft: "2rem" }}>
        <Box sx={{ display: "flex", justifyContent: "left" }}>
          <ArrowBackIosIcon
            style={{ color: colors.purple[200], fontSize: "15px" }}
            onClick={handlePrevClick}
          />
          <ArrowForwardIosIcon
            style={{ color: colors.purple[200], fontSize: "15px" }}
            onClick={handleNextClick}
          />
        </Box>
      </div>
      {blocks[currentIndex]}
    </main>
  );
};

export default SectionBPage;
