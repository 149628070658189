import Topbar from "scenes/global/Topbar";
import {
  useTheme,
  Grid,
  Container,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTopbar } from "state";
import { useDispatch } from "react-redux";
import { tokens } from "../../theme";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { mockBarData as data } from "data/mockData";
import { mockPieData as piedata } from "data/mockData";
import { mockLineData as linedata } from "data/mockData";

const EDashboardPage = () => {
  console.log("Inside Edashboard page");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const companyid = useSelector((state) => state.companyid);
  const token = useSelector((state) => state.token);
  const { state } = useLocation();
  const { Eval } = state;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(
    setTopbar({
      pageName: "Environment",
      breadcrumbs: ["Dashboard", "Environment"],
    })
  );

  return (
    <main>
      <div>
        <Topbar />
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Stack direction={"row"}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "16rem",
                  width: "34rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Energy Consumption(kWH)
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="27vh">
                    <ResponsiveBar
                      data={data}
                      keys={["value"]}
                      indexBy="period"
                      margin={{
                        top: 5,
                        right: 0,
                        bottom: 40,
                        left: 50,
                      }}
                      padding={0.6}
                      groupMode="grouped"
                      colors={colors.green[100]}
                      axisTop={null}
                      axisRight={null}
                      enableGridX={false}
                      enableGridY={true}
                      enableLabel={false}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "16rem",
                  width: "34rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Waste Collected & Recycled
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="30vh">
                    <ResponsivePie
                      data={piedata}
                      margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
                      innerRadius={0.5}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={colors.white[100]}
                      colors={{ scheme: "set2" }}
                      arcLinkLabel="label"
                      arcLinkLabelsTextColor={colors.grey[100]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Stack>
        </Container>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Card
              sx={{
                height: "18rem",
                width: "70rem",
                display: "flex",
                flexDirection: "column",
                mr: "1rem",
                borderRadius: "10px",
                border: colors.purple[200],
              }}
            >
              <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  component="h4"
                  variant="h4"
                  fontWeight="bold"
                  color={colors.grey[300]}
                >
                  CO2 Emmision(kg)
                </Typography>
              </CardContent>
              <CardContent>
                <Box height="30vh">
                  <ResponsiveLine
                    data={linedata}
                    margin={{ top: 10, right: 50, bottom: 50, left: 60 }}
                    xScale={{ type: "point" }}
                    yScale={{
                      type: "linear",
                      min: "auto",
                      max: "auto",
                      stacked: true,
                      reverse: false,
                    }}
                    enableGridX={false}
                    colors={colors.blue[200]}
                    pointSize={10}
                    pointColor={{ theme: "background" }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: "serieColor" }}
                    pointLabelYOffset={-12}
                    useMesh={true}
                  />
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </div>
    </main>
  );
};

export default EDashboardPage;
