import Topbar from "scenes/global/Topbar";
import {
  useTheme,
  Grid,
  Container,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTopbar } from "state";
import { useDispatch } from "react-redux";
import { tokens } from "../../theme";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { mockBar2Data as data } from "data/mockData";
import { mockBar3Data as bar3data } from "data/mockData";
import { mockPie2Data as piedata } from "data/mockData";
import { mockLine2Data as linedata } from "data/mockData";


const SDashboardPage = () => {
  console.log("Inside SDashboard page");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const companyid = useSelector((state) => state.companyid);
  const token = useSelector((state) => state.token);
  const { state } = useLocation();
  const { Sval } = state;
  const navigate = useNavigate();
  const dispatch = useDispatch();


  dispatch(
    setTopbar({
      pageName: "Social",
      breadcrumbs: ["Dashboard", "Social"],
    })
  );

  return (
    <main>
      <div>
        <Topbar />
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Stack direction={"row"}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "16rem",
                  width: "34rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Gender Diversity
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="27vh">
                    <ResponsiveBar
                      data={data}
                      keys={["Male","Female"]}
                      indexBy="period"
                      margin={{
                        top: 5,
                        right: 90,
                        bottom: 40,
                        left: 50,
                      }}
                      groupMode={"stacked"}
                      padding={0.6}
                      colors={[colors.green[100],colors.yellow[100]]}
                      axisTop={null}
                      axisRight={null}
                      enableGridX={false}
                      enableGridY={true}
                      enableLabel={false}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                      }}
                      legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 0,
                            itemsSpacing: 2,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1
                                    }
                                }
                            ]
                        }
                    ]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "16rem",
                  width: "34rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Differently Abled
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="30vh">
                    <ResponsiveLine
                      data={linedata}
                      margin={{ top: 10, right: 80, bottom: 70, left: 60 }}
                      xScale={{ type: "point" }}
                      axisTop={null}
                      axisRight={null}
                      colors={[colors.green[100], colors.yellow[100]]}
                      pointSize={10}
                      pointColor={{ theme: "background" }}
                      pointBorderWidth={2}
                      pointBorderColor={{ from: "serieColor" }}
                      pointLabelYOffset={-12}
                      useMesh={true}
                      animate={true}
                      legends={[
                        {
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 100,
                          translateY: 0,
                          itemsSpacing: 0,
                          itemDirection: "left-to-right",
                          itemWidth: 80,
                          itemHeight: 20,
                          itemOpacity: 0.75,
                          symbolSize: 12,
                          symbolShape: "circle",
                          symbolBorderColor: "rgba(0, 0, 0, .5)",
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemBackground: "rgba(0, 0, 0, .03)",
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Stack>
        </Container>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Stack direction={"row"}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "16rem",
                  width: "34rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Pay Parity(Hourly Average)
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="27vh">
                    <ResponsiveBar
                      data={bar3data}
                      keys={["value1", "value2"]}
                      indexBy="period"
                      margin={{
                        top: 5,
                        right: 0,
                        bottom: 40,
                        left: 50,
                      }}
                      padding={0.6}
                      groupMode="stacked"
                      colors={[colors.green[100],colors.yellow[100]]}
                      axisTop={null}
                      axisRight={null}
                      enableGridX={false}
                      enableGridY={true}
                      enableLabel={false}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                      }}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "16rem",
                  width: "34rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Gender Ratio(Leadership)
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="30vh">
                    <ResponsivePie
                      data={piedata}
                      margin={{ top: 10, right: 0, bottom: 60, left: 0 }}
                      innerRadius={0}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      borderWidth={1}
                      borderColor={colors.white[100]}
                      colors={[colors.green[100], colors.yellow[100]]}
                      arcLinkLabel="label"
                      arcLinkLabelsTextColor={colors.grey[100]}
                      animate={true}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Stack>
        </Container>
      </div>
    </main>
  );
};

export default SDashboardPage;
