import { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import Topbar from "./scenes/global/Topbar";
import { MainSidebarProvider } from "scenes/global/sidebarContext";
import ProductPage from "./scenes/solutions";
import { CssBaseline, Divider, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import LoginPage from "scenes/login";
import SignupPage from "scenes/signup";
import ProductViewer from "scenes/solutions/ProductViewer";
import { AuthContextProvider } from "context/AuthContext";
import ProtectedRoute from "components/ProtectedRoute";
import UserManagePage from "scenes/usermanage";
import UserAdd from "scenes/usermanage/UserAdd";
import CompanyAddForm from "scenes/usermanage/CompanyAddForm";
import ProductMembers from "scenes/solutions/ProductMembers";
import ProductAddMembers from "scenes/solutions/ProductAddMembers";
import ProductRemoveMembers from "scenes/solutions/ProductRemoveMembers";
import DashboardPage from "scenes/dashboard";
import StudioPage from "scenes/studio";
import BRSRPage from "scenes/studio/BRSR/BRSRTemplate.jsx";
import SectionAPage from "scenes/studio/BRSR/SectionA";
import SectionBPage from "scenes/studio/BRSR/SectionB";
import SectionCPage from "scenes/studio/BRSR/SectionC";
import ReportGenerationPage from "scenes/studio/BRSR/ReportGeneration";
import FileUpload from "scenes/studio/BRSR/FileUpload";
import HomePage from "scenes/global/HomePage";
import EDashboardPage from "scenes/dashboard/Edashboard";
import SDashboardPage from "scenes/dashboard/Sdashboard";
import StrategyPage from "scenes/strategy";
import GDashboardPage from "scenes/dashboard/Gdashboard";

const App = () => {
  console.log("In App");
  const [theme, colorMode] = useMode();

  const mode = useSelector((state) => state.mode);
  const [isSidebar, setIsSidebar] = useState(true);
  const isAuth = Boolean(useSelector((state) => state.token));

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthContextProvider>
          {/* {isAuth && <MainSidebarProvider isSidebar={isSidebar} />} */}
          
            <div style={{ height: "100%", width: "100%" }}>
              <main>

                <Routes>
                  <Route path="/" element={<LoginPage />} />
                  <Route path="/signup" element={<SignupPage />} />
                  <Route path="/home" element={<ProtectedRoute><HomePage /></ProtectedRoute>} />
                  <Route path="/admin/usermanage" element={<MainSidebarProvider><ProtectedRoute><UserManagePage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/admin/usermanage/adduser" element={<MainSidebarProvider><ProtectedRoute><UserAdd /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/admin/usermanage/addcompany" element={<MainSidebarProvider><ProtectedRoute><CompanyAddForm /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/dashboard" element={<MainSidebarProvider data={"Dashboard"}><ProtectedRoute><DashboardPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/dashboard/environment" element={<MainSidebarProvider><ProtectedRoute><EDashboardPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/dashboard/social" element={<MainSidebarProvider><ProtectedRoute><SDashboardPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/dashboard/governance" element={<MainSidebarProvider><ProtectedRoute><GDashboardPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/studio" element={<MainSidebarProvider data={"Reporting Studio"}><ProtectedRoute><StudioPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/studio/brsr" element={<MainSidebarProvider><ProtectedRoute><BRSRPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/studio/brsr/sectionA" element={<MainSidebarProvider><ProtectedRoute><SectionAPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/studio/brsr/sectionB" element={<MainSidebarProvider><ProtectedRoute><SectionBPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/studio/brsr/sectionC" element={<MainSidebarProvider><ProtectedRoute><SectionCPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/studio/brsr/reportGen" element={<MainSidebarProvider><ProtectedRoute><ReportGenerationPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/studio/brsr/fileupload" element={<ProtectedRoute><FileUpload /></ProtectedRoute>} />
                  <Route path="/strategy" element={<MainSidebarProvider data={"Strategy"}><ProtectedRoute><StrategyPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/products" element={<MainSidebarProvider><ProtectedRoute><ProductPage /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/products/:name/:pid/:companyid/members" element={<MainSidebarProvider><ProtectedRoute><ProductMembers /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/products/:name/:pid/:companyid/addmembers" element={<MainSidebarProvider><ProtectedRoute><ProductAddMembers /></ProtectedRoute></MainSidebarProvider>} />
                  <Route path="/products/:name/:pid/:companyid/removemembers" element={<ProtectedRoute><ProductRemoveMembers /></ProtectedRoute>} />
                  <Route path="/productview" element={<MainSidebarProvider><ProtectedRoute><ProductViewer /></ProtectedRoute></MainSidebarProvider>} />
                </Routes>
              </main>
            </div>          
        </AuthContextProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
