import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Checkbox,
  Grid,
  FormControlLabel,
  Stack
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";
import { tokens } from "../../theme";
import { UserAuth } from "context/AuthContext";
import logo from "../../assets/icons/aiou_logo.png";

const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState("");
  const { signIn } = UserAuth();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await signIn(email, password);

      navigate("/home");
    } catch (e) {
      
      setError(e.message);
    }
  };

  return (
    <Grid align="center" paddingTop={"2rem"}>
      <Formik>
        {() => (
          <form onSubmit={handleFormSubmit}>
            <Box component="img" sx={{ height: 54 }} alt="Logo" src={logo} />
            <Typography
              variant="h4"
              textAlign={"center"}
              sx={{
                color: colors.grey[300],
              }}
            >
              Empowering a Sustainable Universe
            </Typography>
            <Box
              display="grid"
              gap="30px"
              padding={"3rem 3rem 0 3rem"}
              gridTemplateColumns="repeat(2, minmax(0, 1fr))"
            >
              <TextField
                fullWidth
                label="Email"
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                sx={{ gridColumn: "span 4"}}
                InputProps={{ sx: { borderRadius: "2rem" } }}
              />
              <TextField
                fullWidth
                label="Password"
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                sx={{ gridColumn: "span 4" }}
                InputProps={{ sx: { borderRadius: "2rem" } }}
              />
            </Box>
            <Box padding={"1rem 0 0 3rem"} align="left">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={true}
                    // onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      color: colors.purple[100],
                    }}
                  />
                }
                label="Remember Me"
              />
            </Box>
            <Box padding={"1rem 4rem 0 4rem"} >
              <Stack direction={"row"} justifyContent={"space-around"}>
              <Button
                type="submit"
                variant="contained"
                size="large"
                sx={{
                  color: colors.purple[100],
                  width: "10rem",
                  borderRadius: "4rem"

                }}
              >
                <Typography
                  variant="h4"
                  textAlign={"center"}
                  sx={{
                    color: colors.white[200],
                  }}
                >
                  Login
                </Typography>
              </Button>
              <Box padding={"0.5rem 0 0 0"}>
                <Typography
                  variant="h5"
                  textAlign={"center"}
                  sx={{
                    color: colors.grey[300],
                  }}
                >
                  forgot password?
                </Typography>
                </Box>
              </Stack>
            </Box>
          </form>
        )}
      </Formik>
    </Grid>
  );
};

export default LoginForm;
