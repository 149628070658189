import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
} from "@mui/material";
import { tokens } from "../../../theme";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import Topbar from "scenes/global/Topbar";
import { setTopbar } from "state";
import { useDispatch, useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import SectionC1 from "./SectionC_1.jsx";
import SectionC2 from "./SectionC_2.jsx";
import SectionC3 from "./SectionC_3.jsx";
import SectionC4 from "./SectionC_4.jsx";
import SectionC5 from "./SectionC_5.jsx";
import SectionC6 from "./SectionC_6.jsx";
import SectionC7 from "./SectionC_7.jsx";
import SectionC8 from "./SectionC_8.jsx";
import SectionC9 from "./SectionC_9.jsx";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 3,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#9147d8" : "#308fe8",
  },
}));

const SectionCPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { data } = state;

  const reportName = useSelector((state) => state.reportName);
  dispatch(
    setTopbar({
      pageName: "Section C",
      breadcrumbs: ["Reporting Studio", reportName, "Section C"],
    })
  );

  const blocks = [
    <SectionC1 data={data['section'][0]}/>,
    <SectionC2 data={data['section'][1]}/>,
    <SectionC3 data={data['section'][2]}/>,
    <SectionC4 data={data['section'][3]}/>,
    <SectionC5 data={data['section'][4]}/>,
    <SectionC6 data={data['section'][5]}/>,
    <SectionC7 data={data['section'][6]}/>,
    <SectionC8 data={data['section'][7]}/>,
    <SectionC9 data={data['section'][8]}/>,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleNextClick = () => {
    if (currentIndex < blocks.length - 1) {
      setCurrentIndex(currentIndex + 1);
    }
  };

  return (
    <main>
      <div>
        <Topbar />
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            alignItems={"center"}
            justifyContent={"left"}
          >
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 0
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 0
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 1
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 0
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 1
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 1
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 2
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 1
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 2
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 2
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 3
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 2
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 3
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 3
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 4
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 3
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 4
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 4
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 5
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 4
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 5
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 5
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 6
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 5
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 6
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 6
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 7
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 6
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "0.5rem",
                  borderRadius: "10px",
                  border:
                    currentIndex === 7
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 7
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 8
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 7
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
            <Grid>
              <Card
                sx={{
                  height: "8rem",
                  width: "7rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border:
                    currentIndex === 8
                      ? `1px solid ${colors.purple[200]}`
                      : "0px",
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "left" }}>
                  <Stack direction="column">
                    <Typography
                      component="body2"
                      variant="body2"
                      fontWeight="100"
                      color={
                        currentIndex === 8
                          ? colors.purple[200]
                          : colors.grey[100]
                      }
                      paddingBottom={"4.1rem"}
                    >
                      Principle 9
                    </Typography>
                    <Box sx={{ width: "5rem" }}>
                      <BorderLinearProgress variant="determinate" value={50} />
                      <Typography
                        component="body2"
                        variant="body2"
                        fontWeight="medium"
                        color={
                          currentIndex === 8
                            ? colors.purple[200]
                            : colors.grey[100]
                        }
                      >
                        50 %
                      </Typography>
                    </Box>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div style={{ marginTop: "1rem", marginLeft: "2rem" }}>
        <Box sx={{ display: "flex", justifyContent: "left" }}>
          <ArrowBackIosIcon
            style={{ color: colors.purple[200], fontSize: "15px" }}
            onClick={handlePrevClick}
          />
          <ArrowForwardIosIcon
            style={{ color: colors.purple[200], fontSize: "15px" }}
            onClick={handleNextClick}
          />
        </Box>
      </div>
      {blocks[currentIndex]}
    </main>
  );
};

export default SectionCPage;
