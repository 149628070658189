import Topbar from "scenes/global/Topbar";
import {
  useTheme,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Box,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTopbar } from "state";
import { useDispatch } from "react-redux";
import { tokens } from "../../theme";
import { RadialBar } from "@nivo/radial-bar";
import { ResponsiveBar } from "@nivo/bar";


const DashboardPage = () => {
  console.log("Inside insights page");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const companyid = useSelector((state) => state.companyid);
  const token = useSelector((state) => state.token);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [dashboardData, setDashboardData] = useState([]);
  const [error, setError] = useState("");
  const [ERadialval, setERadialval] = useState(0);
  const [SRadialval, setSRadialval] = useState(0);
  const [GRadialval, setGRadialval] = useState(0);
  const [SDGval, setSDGval] = useState([]);
  const [Eval, setEval] = useState({});
  const [Sval, setSval] = useState({});
  const [Gval, setGval] = useState({});

  dispatch(setTopbar({ pageName: "Dashboard", breadcrumbs: ["Dashboard"] }));

  const getDashboardData = async () => {
    try {
      await fetch(process.env.REACT_APP_SERVER_URL+`/dashboard/${companyid}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      })
        .then((res) => {
          if (!res.ok) setError(res.message);
          else return res.json();
        })
        .then((data) => {
          setDashboardData(data);
        })
        .catch((err) => {
          console.log("Error :" + err);
          setError("Internal Server Error");
        });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  const EMetric = ({ center }) => {
    return (
      <text
        x={center[0]}
        y={center[0]}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 25,
          fill: colors.grey[300],
        }}
      >
        {ERadialval}
      </text>
    );
  };

  const SMetric = ({ center }) => {
    return (
      <text
        x={center[0]}
        y={center[0]}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 25,
          fill: colors.grey[300],
        }}
      >
        {SRadialval}
      </text>
    );
  };

  const GMetric = ({ center }) => {
    return (
      <text
        x={center[0]}
        y={center[0]}
        textAnchor="middle"
        dominantBaseline="central"
        style={{
          fontSize: 25,
          fill: colors.grey[300],
        }}
      >
        {GRadialval}
      </text>
    );
  };

  useEffect(() => {
    getDashboardData();
    if (dashboardData.length !== 0) {

      setERadialval(dashboardData[0].environment.value);
      setSRadialval(dashboardData[0].social.value);
      setGRadialval(dashboardData[0].governance.value);
      setSDGval(dashboardData[0].sdg);
      setEval(dashboardData[0].environment);
      setSval(dashboardData[0].social);
      setGval(dashboardData[0].governance);
    }
  }, [dashboardData.length]);

  return (
    <main>
      <Topbar />
      {dashboardData.length !== 0 && (
        <div>
          <Container
            maxWidth="ls"
            style={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Grid>
              <Box
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard/environment",  {state: {Eval}});
                  }}
                >
                <Card
                  sx={{
                    height: "13rem",
                    width: "22.5rem",
                    display: "flex",
                    flexDirection: "column",
                    mr: "1.5rem",
                    borderRadius: "10px",
                    border: colors.purple[200],
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <RadialBar
                      width={120}
                      height={120}
                      maxValue={100}
                      startAngle={0}
                      endAngle={360}
                      cornerRadius={100}
                      innerRadius={0.6}
                      tracksColor={colors.green[200]}
                      colors={[colors.green[100]]}
                      data={[
                        {
                          id: "default",
                          data: [{ x: "percentage", y: ERadialval }],
                        },
                      ]}
                      layers={["tracks", "bars", EMetric]}
                    />
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      fontWeight="bold"
                      color={colors.grey[300]}
                    >
                      Environment
                    </Typography>
                  </CardContent>
                </Card>
                </Box>
              </Grid>
              <Grid>
                <Box
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard/social",  {state: {Sval}});
                  }}
                >
                  <Card
                    sx={{
                      height: "13rem",
                      width: "22.5rem",
                      display: "flex",
                      flexDirection: "column",
                      mr: "1.5rem",
                      borderRadius: "10px",
                      border: colors.purple[200],
                    }}
                  >
                    <CardContent
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <RadialBar
                        width={120}
                        height={120}
                        maxValue={100}
                        startAngle={0}
                        endAngle={360}
                        cornerRadius={100}
                        innerRadius={0.6}
                        tracksColor={colors.yellow[200]}
                        colors={[colors.yellow[100]]}
                        data={[
                          {
                            id: "default",
                            data: [{ x: "percentage", y: SRadialval }],
                          },
                        ]}
                        layers={["tracks", "bars", SMetric]}
                      />
                    </CardContent>
                    <CardContent
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <Typography
                        component="h4"
                        variant="h4"
                        fontWeight="bold"
                        color={colors.grey[300]}
                      >
                        Social
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
              <Grid>
              <Box
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/dashboard/governance",  {state: {Eval}});
                  }}
                >
                <Card
                  sx={{
                    height: "13rem",
                    width: "22rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                    border: colors.purple[200],
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <RadialBar
                      width={120}
                      height={120}
                      maxValue={100}
                      startAngle={0}
                      endAngle={360}
                      cornerRadius={100}
                      innerRadius={0.6}
                      tracksColor={colors.blue[200]}
                      colors={[colors.blue[100]]}
                      data={[
                        {
                          id: "default",
                          data: [{ x: "percentage", y: GRadialval }],
                        },
                      ]}
                      layers={["tracks", "bars", GMetric]}
                    />
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      fontWeight="bold"
                      color={colors.grey[300]}
                    >
                      Governance
                    </Typography>
                  </CardContent>
                </Card>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
              paddingTop={"2rem"}
            >
              <Grid>
                <Card
                  sx={{
                    height: "20rem",
                    width: "70rem",
                    display: "flex",
                    flexDirection: "column",
                    mr: "2rem",
                    borderRadius: "10px",
                    border: colors.purple[200],
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      fontWeight="bold"
                      color={colors.grey[300]}
                    >
                      Goal-wise Performance
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Box height="30vh">
                      <ResponsiveBar
                        data={SDGval}
                        keys={["value"]}
                        indexBy="name"
                        margin={{
                          top: 20,
                          right: 0,
                          bottom: 40,
                          left: 50,
                        }}
                        padding={0.6}
                        groupMode="grouped"
                        colors={colors.purple[200]}
                        axisTop={null}
                        axisRight={null}
                        enableGridX={false}
                        enableGridY={true}
                        enableLabel={false}
                        axisBottom={{
                          tickSize: 0,
                          tickPadding: 10,
                          tickRotation: 0,
                        }}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
      )}
    </main>
  );
};

export default DashboardPage;
