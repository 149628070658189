import {
  useTheme,
  Box,
  Grid,
  Container,
  Card,
  CardContent,
  Typography,
  Stack,
  TextField,
  Button,
} from "@mui/material";
import { tokens } from "../../../theme";
import Topbar from "scenes/global/Topbar";
import { setTopbar } from "state";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import SectionA1 from "./SectionA_1";
import SectionA2 from "./SectionA_2.jsx";
import SectionA3 from "./SectionA_3.jsx";
import SectionA4 from "./SectionA_4.jsx";
import SectionA5 from "./SectionA_5.jsx";
import SectionA6 from "./SectionA_6.jsx";
import SectionA7 from "./SectionA_7.jsx";
import SectionB1 from "./SectionB_1.jsx";
import SectionB2 from "./SectionB_2.jsx";
import SectionB3 from "./SectionB_3.jsx";
import SectionC1 from "./SectionC_1.jsx";
import SectionC2 from "./SectionC_2.jsx";
import SectionC3 from "./SectionC_3.jsx";
import SectionC4 from "./SectionC_4.jsx";
import SectionC5 from "./SectionC_5.jsx";
import SectionC6 from "./SectionC_6.jsx";
import SectionC7 from "./SectionC_7.jsx";
import SectionC8 from "./SectionC_8.jsx";
import SectionC9 from "./SectionC_9.jsx";

const ReportGenerationPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const reportName = useSelector((state) => state.reportName);
  const companyid = useSelector((state) => state.companyid);
  const companyname = useSelector((state) => state.companyname);
  const [savedReport, setSavedReport] = useState([]);
  const [error, setError] = useState("");

  dispatch(
    setTopbar({
      pageName: "Generate Report",
      breadcrumbs: ["Reporting Studio", reportName, "Generate Report"],
    })
  );

  const getSavedReport = async () => {
    try {
      await fetch(
        process.env.REACT_APP_SERVER_URL +
          `/studio/report/saved/${companyid}/${reportName}`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      )
        .then((res) => {
          if (!res.ok) setError(res.message);
          else return res.json();
        })
        .then((data) => {
          setSavedReport(data);
        })
        .catch((err) => {
          console.log("Error :" + err);
          setError("Internal Server Error");
        });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  useEffect(() => {
    getSavedReport();
  }, [savedReport.length]);

  const createPDF = async () => {
    const pdfName =
      companyname +
      " " +
      savedReport.reportType +
      " " +
      savedReport.fy +
      ".pdf";
    const canvas = await html2canvas(document.querySelector("#pdf"), {
      scale: 2,
    });
    const imgData = canvas.toDataURL("image/png");
    var imgWidth = 210;
    var pageHeight = 295;
    var imgHeight = (canvas.height * imgWidth) / canvas.width;
    var heightLeft = imgHeight;
    var doc = new jsPDF("p", "mm");
    var position = 0;
    doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
    heightLeft -= pageHeight;

    while (heightLeft >= 0) {
      position = heightLeft - imgHeight;
      doc.addPage();
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
    }
    doc.save(pdfName);
  };

  return (
    <main>
      <Topbar />
      <div
        style={{ display: "flex", paddingRight: "4.5rem", paddingTop: "2rem" }}
      >
        <Button
          variant="contained"
          size="large"
          style={{ marginLeft: "auto" }}
          onClick={createPDF}
        >
          Download
        </Button>
      </div>
      <div id="pdf">
        {savedReport.length !== 0 && (
          <Container
            maxWidth="ls"
            style={{ marginTop: "2rem", marginLeft: "1rem" }}
          >
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "100%",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: "10px",
                  border: colors.purple[200],
                  marginLeft: "2rem"
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h3"
                    variant="h3"
                    fontWeight="bold"
                    color={colors.grey[100]}
                    marginLeft={"1rem"}
                    paddingTop={"1rem"}
                    paddingBottom={"1rem"}
                  >
                    {companyname +
                      " " +
                      savedReport.reportType +
                      " " +
                      savedReport.fy}
                  </Typography>
                </CardContent>
              </Card>
              {/* <Grid>
                <Card
                  sx={{
                    height: "100%",
                    width: "70rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                    border: colors.purple[200],
                    marginTop: "1rem",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["section"][0]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[0].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-1"
                                variant="outlined"
                                defaultValue={sectionA1[0].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[1].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-2"
                                variant="outlined"
                                value={sectionA1[1].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[2].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[2].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-3"
                                variant="outlined"
                                value={sectionA1[2].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[3].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[3].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-4"
                                variant="outlined"
                                value={sectionA1[3].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[4].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[4].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-5"
                                variant="outlined"
                                value={sectionA1[4].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[5].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[5].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-6"
                                variant="outlined"
                                value={sectionA1[5].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[6].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[6].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-7"
                                variant="outlined"
                                value={sectionA1[6].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[7].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[7].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-8"
                                variant="outlined"
                                value={sectionA1[7].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[8].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[8].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-9"
                                variant="outlined"
                                value={sectionA1[8].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[9].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[9].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-10"
                                variant="outlined"
                                value={sectionA1[9].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[10].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[10].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-11"
                                variant="outlined"
                                value={sectionA1[10].value}
                                size="small"
                                sx={{ width: "60rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA1[11].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA1[11].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-12-1"
                                variant="outlined"
                                label="Name"
                                value={sectionA1[11].values[0].value}
                                size="small"
                                sx={{ width: "15rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-1-12-2"
                                variant="outlined"
                                label="Designation"
                                value={sectionA1[11].values[1].value}
                                size="small"
                                sx={{ width: "14rem", marginLeft: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-1-12-3"
                                variant="outlined"
                                label="Telephone"
                                value={sectionA1[11].values[2].value}
                                size="small"
                                sx={{ width: "14rem", marginLeft: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-1-12-4"
                                variant="outlined"
                                label="Email"
                                value={sectionA1[11].values[3].value}
                                size="small"
                                sx={{ width: "14rem", marginLeft: "1rem" }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Stack direction={"row"}>
                            <Box sx={{ width: "4rem" }}>
                              <Typography
                                component="body1"
                                variant="body1"
                                fontWeight="bold"
                                color={colors.grey[100]}
                                marginLeft={"1rem"}
                              >
                                {sectionA1[12].id.split("-").pop()}
                              </Typography>
                            </Box>
                            <Box sx={{ width: "55rem" }}>
                              <Typography
                                component="body1"
                                variant="body1"
                                fontWeight="bold"
                                color={colors.grey[100]}
                              >
                                {sectionA1[12].name}
                              </Typography>
                            </Box>
                          </Stack>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-1-13"
                                variant="outlined"
                                size="small"
                                sx={{ width: "60rem" }}
                                value={sectionA1[12].value}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      paddingTop={"2rem"}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["section"][1]["name"]}
                    </Typography>
                  </CardContent>

                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA2[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA2[0].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {sectionA2[0].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="A-2-1-1-1"
                                  variant="outlined"
                                  defaultValue={sectionA2[0].rows[0].value1}
                                  size="small"
                                  sx={{ width: "20rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA2[0].rows[0].name2}
                                </Typography>
                                <TextField
                                  id="A-2-1-2-1"
                                  variant="outlined"
                                  defaultValue={sectionA2[0].rows[0].value2}
                                  size="small"
                                  sx={{ width: "19rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA2[0].rows[0].name3}
                                </Typography>
                                <TextField
                                  id="A-2-1-3-1"
                                  variant="outlined"
                                  defaultValue={sectionA2[0].rows[0].value3}
                                  size="small"
                                  sx={{ width: "19rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>

                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA2[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA2[1].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                >
                                  {sectionA2[1].rows[0].name1}
                                </Typography>
                                <TextField
                                  id="A-2-2-1-1"
                                  variant="outlined"
                                  defaultValue={sectionA2[1].rows[0].value1}
                                  size="small"
                                  sx={{ width: "20rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA2[1].rows[0].name2}
                                </Typography>
                                <TextField
                                  id="A-2-2-2-1"
                                  variant="outlined"
                                  defaultValue={sectionA2[1].rows[0].value2}
                                  size="small"
                                  sx={{ width: "19rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA2[1].rows[0].name3}
                                </Typography>
                                <TextField
                                  id="A-2-2-3-1"
                                  variant="outlined"
                                  defaultValue={sectionA2[1].rows[0].value3}
                                  size="small"
                                  sx={{ width: "19rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            {Object.keys(sectionA2[1].rows).length > 1 && (
                              <div>
                                {sectionA2[1].rows.slice(1).map((row) => {
                                  console.log(row);
                                  return (
                                    <div style={{ paddingTop: "1rem" }}>
                                      <Stack direction={"row"}>
                                        <TextField
                                          id="A-2-2-1-1"
                                          variant="outlined"
                                          defaultValue={row.value1}
                                          size="small"
                                          sx={{ width: "20rem" }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-2-1"
                                          variant="outlined"
                                          defaultValue={row.value2}
                                          size="small"
                                          sx={{
                                            width: "19rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-3-1"
                                          variant="outlined"
                                          defaultValue={row.value3}
                                          size="small"
                                          sx={{
                                            width: "19rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                      </Stack>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      paddingTop={"2rem"}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["section"][2]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA3[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA3[0].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <Box sx={{ width: "15rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA3[0].values[0].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-3-1-1-1"
                                    variant="outlined"
                                    value={
                                      sectionA3[0].values[0].values[0].value
                                    }
                                    size="small"
                                    sx={{ width: "15rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA3[0].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-3-1-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA3[0].values[0].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA3[0].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-3-1-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA3[0].values[0].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA3[0].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-3-1-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA3[0].values[0].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "15rem" }}>
                                <TextField
                                  id="A-3-1-2-1"
                                  variant="outlined"
                                  value={sectionA3[0].values[1].values[0].value}
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-3-1-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA3[0].values[1].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-3-1-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA3[0].values[1].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-3-1-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA3[0].values[1].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA3[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA3[1].name}
                          </Typography>
                          <div
                            style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                          >
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA3[1].values[0].name}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "30rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA3[1].values[0].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-3-2-1-1"
                                    variant="outlined"
                                    value={
                                      sectionA3[1].values[0].values[0].value
                                    }
                                    size="small"
                                    sx={{ width: "30rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA3[1].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-3-2-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA3[1].values[0].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "29rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "30rem" }}>
                                <TextField
                                  id="A-3-2-2-1"
                                  variant="outlined"
                                  value={sectionA3[1].values[1].values[0].value}
                                  size="small"
                                  sx={{ width: "30rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-3-2-2-2"
                                variant="outlined"
                                size="small"
                                defaultValue={
                                  sectionA3[1].values[1].values[1].value
                                }
                                sx={{
                                  width: "29rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Typography
                        component="body1"
                        variant="body1"
                        fontWeight="bold"
                        color={colors.grey[100]}
                        marginLeft={"1rem"}
                      >
                        {sectionA3[1].values[2].name}
                      </Typography>
                      <div
                        style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                      >
                        <Stack direction={"row"}>
                          <TextField
                            id="A-3-2-3"
                            variant="outlined"
                            defaultValue={sectionA3[1].values[2].value}
                            size="small"
                            multiline
                            maxRows={10}
                            sx={{ width: "60rem" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Stack>
                      </div>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Typography
                        component="body1"
                        variant="body1"
                        fontWeight="bold"
                        color={colors.grey[100]}
                        marginLeft={"1rem"}
                      >
                        {sectionA3[1].values[3].name}
                      </Typography>
                      <div
                        style={{ paddingTop: "0.5rem", paddingLeft: "1rem" }}
                      >
                        <Stack direction={"row"}>
                          <TextField
                            id="A-3-2-4"
                            variant="outlined"
                            defaultValue={sectionA3[1].values[3].value}
                            size="small"
                            multiline
                            maxRows={10}
                            sx={{ width: "60rem" }}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Stack>
                      </div>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      paddingTop={"2rem"}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["section"][3]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA4[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA4[0].name}
                          </Typography>
                          <div
                            style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                          >
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA4[0].values[0].name}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA4[0].values[0].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-4-1-1-1"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA4[0].values[0].values[0].value
                                    }
                                    size="small"
                                    sx={{ width: "11rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-1-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[0].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-1-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[0].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-1-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[0].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[0].values[4].name}
                                </Typography>
                                <TextField
                                  id="A-4-1-1-5"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[0].values[4].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[0].values[5].name}
                                </Typography>
                                <TextField
                                  id="A-4-1-1-6"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[0].values[5].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[0].values[6].name}
                                </Typography>
                                <TextField
                                  id="A-4-1-1-7"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[0].values[6].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[0].values[7].name}
                                </Typography>
                                <TextField
                                  id="A-4-1-1-8"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[0].values[7].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-1-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[1].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-1-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[1].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[1].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[1].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-2-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[1].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-2-6"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[1].values[5].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-2-7"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[1].values[6].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-2-8"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[1].values[7].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-1-3-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[2].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-1-3-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[2].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-3-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[2].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-3-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[2].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-3-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[2].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-3-6"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[2].values[5].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-3-7"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[2].values[6].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-1-3-8"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[2].values[7].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <div style={{ paddingLeft: "1rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA4[0].values[3].name}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA4[0].values[3].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-4-2-1-1"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA4[0].values[3].values[0].value
                                    }
                                    size="small"
                                    sx={{ width: "11rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[3].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[3].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[3].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[3].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[3].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[3].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[3].values[4].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-5"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[3].values[4].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[3].values[5].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-6"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[3].values[5].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[3].values[6].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-7"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[3].values[6].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[3].values[7].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-8"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[3].values[7].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-2-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[4].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-2-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[4].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[4].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[4].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[4].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-6"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[4].values[5].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-7"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[4].values[6].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-8"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[4].values[7].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-2-3-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[5].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-2-3-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[5].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-3-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[5].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-3-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[5].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-3-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[5].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-3-6"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[5].values[5].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-3-7"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[5].values[6].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-3-8"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[5].values[7].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>

                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <div style={{ paddingLeft: "1rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA4[0].values[6].name}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA4[0].values[6].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-4-3-1-1"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA4[0].values[6].values[0].value
                                    }
                                    size="small"
                                    sx={{ width: "11rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[6].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[6].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[6].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[6].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[6].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[6].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[6].values[4].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-5"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[6].values[4].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[6].values[5].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-6"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[6].values[5].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[6].values[6].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-7"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[6].values[6].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[6].values[7].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-8"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[6].values[7].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-3-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[7].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-3-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[7].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[7].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[7].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[7].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-6"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[7].values[5].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-7"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[7].values[6].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-8"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[7].values[7].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-3-3-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[8].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-3-3-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[8].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-3-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[8].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-3-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[8].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-3-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[8].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-3-6"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[8].values[5].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-3-7"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[8].values[6].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-3-8"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[8].values[7].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <div style={{ paddingLeft: "1rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA4[0].values[9].name}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA4[0].values[9].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-4-4-1-1"
                                    variant="outlined"
                                    value={
                                      sectionA4[0].values[9].values[0].value
                                    }
                                    size="small"
                                    sx={{ width: "11rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[9].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-4-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[9].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[9].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-4-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[9].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[9].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-4-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[9].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[9].values[4].name}
                                </Typography>
                                <TextField
                                  id="A-4-4-1-5"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[9].values[4].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[9].values[5].name}
                                </Typography>
                                <TextField
                                  id="A-4-4-1-6"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[9].values[5].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[9].values[6].name}
                                </Typography>
                                <TextField
                                  id="A-4-4-1-7"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[9].values[6].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[0].values[9].values[7].name}
                                </Typography>
                                <TextField
                                  id="A-4-4-1-8"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[9].values[7].value
                                  }
                                  size="small"
                                  sx={{ width: "6rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-4-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[10].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-4-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[10].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[10].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[10].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-2-5"
                                variant="outlined"
                                size="small"
                                defaultValue={
                                  sectionA4[0].values[10].values[4].value
                                }
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-2-6"
                                variant="outlined"
                                size="small"
                                defaultValue={
                                  sectionA4[0].values[10].values[5].value
                                }
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-2-7"
                                variant="outlined"
                                size="small"
                                defaultValue={
                                  sectionA4[0].values[10].values[6].value
                                }
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-2-8"
                                variant="outlined"
                                size="small"
                                defaultValue={
                                  sectionA4[0].values[10].values[7].value
                                }
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "11rem" }}>
                                <TextField
                                  id="A-4-4-3-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[0].values[11].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "11rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-4-3-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[11].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-3-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[11].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-3-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[11].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-3-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[11].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-3-6"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[11].values[5].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-3-7"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[11].values[6].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-4-3-8"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[0].values[11].values[7].value
                                }
                                size="small"
                                sx={{
                                  width: "6rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>

                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA4[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA4[1].name}
                          </Typography>
                          <div
                            style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                          >
                            <Stack direction={"row"}>
                              <Box
                                sx={{ width: "15rem", paddingTop: "1.15rem" }}
                              >
                                <TextField
                                  id="A-4-2-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[1].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[1].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[1].values[0].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[1].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[1].values[0].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[1].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-2-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[1].values[0].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "15rem" }}>
                                <TextField
                                  id="A-4-2-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[1].values[1].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-2-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[1].values[1].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[1].values[1].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-2-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[1].values[1].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA4[2].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA4[2].name}
                          </Typography>
                          <div
                            style={{ paddingTop: "1rem", paddingLeft: "1rem" }}
                          >
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA4[2].values[0].value}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box
                                sx={{ width: "15rem", paddingTop: "1.15rem" }}
                              >
                                <TextField
                                  id="A-4-3-1-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[0].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[0].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[0].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[0].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[0].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[0].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-1-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[0].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "15rem" }}>
                                <TextField
                                  id="A-4-3-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[1].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-3-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[1].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[1].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[1].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <div style={{ paddingLeft: "1rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA4[2].values[2].value}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box
                                sx={{ width: "15rem", paddingTop: "1.15rem" }}
                              >
                                <TextField
                                  id="A-4-3-3-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[2].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[2].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-3-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[2].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[2].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-3-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[2].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[2].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-3-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[2].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "15rem" }}>
                                <TextField
                                  id="A-4-3-4-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[3].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-3-4-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[3].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-4-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[3].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-4-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[3].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <div style={{ paddingLeft: "1rem" }}>
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA4[2].values[4].value}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box
                                sx={{ width: "15rem", paddingTop: "1.15rem" }}
                              >
                                <TextField
                                  id="A-4-3-5-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[4].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[4].values[1].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-5-2"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[4].values[1].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[4].values[2].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-5-3"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[4].values[2].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                              <Stack direction={"column"}>
                                <Typography
                                  component="subtitle2"
                                  variant="subtitle2"
                                  fontWeight="medium"
                                  color={colors.grey[300]}
                                  sx={{ marginLeft: "1rem" }}
                                >
                                  {sectionA4[2].values[4].values[3].name}
                                </Typography>
                                <TextField
                                  id="A-4-3-5-4"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[4].values[3].value
                                  }
                                  size="small"
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Stack>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "15rem" }}>
                                <TextField
                                  id="A-4-3-6-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA4[2].values[5].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "15rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-4-3-6-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[5].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-6-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[5].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-4-3-6-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA4[2].values[5].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "14rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      paddingTop={"2rem"}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["section"][4]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA5[0].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <Box sx={{ width: "15rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA5[0].rows[0].name1}
                                  </Typography>
                                  <TextField
                                    id="A-5-1-1-1"
                                    variant="outlined"
                                    defaultValue={sectionA5[0].rows[0].value1}
                                    size="small"
                                    sx={{
                                      width: "15rem",
                                      paddingTop: "1.25rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Box
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                >
                                  <Stack direction={"column"}>
                                    <Typography
                                      component="subtitle2"
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color={colors.grey[300]}
                                    >
                                      {sectionA5[0].rows[0].name2}
                                    </Typography>
                                    <TextField
                                      id="A-5-1-2-1"
                                      variant="outlined"
                                      defaultValue={sectionA5[0].rows[0].value2}
                                      size="small"
                                      sx={{
                                        width: "14rem",
                                        paddingTop: "1.25rem",
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Stack>
                              <Stack direction={"column"}>
                                <Box
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                >
                                  <Stack direction={"column"}>
                                    <Typography
                                      component="subtitle2"
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color={colors.grey[300]}
                                    >
                                      {sectionA5[0].rows[0].name3}
                                    </Typography>
                                    <TextField
                                      id="A-5-1-3-1"
                                      variant="outlined"
                                      defaultValue={sectionA5[0].rows[0].value3}
                                      size="small"
                                      sx={{
                                        width: "14rem",
                                        paddingTop: "2.35rem",
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Stack>
                              <Stack direction={"column"}>
                                <Box
                                  sx={{ width: "14rem", marginLeft: "1rem" }}
                                >
                                  <Stack direction={"column"}>
                                    <Typography
                                      component="subtitle2"
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color={colors.grey[300]}
                                    >
                                      {sectionA5[0].rows[0].name4}
                                    </Typography>
                                    <TextField
                                      id="A-5-1-4-1"
                                      variant="outlined"
                                      defaultValue={sectionA5[0].rows[0].value4}
                                      size="small"
                                      sx={{ width: "14rem" }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Stack>
                            </Stack>
                            {Object.keys(sectionA5[0].rows).length > 1 && (
                              <div>
                                {sectionA5[0].rows.slice(1).map((row) => {
                                  return (
                                    <div style={{ paddingTop: "1rem" }}>
                                      <Stack direction={"row"}>
                                        <TextField
                                          id="A-2-2-1-1"
                                          variant="outlined"
                                          defaultValue={row.value1}
                                          size="small"
                                          sx={{ width: "15rem" }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-2-1"
                                          variant="outlined"
                                          defaultValue={row.value2}
                                          size="small"
                                          sx={{
                                            width: "14rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-3-1"
                                          variant="outlined"
                                          defaultValue={row.value3}
                                          size="small"
                                          sx={{
                                            width: "14rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-3-1"
                                          variant="outlined"
                                          defaultValue={row.value4}
                                          size="small"
                                          sx={{
                                            width: "14rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                      </Stack>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      paddingTop={"2rem"}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["section"][5]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA6[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA6[0].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-6-1"
                                size="small"
                                defaultValue={sectionA6[0].value}
                                sx={{ width: "60rem" }}
                              ></TextField>
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA6[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA6[1].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-6-2"
                                variant="outlined"
                                defaultValue={sectionA6[1].value}
                                size="small"
                                sx={{ width: "60rem" }}
                              />
                            </Stack>
                          </div>
                        </div>
                        <div style={{ paddingTop: "1rem" }}>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA6[2].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA6[2].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <TextField
                                id="A-6-3"
                                variant="outlined"
                                defaultValue={sectionA6[2].value}
                                size="small"
                                sx={{ width: "60rem" }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      paddingTop={"2rem"}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][0]["data"]["section"][6]["name"]}
                    </Typography>
                  </CardContent>

                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA7[0].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA7[0].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA7[0].values[0].value}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA7[0].values[0].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-A-1-1"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[0].values[0].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "12rem",
                                      paddingTop: "2.3rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box
                                sx={{ width: "11rem", paddingRight: "1rem" }}
                              >
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[0].values[1].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-A-1-2"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[0].values[1].value
                                    }
                                    size="small"
                                    sx={{ width: "11rem", marginLeft: "1rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[0].values[2].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-A-1-3"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[0].values[2].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      marginLeft: "1rem",
                                      paddingTop: "2.3rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[0].values[3].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-A-1-4"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[0].values[3].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      marginLeft: "1rem",
                                      paddingTop: "2.3rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[0].values[4].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-A-1-5"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[0].values[4].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      marginLeft: "1rem",
                                      paddingTop: "3.5rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-A-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[1].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-A-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[1].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[1].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[1].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                              />
                              <TextField
                                id="A-7-A-2-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[1].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-A-3-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[2].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-A-3-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[2].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-3-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[2].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-3-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[2].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-3-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[2].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-A-4-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[3].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-A-4-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[3].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-4-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[3].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-4-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[3].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-4-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[3].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-A-5-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[4].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-A-5-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[4].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-5-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[4].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-5-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[4].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-5-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[4].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-A-6-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[5].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-A-6-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[5].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-6-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[5].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-6-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[5].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-6-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[5].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-A-7-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[6].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-A-7-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[6].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-7-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[6].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-7-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[6].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-A-7-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[6].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Typography
                              component="body1"
                              variant="body1"
                              fontWeight="bold"
                              color={colors.grey[100]}
                            >
                              {sectionA7[0].values[7].value}
                            </Typography>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA7[0].values[7].values[0].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-B-1-1"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[7].values[0].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "12rem",
                                      paddingTop: "2.3rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box
                                sx={{ width: "11rem", paddingRight: "1rem" }}
                              >
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[7].values[1].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-B-1-2"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[7].values[1].value
                                    }
                                    size="small"
                                    sx={{ width: "11rem", marginLeft: "1rem" }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[7].values[2].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-B-1-3"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[7].values[2].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      marginLeft: "1rem",
                                      paddingTop: "2.3rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[7].values[3].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-B-1-4"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[7].values[3].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      marginLeft: "1rem",
                                      paddingTop: "2.3rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Box sx={{ width: "11rem", marginLeft: "1rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionA7[0].values[7].values[4].name}
                                  </Typography>
                                  <TextField
                                    id="A-7-B-1-5"
                                    variant="outlined"
                                    defaultValue={
                                      sectionA7[0].values[7].values[4].value
                                    }
                                    size="small"
                                    sx={{
                                      width: "11rem",
                                      marginLeft: "1rem",
                                      paddingTop: "3.5rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-B-2-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[8].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-B-2-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[8].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-2-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[8].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-2-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[8].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-2-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[8].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-B-3-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[9].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-B-3-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[9].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-3-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[9].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-3-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[9].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-3-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[9].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-B-4-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[10].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-B-4-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[10].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-4-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[10].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-4-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[10].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-4-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[10].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-B-5-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[11].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-B-5-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[11].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-5-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[11].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-5-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[11].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-5-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[11].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-B-6-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[12].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-B-6-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[12].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-6-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[12].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-6-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[12].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-6-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[12].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <TextField
                                  id="A-7-B-7-1"
                                  variant="outlined"
                                  defaultValue={
                                    sectionA7[0].values[13].values[0].value
                                  }
                                  size="small"
                                  sx={{ width: "12rem", paddingTop: "1rem" }}
                                  InputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </Box>
                              <TextField
                                id="A-7-B-7-2"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[13].values[1].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-7-3"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[13].values[2].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-7-4"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[13].values[3].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                              <TextField
                                id="A-7-B-7-5"
                                variant="outlined"
                                defaultValue={
                                  sectionA7[0].values[13].values[4].value
                                }
                                size="small"
                                sx={{
                                  width: "11rem",
                                  marginLeft: "1rem",
                                  paddingTop: "1rem",
                                }}
                                InputProps={{
                                  readOnly: true,
                                }}
                              />
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                            marginLeft={"1rem"}
                          >
                            {sectionA7[1].id.split("-").pop()}&nbsp;
                          </Typography>
                          <Typography
                            component="body1"
                            variant="body1"
                            fontWeight="bold"
                            color={colors.grey[100]}
                          >
                            {sectionA7[1].name}
                          </Typography>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Box sx={{ width: "60rem", paddingBottom: "1rem" }}>
                              <Typography
                                component="body1"
                                variant="body1"
                                fontWeight="bold"
                                color={colors.grey[100]}
                              >
                                {sectionA7[1].value}
                              </Typography>
                            </Box>
                            <Stack direction={"row"}>
                              <Box sx={{ width: "12rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionA7[1].rows[0].name1}
                                  </Typography>
                                  <TextField
                                    id="A-7-2-1-1"
                                    defaultValue={sectionA7[1].rows[0].value1}
                                    variant="outlined"
                                    size="small"
                                    sx={{
                                      width: "12rem",
                                      paddingTop: "2.9rem",
                                    }}
                                    InputProps={{
                                      readOnly: true,
                                    }}
                                  />
                                </Stack>
                              </Box>
                              <Stack direction={"column"}>
                                <Box
                                  sx={{ width: "11rem", marginLeft: "1rem" }}
                                >
                                  <Stack direction={"column"}>
                                    <Typography
                                      component="subtitle2"
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color={colors.grey[300]}
                                    >
                                      {sectionA7[1].rows[0].name2}
                                    </Typography>
                                    <TextField
                                      id="A-7-2-2-1"
                                      defaultValue={sectionA7[1].rows[0].value2}
                                      variant="outlined"
                                      size="small"
                                      sx={{
                                        width: "11rem",
                                        paddingTop: "1.8rem",
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Stack>
                              <Stack direction={"column"}>
                                <Box
                                  sx={{ width: "11rem", marginLeft: "1rem" }}
                                >
                                  <Stack direction={"column"}>
                                    <Typography
                                      component="subtitle2"
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color={colors.grey[300]}
                                    >
                                      {sectionA7[1].rows[0].name3}
                                    </Typography>
                                    <TextField
                                      id="A-7-2-3-1"
                                      defaultValue={sectionA7[1].rows[0].value3}
                                      variant="outlined"
                                      size="small"
                                      sx={{
                                        width: "11rem",
                                        paddingTop: "1.8rem",
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Stack>
                              <Stack direction={"column"}>
                                <Box
                                  sx={{ width: "11rem", marginLeft: "1rem" }}
                                >
                                  <Stack direction={"column"}>
                                    <Typography
                                      component="subtitle2"
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color={colors.grey[300]}
                                    >
                                      {sectionA7[1].rows[0].name4}
                                    </Typography>
                                    <TextField
                                      id="A-7-2-4-1"
                                      defaultValue={sectionA7[1].rows[0].value4}
                                      variant="outlined"
                                      size="small"
                                      sx={{
                                        width: "11rem",
                                        paddingTop: "1.7rem",
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Stack>
                              <Stack direction={"column"}>
                                <Box
                                  sx={{ width: "11rem", marginLeft: "1rem" }}
                                >
                                  <Stack direction={"column"}>
                                    <Typography
                                      component="subtitle2"
                                      variant="subtitle2"
                                      fontWeight="medium"
                                      color={colors.grey[300]}
                                    >
                                      {sectionA7[1].rows[0].name5}
                                    </Typography>
                                    <TextField
                                      id="A-7-2-5-1"
                                      defaultValue={sectionA7[1].rows[0].value5}
                                      variant="outlined"
                                      size="small"
                                      sx={{
                                        width: "11rem",
                                        paddingTop: "0.5rem",
                                      }}
                                      InputProps={{
                                        readOnly: true,
                                      }}
                                    />
                                  </Stack>
                                </Box>
                              </Stack>
                            </Stack>
                            {Object.keys(sectionA7[1].rows).length > 1 && (
                              <div>
                                {sectionA7[1].rows.slice(1).map((row) => {
                                  console.log(row);
                                  return (
                                    <div style={{ paddingTop: "1rem" }}>
                                      <Stack direction={"row"}>
                                        <TextField
                                          id="A-2-2-1-1"
                                          variant="outlined"
                                          defaultValue={row.value1}
                                          size="small"
                                          sx={{ width: "12rem" }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-2-1"
                                          variant="outlined"
                                          defaultValue={row.value2}
                                          size="small"
                                          sx={{
                                            width: "11rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-3-1"
                                          variant="outlined"
                                          defaultValue={row.value3}
                                          size="small"
                                          sx={{
                                            width: "11rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-3-1"
                                          variant="outlined"
                                          defaultValue={row.value4}
                                          size="small"
                                          sx={{
                                            width: "11rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                        <TextField
                                          id="A-2-2-3-1"
                                          variant="outlined"
                                          defaultValue={row.value5}
                                          size="small"
                                          sx={{
                                            width: "11rem",
                                            marginLeft: "1rem",
                                          }}
                                          InputProps={{
                                            readOnly: true,
                                          }}
                                        />
                                      </Stack>
                                    </div>
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    height: "100%",
                    width: "70rem",
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "10px",
                    border: colors.purple[200],
                    marginTop: "1rem",
                  }}
                >
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h4"
                      variant="h4"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][1]["data"]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography
                      component="h5"
                      variant="h5"
                      fontWeight="bold"
                      color={colors.grey[100]}
                      marginLeft={"1rem"}
                    >
                      {savedReport["nodes"][1]["data"]["section"][0]["name"]}
                    </Typography>
                  </CardContent>
                  <CardContent
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div>
                      <Stack direction={"column"}>
                        <div>
                          <div
                            style={{
                              paddingTop: "0.5rem",
                              paddingLeft: "1rem",
                            }}
                          >
                            <Stack direction={"row"}>
                              <Box sx={{ width: "6rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                  >
                                    {sectionB1[0].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-1"
                                    variant="outlined"
                                    defaultValue={sectionB1[0].value}
                                    size="small"
                                    sx={{ width: "6rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[1].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-2"
                                    variant="outlined"
                                    defaultValue={sectionB1[1].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[2].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-3"
                                    variant="outlined"
                                    defaultValue={sectionB1[2].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[3].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-4"
                                    variant="outlined"
                                    defaultValue={sectionB1[3].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[4].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-5"
                                    variant="outlined"
                                    defaultValue={sectionB1[4].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[5].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-6"
                                    variant="outlined"
                                    defaultValue={sectionB1[5].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[6].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-7"
                                    variant="outlined"
                                    defaultValue={sectionB1[6].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[7].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-8"
                                    variant="outlined"
                                    defaultValue={sectionB1[7].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                              <Box sx={{ width: "7rem" }}>
                                <Stack direction={"column"}>
                                  <Typography
                                    component="subtitle2"
                                    variant="subtitle2"
                                    fontWeight="medium"
                                    color={colors.grey[300]}
                                    sx={{ marginLeft: "1rem" }}
                                  >
                                    {sectionB1[8].name}
                                  </Typography>
                                  <TextField
                                    id="B-1-1-A-9"
                                    variant="outlined"
                                    defaultValue={sectionB1[8].value}
                                    size="small"
                                    sx={{ width: "6rem", marginLeft: "1rem" }}
                                  ></TextField>
                                </Stack>
                              </Box>
                            </Stack>
                          </div>
                        </div>
                      </Stack>
                    </div>
                  </CardContent>
                </Card>
              </Grid> */}
              {/* <SectionA1 data={savedReport["nodes"][0]["data"]["section"][0]} />
              <SectionA2 data={savedReport["nodes"][0]["data"]["section"][1]} />
              <SectionA3 data={savedReport["nodes"][0]["data"]["section"][2]} />
              <SectionA4 data={savedReport["nodes"][0]["data"]["section"][3]} />
              <SectionA5 data={savedReport["nodes"][0]["data"]["section"][4]} />
              <SectionA6 data={savedReport["nodes"][0]["data"]["section"][5]} />
              <SectionA7 data={savedReport["nodes"][0]["data"]["section"][6]} />
              <SectionB1 data={savedReport["nodes"][1]["data"]["section"][0]} />
              <SectionB2 data={savedReport["nodes"][1]["data"]["section"][1]} />
              <SectionB3 data={savedReport["nodes"][1]["data"]["section"][2]} /> */}
              <SectionC1 data={savedReport["nodes"][2]["data"]["section"][0]} />
              <SectionC2 data={savedReport["nodes"][2]["data"]["section"][1]} />
              <SectionC3 data={savedReport["nodes"][2]["data"]["section"][2]} />
              <SectionC4 data={savedReport["nodes"][2]["data"]["section"][3]} />
              <SectionC5 data={savedReport["nodes"][2]["data"]["section"][4]} />
              <SectionC6 data={savedReport["nodes"][2]["data"]["section"][5]} />
              <SectionC7 data={savedReport["nodes"][2]["data"]["section"][6]} />
              <SectionC8 data={savedReport["nodes"][2]["data"]["section"][7]} />
              <SectionC9 data={savedReport["nodes"][2]["data"]["section"][8]} />
            </Grid>
          </Container>
        )}
      </div>
    </main>
  );
};

export default ReportGenerationPage;
