import Topbar from "scenes/global/Topbar";
import {
  useTheme,
  Grid,
  Container,
  Card,
  CardContent,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { setTopbar } from "state";
import { useDispatch } from "react-redux";
import { tokens } from "../../theme";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import { ResponsiveLine } from "@nivo/line";
import { mockBar4Data as data } from "data/mockData";
import { mockBar5Data as bar2data } from "data/mockData";

const GDashboardPage = () => {
  console.log("Inside GDashboard page");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const companyid = useSelector((state) => state.companyid);
  const token = useSelector((state) => state.token);
  const { state } = useLocation();
  const { Sval } = state;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  dispatch(
    setTopbar({
      pageName: "Governance",
      breadcrumbs: ["Dashboard", "Governance"],
    })
  );

  return (
    <main>
      <div>
        <Topbar />
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Stack direction={"row"}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "15rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Number of complaints made by employees and workers
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="27vh">
                    <ResponsiveBar
                      data={data}
                      keys={["FY2022_23", "FY2021_22"]}
                      indexBy="period"
                      margin={{
                        top: 5,
                        right: 120,
                        bottom: 40,
                        left: 50,
                      }}
                      groupMode={"stacked"}
                      padding={0.6}
                      colors={[colors.green[100], colors.yellow[100]]}
                      axisTop={null}
                      axisRight={null}
                      enableGridX={false}
                      enableGridY={true}
                      enableLabel={false}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                      }}
                      axisLeft={{
                        format: e => Math.floor(e) === e && e
                      }}
                      legends={[
                        {
                          dataFrom: "keys",
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 120,
                          translateY: 0,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Stack>
        </Container>
        <Container
          maxWidth="ls"
          style={{ marginTop: "2rem", marginLeft: "1rem" }}
        >
          <Stack direction={"row"}>
            <Grid
              container
              spacing={1}
              direction="row"
              alignItems={"center"}
              justifyContent={"left"}
            >
              <Card
                sx={{
                  height: "15rem",
                  width: "70rem",
                  display: "flex",
                  flexDirection: "column",
                  mr: "1rem",
                  borderRadius: "10px",
                  border: colors.purple[200],
                }}
              >
                <CardContent sx={{ display: "flex", justifyContent: "center" }}>
                  <Typography
                    component="h4"
                    variant="h4"
                    fontWeight="bold"
                    color={colors.grey[300]}
                  >
                    Number of consumer complaints by subject
                  </Typography>
                </CardContent>
                <CardContent>
                  <Box height="27vh">
                    <ResponsiveBar
                      data={bar2data}
                      keys={["FY2022_23", "FY2021_22"]}
                      indexBy="period"
                      margin={{
                        top: 5,
                        right: 120,
                        bottom: 40,
                        left: 50,
                      }}
                      groupMode={"stacked"}
                      padding={0.6}
                      colors={[colors.green[100], colors.yellow[100]]}
                      axisTop={null}
                      axisRight={null}
                      enableGridX={false}
                      enableGridY={true}
                      enableLabel={false}
                      axisBottom={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                      }}
                      axisLeft={{
                        format: e => Math.floor(e) === e && e
                      }}
                      legends={[
                        {
                          dataFrom: "keys",
                          anchor: "bottom-right",
                          direction: "column",
                          justify: false,
                          translateX: 120,
                          translateY: 0,
                          itemsSpacing: 2,
                          itemWidth: 100,
                          itemHeight: 20,
                          itemDirection: "left-to-right",
                          itemOpacity: 0.85,
                          symbolSize: 20,
                          effects: [
                            {
                              on: "hover",
                              style: {
                                itemOpacity: 1,
                              },
                            },
                          ],
                        },
                      ]}
                    />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Stack>
        </Container>
      </div>
    </main>
  );
};

export default GDashboardPage;
