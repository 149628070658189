import { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Grid,
} from "@mui/material";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokens } from "../../theme";

const CompanyAddForm = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const token  = useSelector((state) => state.token);

  const [companyName, setCompanyName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [addr1, setAddr1] = useState("");
  const [addr2, setAddr2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [pincode, setPincode] = useState("");
  const [error, setError] = useState("");

  const handleAccountFormSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await fetch(process.env.REACT_APP_SERVER_URL+"/company/register", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          companyName: companyName,
          industryName: industryName,
          addr1: addr1,
          addr2: addr2,
          city: city,
          state: state,
          country: country,
          pincode: pincode,
        }),
      }).then((res) => {
        if (res.status === 201) navigate('/admin/usermanage');
        else setError(res.message);
      });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  return (
    <Formik>
      {() => (
        <form onSubmit={handleAccountFormSubmit}>
          <Grid>
            <Typography
              variant="h2"
              padding={"2rem 0 0 3rem"}
              textAlign={"left"}
              sx={{
                color: colors.purple[100],
              }}
            >
              Add Company Information
            </Typography>
          </Grid>
          <Box
            display="grid"
            gap="20px"
            padding={"2rem 3rem 0 3rem"}
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
            {
              <>
                <TextField
                  label="Company"
                  onChange={(e) => setCompanyName(e.target.value)}
                  name="companyName"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Industry"
                  onChange={(e) => setIndustryName(e.target.value)}
                  name="industryName"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Address 1"
                  onChange={(e) => setAddr1(e.target.value)}
                  name="addr1"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Address 2"
                  onChange={(e) => setAddr2(e.target.value)}
                  name="addr2"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="City"
                  onChange={(e) => setCity(e.target.value)}
                  name="city"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="State"
                  onChange={(e) => setState(e.target.value)}
                  name="state"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Country"
                  onChange={(e) => setCountry(e.target.value)}
                  name="country"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Pincode"
                  onChange={(e) => setPincode(e.target.value)}
                  name="pincode"
                  sx={{ gridColumn: "span 2" }}
                />
              </>
            }
          </Box>

          <Box padding={"2rem 3rem 0 3rem"}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{
                color: colors.purple[100],
              }}
            >
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                  color: colors.white[200],
                }}
              >
                Add Company
              </Typography>
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default CompanyAddForm;
