// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyAuPT4shK-fLtWqYDEFXU_XzCGDtyq5T-4",
  authDomain: "aquarius-396506.firebaseapp.com",
  projectId: "aquarius-396506",
  storageBucket: "aquarius-396506.appspot.com",
  messagingSenderId: "723649453177",
  appId: "1:723649453177:web:df49ff36407f63716608a8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export default app;