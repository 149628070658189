import {
  Box,
  Container,
  useTheme,
  Stack,
  Breadcrumbs,
  Card,
  InputAdornment,
  TextField,
  Menu,
  MenuItem,
} from "@mui/material";
import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ColorModeContext, tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { UserAuth } from "context/AuthContext";
import Typography from "@mui/material/Typography";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { user, logout } = UserAuth();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");


  const pageName = useSelector((state) => state.pageName) || "pageName";
  const breadcrumbs = useSelector((state) => state.breadcrumbs);
  

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <main>
      <div style={{ width: "75rem" }}>
        <Container>
          <Box style={{ height: "6rem" }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Box
                padding={"2rem 0 0 0"}
                justifyContent={"left"}
                marginLeft={"1rem"}
              >
                <Stack direction={"column"}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    {breadcrumbs.map((ele) => {
                      return <Typography>{ele}</Typography>
                    })}
                  </Breadcrumbs>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    textAlign={"left"}
                    sx={{
                      color: colors.grey[100],
                    }}
                  >
                    {pageName}
                  </Typography>
                </Stack>
              </Box>
              <Box padding={"2rem 0 0 0"} justifyContent={"left"}>
                <Card
                  sx={{
                    height: "3rem",
                    width: "20rem",
                    display: "flex",
                    borderRadius: "30px",
                  }}
                >
                  <Stack direction={"row"}>
                    <Box
                      sx={{
                        width: "12rem",
                        padding: "0.5rem 0.1rem 0.1rem 0.5rem",
                      }}
                    >
                      <TextField
                        id="search"
                        type="search"
                        value={searchTerm}
                        onChange={handleChange}
                        sx={{ width: "12rem" }}
                        InputProps={{
                          sx: {
                            borderRadius: "2rem",
                            height: "2rem",
                            backgroundColor: colors.grey[400],
                            fieldset: { borderColor: colors.grey[400] },
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                    <Box sx={{ padding: "0.85rem 0 0 1.8rem" }}>
                      <NotificationsOutlinedIcon
                        fontSize="medium"
                        sx={{
                          color: colors.grey[300],
                        }}
                      />
                    </Box>
                    <Box sx={{ padding: "0.85rem 0 0 1.5rem" }}>
                      <PersonOutlinedIcon
                        onClick={handleClick}
                        fontSize="medium"
                        sx={{
                          color: colors.grey[300],
                        }}
                      />
                    </Box>
                    
                    <Menu
                      id="account-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      <Box >
                      <MenuItem onClick={handleClose}>Profile</MenuItem>
                      <MenuItem onClick={handleClose}>My Account</MenuItem>
                      <MenuItem onClick={handleLogout}>Logout</MenuItem>
                      </Box>
                    </Menu>
                    
                  </Stack>
                </Card>
              </Box>
            </Stack>
          </Box>
          {/* <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            style={{ height: "6rem"}}

          >
            <Grid>
              <Box padding={"2rem 0 0 0"} justifyContent={"left"}>
                <Stack direction={"column"}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Typography>Bread</Typography><Typography>Crumbs</Typography>
                  </Breadcrumbs>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    textAlign={"center"}
                    sx={{
                      color: colors.grey[100],
                    }}
                  >
                    {"<Page Name>"}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
            <Grid>
              <Box padding={"2rem 0 0 0"} justifyContent={"center"}>
                <Stack direction={"column"}>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="small" />}
                    aria-label="breadcrumb"
                  >
                    <Typography>Bread</Typography><Typography>Crumbs</Typography>
                  </Breadcrumbs>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    textAlign={"center"}
                    sx={{
                      color: colors.grey[100],
                    }}
                  >
                    {"<Page Name>"}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid> */}
        </Container>
        {/* <CssBaseline /> */}
        {/* <AppBar position="relative" style={{ background: 'white' }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton onClick={handleLogout}>
          <PersonOutlinedIcon />
        </IconButton>
          </Box>
        </Toolbar>
      </AppBar> */}
      </div>
    </main>
  );
};

export default Topbar;
