import { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useTheme,
  Grid,
  InputLabel,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { tokens } from "../../theme";

// Only for invites
import { doc, setDoc } from "firebase/firestore";
import { db } from "../../config/firebase";

const UserAddForm = ({ companyList }) => {

  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [designation, setDesignation] = useState("");
  const [license, setLicense] = useState("");
  const [usertype, setUserType] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [error, setError] = useState("");

  const token = useSelector((state) => state.token);

  const finalList = [];

  companyList.map((data) => {
    finalList.push(<MenuItem value={data._id}>{data.companyName}</MenuItem>);
  });

  const inviteUser = async () => {
    try {
      return await fetch(process.env.REACT_APP_SERVER_URL+`/email/invite`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: email,
          from: "letter@aiou.co.in",
          subject: `AIOU App Invitation-${firstName + " " + lastName}`,
          text: `${
            firstName + " " + lastName
          } has been invited to join AIOU app. Please go to https://aiou.co.im/signup to Sign Up`,
        }),
      }).then((res) => {
        return res;

      });
    } catch (e) {
      console.log(e.message);
      setError("User not invited through email");
    }
  };

  const handleAccountFormSubmit = async (e) => {
    e.preventDefault();
    setError("");

    try {
      await fetch(process.env.REACT_APP_SERVER_URL+"/auth/register", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          email: email,
          designation: designation,
          license: license,
          usertype: usertype,
          companyId: companyId,
        }),
      }).then(async(res) => {
        if (res.status === 201) {
          /* Store invited email in firestore */
          await setDoc(doc(db, "invites", email), {
            email: email,
          }).then(async () => {
            await inviteUser().then((inviteResponse) => {
              if(inviteResponse.status === 201) navigate("/admin/usermanage");
              else setError(inviteResponse.message);
            });
          });
        } else {
          setError(res.message);
        }
      });
    } catch (e) {
      console.log(e.message);
      setError("Internal Server Error");
    }
  };

  return (
    <Formik>
      {() => (
        <form onSubmit={handleAccountFormSubmit}>
          <Grid>
            <Typography
              variant="h2"
              padding={"2rem 0 0 3rem"}
              textAlign={"left"}
              sx={{
                color: colors.purple[100],
              }}
            >
              Add User Information
            </Typography>
          </Grid>
          <Box
            display="grid"
            gap="20px"
            padding={"2rem 3rem 0 3rem"}
            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
          >
            {
              <>
                <TextField
                  label="First Name"
                  onChange={(e) => setFirstName(e.target.value)}
                  name="firstName"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Last Name"
                  onChange={(e) => setLastName(e.target.value)}
                  name="lastName"
                  sx={{ gridColumn: "span 2" }}
                />
                <TextField
                  label="Email"
                  onChange={(e) => setEmail(e.target.value)}
                  name="email"
                  sx={{ gridColumn: "span 2" }}
                />
                <FormControl sx={{ gridColumn: "span 2" }}>
                  <InputLabel>Company</InputLabel>
                  <Select
                    id="company"
                    name="company"
                    label="Company"
                    value={companyId}
                    onChange={(e) => setCompanyId(e.target.value)}
                  >
                    {finalList}
                  </Select>
                </FormControl>
                <TextField
                  label="Designation"
                  onChange={(e) => setDesignation(e.target.value)}
                  name="designation"
                  sx={{ gridColumn: "span 2" }}
                />
                <FormControl sx={{ gridColumn: "span 2" }}>
                  <InputLabel>License</InputLabel>
                  <Select
                    id="license"
                    name="license"
                    label="License"
                    value={license}
                    onChange={(e) => setLicense(e.target.value)}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="single">Single</MenuItem>
                    <MenuItem value="multiple">Multiple</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ gridColumn: "span 2" }}>
                  <InputLabel>User Type</InputLabel>
                  <Select
                    id="usertype"
                    name="usertype"
                    label="User Type"
                    value={usertype}
                    onChange={(e) => setUserType(e.target.value)}
                  >
                    <MenuItem value="parent">Parent</MenuItem>
                    {license !== "single" && (
                      <MenuItem value="child">Child</MenuItem>
                    )}
                  </Select>
                </FormControl>
              </>
            }
          </Box>

          <Box padding={"2rem 3rem 0 3rem"}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              sx={{
                color: colors.purple[100],
              }}
            >
              <Typography
                variant="h4"
                textAlign={"center"}
                sx={{
                  color: colors.white[200],
                }}
              >
                Add User
              </Typography>
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

export default UserAddForm;
