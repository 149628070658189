import { Typography, useTheme, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { tokens } from "../../theme";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const ProductItem = ({ pid, name, desc, price, pdfURL, img, index }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const userid = useSelector((state) => state.userid);
  const token = useSelector((state) => state.token);
  const usertype = useSelector((state) => state.usertype);
  const companyid = useSelector((state) => state.companyid);
  const firstname = useSelector((state) => state.firstname);
  const lastname = useSelector((state) => state.lastname);
  const [error, setError] = useState("");

  const navigate = useNavigate();
  const routeChange = () => {
    navigate("/productview");
  };

  // const purchaseProduct = async () => {
  //   try {
  //     await fetch(process.env.REACT_APP_SERVER_URL+`/product/${userid}/${companyid}/purchase/${pid}`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     }).then((res) => {
  //       if (!res.ok) setError("Unable to purchase product");
  //     });
  //   } catch (e) {
  //     console.log(e.message);
  //     setError("Internal Server Error");
  //   }
  // };

  // const wishProduct = async () => {
  //   try {
  //     await fetch(process.env.REACT_APP_SERVER_URL+`/users/${companyid}/parent`, {
  //       method: "GET",
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then((res) => res.json())
  //       .then(async (data) => {
  //         console.log(data.email);
  //         await fetch(process.env.REACT_APP_SERVER_URL+`/email/product/wish`, {
  //           method: "POST",
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "application/json",
  //           },

  //           body: JSON.stringify({
  //             to: data.email,
  //             from: "letter@aiou.co.in",
  //             subject: `Product Wish-${firstname + " " + lastname}`,
  //             text: `${firstname + " " + lastname} wished for Product:${name} `,
  //           }),
  //         });
  //       });
  //   } catch (e) {
  //     console.log(e.message);
  //     setError("Unable to wish for product");
  //   }
  // };

  return (
    <div>
      <Card
        sx={{
          height: "23rem",
          width: "20rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CardMedia
          // component="div"
          sx={{ height: 0,pt: "80%" }}
          image={index===0 ? require('assets/images/product1.png'):require('assets/images/product2.png')}
        />
        <CardContent sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            component="h5"
            variant="h5"
            fontWeight="bold"
            color={colors.grey[300]}
          >
            {name}
          </Typography>
          {/* <Typography>{desc}</Typography> */}
          {/* <Typography>{pdfURL}</Typography> */}
        </CardContent>
        <CardActions style={{ justifyContent: "center" }}>
          {/* <Button variant="contained" size="large" onClick={routeChange}> */}
          <Button variant="contained" size="large">
            Preview
          </Button>
          {/* {usertype === "parent" ? (
            <Button variant="contained" size="large" onClick={purchaseProduct}>
              Buy
            </Button>
          ) : (
            <Button variant="contained" size="large" onClick={purchaseProduct}>
              Wish
            </Button>
          )} */}
        </CardActions>
      </Card>
    </div>
  );
};

export default ProductItem;
